import React , {useEffect, useState} from 'react'; 
import '../assets/css/custom.css'; 
import '../assets/css/client.css'; 
import '../assets/css/font-awesome.css';
import '../assets/css/sweetalert.css';
import '../assets/css/bootstrap.min.css';
import bike from '../assets/img/bike.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faEdit, faPlus, faSort, faTrash, faEllipsisVertical, faTrashCan, faPenToSquare} from '@fortawesome/free-solid-svg-icons';
import clientlogo from '../assets/img/user1.jpg'; 
import clientlogo2 from '../assets/img/user2.jpg'; 
import clientlogo3 from '../assets/img/user3.jpg'; 
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Menus from "./menus";
import stopMark from "../assets/img/stop.png";
import TowerIcon from "../assets/img/config-tower.png";
import LocationIcon from "../assets/img/location.png";
import UserIcon from "../assets/img/1.png";
import {commonAPI} from "../services/CommonAPIService"
import {ErrorSource} from '../common/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ClientEmploy()  { 
    const [TowerList, setTowerList] = useState("");
    const [TowerId, setTowerId] = useState("");
    const [BuildingList, setBuildingList] = useState("");
    const [BuildingId, setBuildingId] = useState("");
    const [TowerName, setTowerName] = useState("");
    const [BuildingName, setBuildingName] = useState("");
    const [Loading, setLoading] = useState(false);
    const [UserData, setUserData] = useState("");
    const [AddTowerBtn, setAddTowerBtn] = useState(false);
    const [UpdateTowerBtn, setUpdateTowerBtn] = useState(false);
    const [oldVehicleIndex,setOldVehicleIndex] = useState(null)
    const [vehicleMenu,showVehicleMenu] = useState(null)
    const [DeleteShow, setDeleteShow] = useState(false);
    const [SelectedtowerId,setSelectedTowerId] = useState("")

    const setInputValue = (item) => {
        setShow(true)
        console.log("item", item)
        setAddTowerBtn(false)
        setUpdateTowerBtn(true)
        setBuildingName(item?.buildingId)
        setTowerName(item?.towerName)
        setTowerId(item?.towerId)

    };

    const openCreateModal = () => {
        // if(BuildingId){
            setShow(true)
            setAddTowerBtn(true)
            setUpdateTowerBtn(false)
            setBuildingName(BuildingId)
            setTowerName("")
        // }else{
        //     toast.warning("Please select building");
        // }
    };


    const GetBuildingList = async () => {

        try {
            setLoading(true);
            let response = await commonAPI("Building")
            if (response.success===true){
                setBuildingList(response.data);
                setLoading(false);
            }else{
                toast.error("Something went wrong");
                setLoading(false);
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            const bodyError = JSON.stringify({ 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber,
                "requestJSON": null,
                "uri": "/api/Common/Building",
                "errorNumber": "GB01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "tower.js",
                "functionName": "GetBuildingList",
            });
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }

    } 

    const GetTowerList = async () => {

        try {
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            setUserData(myUserData);
            setLoading(true);

            const endPointVariableKey = {
                buildingId:1155
            }

            let response = await commonAPI("Tower", undefined, endPointVariableKey);
            // return;
            // let response = await fetch(`https://api-test-next.curo.in/api/Master/Tower`, {
            //     // let response = await fetch(`${apiURL}/DAR/GetDARData/${myUserData.userId}`, {
            //     method: "GET",
            //     headers: { 'Content-Type': 'application/json', 'BuildingId': BuildingId},
            // })
            // response = await response.json();
            // console.log("Table", response.data)
            // if (response.success===true){
            //     console.log("Table Tower", response)
            //     setTowerList(response.data);
            //     setLoading(false);
            // }else{
            //     toast.error("Something went wrong");
            //     setLoading(false);
            // }

        } catch (error) {
            console.log(error);
            
        }

    }
    useEffect(() => {
        if(BuildingId){
        GetTowerList();
        }else if (BuildingId == ""){
            setTowerList("")
        }
    }, [BuildingId])

    const ShowDeleteModal = (item) => {
        setDeleteShow(true);
        setSelectedTowerId(item)

    }

    const DeleteTower = async () => {
        const bodydata = { 
            "towerId": SelectedtowerId?.towerId,
            "modifiedUserId": JSON.stringify(UserData?.userId), // Need to be replaced
          };
        try {
            setLoading(true);
            const endPointVariableKey = {
                buildingId:BuildingId
            }

            let response = await commonAPI("TowerDelete", bodydata, endPointVariableKey);
            
            //   console.log("body", bodydata);
            //   let response = await fetch(`https://api-test-next.curo.in/api/Master/Tower/`, {
            //     // let response = await fetch(`${apiURL}/DAR/DARReportToHOD`, {  
            //       method: "DELETE",
            //       headers: { 'Content-Type': 'application/json', 'BuildingId': BuildingId},
            //       body: bodydata
                  
            //   })
            //   const statusCode = response
            // response = await response.json();
            //   console.log("statusCode", response)
              if(response.success == true && response.message.length == 0){
                toast.success("Tower Deleted"); 
                setLoading(false); 
                GetTowerList();
                setDeleteShow(false);
              }else{
                toast.error(response.message[0]);
                setLoading(false);
              }
        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": UserData.userId,
                "mobileNumber": UserData.mobileNumber,
                "requestJSON": bodydata,
                "uri": "/api/Master/Tower",
                "errorNumber": "TD01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "tower.js",
                "functionName": "DeleteTower",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }
          
    }

     

    const addNewTower = async () => {
        const bodydata = {
            towerName: TowerName,
            buildingId: BuildingId,
            createdUserId: JSON.stringify(UserData?.userId), // Need to be change
        }
        try {
            setLoading(true);
            
            if(!TowerName){
                toast.warning("Please enter Tower Name");
                setLoading(false)
            }else{
                const endPointVariableKey = {
                    buildingId:1155
                }
                let response = await commonAPI("TowerAdd", bodydata, endPointVariableKey);
                // let response = await fetch("https://api-test-next.curo.in/api/Master/Tower/", {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'BuildingId': BuildingId
                //     },
                //     body: JSON.stringify(body),
                // });
                // // console.log(body)
                // response = await response.json();
                // console.log(response);
                if(response.success == true && response.message.length == 0){
                    toast.success("Tower Added"); 
                    // console.log(response.success);
                    GetTowerList();
                    setShow(false)
                    setLoading(false);
                }else{
                    toast.error(response.message[0]);
                    setLoading(false);
                }
            }    

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = JSON.stringify({ 
                "userId": UserData.userId,
                "mobileNumber": UserData.mobileNumber,
                "requestJSON": bodydata,
                "uri": "/api/Master/Tower/",
                "errorNumber": "AT01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "tower.js",
                "functionName": "addNewTower",
            });
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }
      };


      const UpdateTower = async () => {
        const bodyData = {
            towerName: TowerName,
            buildingId: BuildingId,
            towerId: TowerId,
            modifiedUserId: JSON.stringify(UserData?.userId), // Need to be change
        }
        try {
            setLoading(true);
            
            if(!TowerName){
                toast.warning("Please enter Tower Name");
                setLoading(false);
            }else{
                const endPointVariableKey = {
                    buildingId:BuildingId
                }
                let response = await commonAPI("TowerAdd", bodyData, endPointVariableKey);
                // let response = await fetch("https://api-test-next.curo.in/api/Master/Tower/", {
                //     method: 'PUT',
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'BuildingId': BuildingId
                //     },
                //     body: JSON.stringify(body),
                // });
                // console.log(body)
                // response = await response.json();
                // console.log(response);
                if(response.success == true && response.message.length == 0){
                    toast.success("Tower Updated"); 
                    // console.log(response.success);
                    GetTowerList();
                    setShow(false)
                    setLoading(false);
                }else{
                    toast.error(response.message[0]);
                    setLoading(false);
                }
            }    

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = JSON.stringify({ 
                "userId": UserData.userId,
                "mobileNumber": UserData.mobileNumber,
                "requestJSON": bodyData,
                "uri": "/api/Master/Tower",
                "errorNumber": "AT01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "tower.js",
                "functionName": "UpdateTower",
            });
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }
      };  

    function setVehicleIndex(index) {
        console.log(oldVehicleIndex == index, "oldVehicleIndex==index")
        if (oldVehicleIndex == index) {
            setOldVehicleIndex(null)
            showVehicleMenu(null)

        }
        else {
            showVehicleMenu(index)
            setOldVehicleIndex(index)

        }
        // setHideVehicleMenu(true)
    }  
      

    // useEffect(() => {
    //     GetBuildingList();
    //     // GetTowerList();
    // }, [])


    const [show, setShow] = useState(false);

     return (
    <>
    {Loading? 
        <div className="loaderDiv">
          <div className="loader"></div>
        </div> 
      :null}
      {/* <Menus/> */}
 
      {/* <ToastContainer /> */}
      <div className="row mt-5 pt-5" style={{alignItem:'center'}}>
            
            
        </div>
      
             






            <Modal size="md" show={show} onHide={() =>  setShow(false)} className="modal-100w modal-md clientmodal right text-end p-0" aria-labelledby="contained-modal-title-vcenter ">
                        <Modal.Header closeButton className='headerMDL'>
                            <Modal.Title id="contained-modal-title-vcenter" onClick={openCreateModal}>
                                {/* Add Tower */}
                                {UpdateTowerBtn ?   <>Update Tower</> : <>Add  Tower</> }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

              <div className="container" style={{height: "77vh"}}>
                       <div className="card-no-border">
                      <div className="row" id="validation">
                    <div className="col-12">
                        <div className="card wizard-content">
                            <div className="card-body">
                         
                                <form action="#" className="validation-wizard wizard-circle wizard clearfix" role="application" id="steps-uid-2" novalidate="novalidate">
                                    
                                        <div className="content clearfix">
                                     
                                    
                                    <section id="steps-uid-2-p-0" role="tabpanel" aria-labelledby="steps-uid-2-h-0" className="body current pt-4" aria-hidden="false">
                                        <div className="row text-left">
                                            <div className="col-md-12 mb-2">
                                                <div className="form-group">
                                                    <label for="wfirstName2"> Tower Name  <span className="danger">*</span> </label>
                                                    <input type="text" value={TowerName} onChange={(e)=>{setTowerName(e.target.value)}}  className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Building Name  <span className="danger">*</span> </label>
                                                    <select name="taskTypes" id="wlastName2" disabled className="form-control" value={BuildingName} onChange={(e)=>{setBuildingName(e.target.value)}}>
                                                        <option value="Select">Select</option>
                                                        {BuildingList ? BuildingList.map((item, index) =>  <option key={index} value={item.buildingId}>{item.buildingName}</option>): null}
                                                    </select>
                                                </div>
                                                    
                                            </div>

                                        </div>  
                                    </section>
 
                                </div>
                                
                                <div className="actions clearfix mt-3">
                                    <ul role="menu" aria-label="Pagination">
                                        {AddTowerBtn ?
                                            // <li aria-hidden="false" aria-disabled="false">
                                            //     <button type="button" className="btn border bg-white" onClick={() => setShow(false)}> Cancel</button>
                                            //     <a className='btn btn-contained' onClick={addNewTower} role="menuitem">Submit </a>
                                            // </li>
                                            <div className="  align-self-start">
                                                <button type="button" className="btn border bg-white" onClick={() => setShow(false)}> Cancel</button>
                                                <button type="button" className="btn btn-info" onClick={() => addNewTower()}> Submit</button>
                                            </div>
                                        :null} 
                                        {UpdateTowerBtn ?
                                            <div className="  align-self-start">
                                                <button type="button" className="btn border bg-white" onClick={() => setShow(false)}> Cancel</button>
                                                <button type="button" className="btn btn-info" onClick={() => UpdateTower()}> Update</button>
                                            </div>
                                            // <li aria-hidden="true">
                                            //     <a href="#finish" onClick={UpdateTower} role="menuitem">Update</a>
                                            // </li>
                                        :null}    
                                    </ul>
                                    </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
              </div>
        </Modal.Body>
      </Modal>




  
          <div className="row">
                    <div className="col-12">

                        
                      
                   
                         
          
            </div>
             <div className="col-lg-6 col-md-6">
                <div className="HeadingTop">
                    <h3> Employee  </h3>
                    <span> List of Employee</span>
                </div>
            </div>
            <div className="row">
                        
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="clientTiles" style={{height:220, backgroundColor: "#2F4F4F"}}>
                        <div className="ClientImg">
                            <div className="tabs_img">
                            <img src={UserIcon} class="img img-responsive" alt="client-img" />
                            </div>
                            <div class="bars_font">
                                <h3 className="font_trigger" onClick={() => {setInputValue();}} >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </h3>
                                <h3 className="font_trigger" onClick={() => {ShowDeleteModal();}}>
                                    <FontAwesomeIcon icon={faTrashCan} />
                                </h3>
                            </div>
                        </div>
                        <div className="ClientText">
                            <div className="l-two">
                                <h1 style={{color: "#fff"}}> Ramu Singh </h1>
                                <h4 style={{color: "#fff"}}> Service</h4>
                                <h4 style={{color: "#fff"}}> Manager</h4>
                                <h4 style={{color: "#fff"}}> Ramu@gmail.com </h4>
                                <h4 style={{color: "#fff"}}> Tekri, Opposie Omax Plaza, Gurgaon, Gurgaon, Haryana - 122002 </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="clientTiles" style={{height:220, backgroundColor: "#006633"}}>
                        <div className="ClientImg">
                            <div className="tabs_img">
                            <img src={UserIcon} class="img img-responsive" alt="client-img" />
                            </div>
                            <div class="bars_font">
                                <h3 className="font_trigger" onClick={() => {setInputValue();}} >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </h3>
                                <h3 className="font_trigger" onClick={() => {ShowDeleteModal();}}>
                                    <FontAwesomeIcon icon={faTrashCan} />
                                </h3>
                            </div>
                        </div>
                        <div className="ClientText">
                            <div className="l-two">
                                <h1 style={{color: "#fff"}}> Shyam Sharma</h1>
                                <h4 style={{color: "#fff"}}> Admin</h4>
                                <h4 style={{color: "#fff"}}> N/A</h4>
                                <h4 style={{color: "#fff"}}>Shyam@gmail.com</h4>
                                <h4 style={{color: "#fff"}}> Sec. 78 , Noida, Noida, Noida, Uttar Pradesh -</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="clientTiles" style={{height:220, backgroundColor: "#00BFFF"}}>
                        <div className="ClientImg">
                            <div className="tabs_img">
                            <img src={UserIcon} class="img img-responsive" alt="client-img" />
                            </div>
                            <div class="bars_font">
                                <h3 className="font_trigger" onClick={() => {setInputValue();}} >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </h3>
                                <h3 className="font_trigger" onClick={() => {ShowDeleteModal();}}>
                                    <FontAwesomeIcon icon={faTrashCan} />
                                </h3>
                            </div>
                        </div>
                        <div className="ClientText">
                            <div className="l-two">
                                <h1 style={{color: "#fff"}}> Rakesh Kumar</h1>
                                <h4 style={{color: "#fff"}}>Sales </h4>
                                <h4 style={{color: "#fff"}}> Manager</h4>
                                <h4 style={{color: "#fff"}}>Rakesh@gmail.com</h4>
                                <h4 style={{color: "#fff"}}> Plot No-1 Industrial area, Phase-9, Mohali, Mohali, Punjab - 160062 </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="clientTiles" style={{height:220, backgroundColor: "#BF5FFF"}}>
                        <div className="ClientImg">
                            <div className="tabs_img">
                            <img src={UserIcon} class="img img-responsive" alt="client-img" />
                            </div>
                            <div class="bars_font">
                                <h3 className="font_trigger" onClick={() => {setInputValue();}} >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </h3>
                                <h3 className="font_trigger" onClick={() => {ShowDeleteModal();}}>
                                    <FontAwesomeIcon icon={faTrashCan} />
                                </h3>
                            </div>
                        </div>
                        <div className="ClientText">
                            <div className="l-two">
                                <h1 style={{color: "#fff"}}> Ramesh Kumar </h1>
                                <h4 style={{color: "#fff"}}>Account </h4>
                                <h4 style={{color: "#fff"}}>Assistant Manager</h4>
                                <h4 style={{color: "#fff"}}>Ramesh@gmail.com</h4>
                                <h4 style={{color: "#fff"}}> 1110, Ashoka Estate , 24 Barakhamba Road </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="clientTiles" style={{height:220,backgroundColor: "#5959AB"}}>
                        <div className="ClientImg">
                            <div className="tabs_img">
                            <img src={UserIcon} class="img img-responsive" alt="client-img" />
                            </div>
                            <div class="bars_font">
                                <h3 className="font_trigger" onClick={() => {setInputValue();}} >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </h3>
                                <h3 className="font_trigger" onClick={() => {ShowDeleteModal();}}>
                                    <FontAwesomeIcon icon={faTrashCan} />
                                </h3>
                            </div>
                        </div>
                        <div className="ClientText">
                            <div className="l-two">
                                <h1 style={{color: "#fff"}}> Akash Singhal</h1>
                                <h4 style={{color: "#fff"}}>HR </h4>
                                <h4 style={{color: "#fff"}}>Assistant HR</h4>
                                <h4 style={{color: "#fff"}}>Akash@gmail.com</h4>
                                <h4 style={{color: "#fff"}}> 1110, Ashoka Estate , 24 Barakhamba Road </h4>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
        <Modal centered show={DeleteShow} onHide={() =>
            setDeleteShow(false)} dialogclassName="modal-100w" aria-labelledby="contained-modal-title-vcenter ">
                <Modal.Header className="headerMDL" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" centered onClick={openCreateModal}>
                        <h4 className="mb-0"> Delete Tower </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row" id="validation">
                        <div className="col-12">
                            <div className="FnFItem">
                            <div className="FnFimg">
                                <img src={stopMark} className='img img-fluid img-responsive'  alt="placeholder" />
                            </div>
                            <div className="FnFtxt">
                                <h3> Are your Sure You want to Delete? </h3>
                            </div>
                            </div>
                            <div className='confirmation_btn'>
                            <div className='both_ConfrmBTN' onClick={DeleteTower}>
                                <Button variant="danger">Delete</Button>{' '}
                            </div>
                            <div className='both_ConfrmBTN' onClick={() => setDeleteShow(false)} >
                                <Button variant="light"> Cancel</Button>{' '}
                            </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
 
 


        </>)

}
export default ClientEmploy