import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Router } from "react-router-dom"; 
import React , {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Body from './pages/Body';
import Login from './pages/login';
import Registration from './pages/Registration';
import Dashboard from './pages/Dashboard';
// import Tower from './pages/tower';
import { commonAPI } from './services/CommonAPIService';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Building from './pages/building';
import Client from './pages/client';
import Menus from './pages/menus';
import Noticemanagement from './pages/Noticemanagement';
import Management from './pages/Management';
import Gate from './pages/Gate';
import Flat from './pages/flat';
import NoticeApproval from './pages/NoticeApproval';
import Familydetails from './pages/Familydetails';
import UserApproval from './pages/UserApprove';
import Approval from './pages/Approval';
import vehicleApproval from './pages/vehicleApproval';
import Resident from './pages/Resident';
import Configuration from './pages/Configuration';
import ProjectMachine from './pages/Projectmachine';
import ClientMachine from './pages/clientMachine';
import ReportMachine from './pages/Report';
// import { Approval } from '@mui/icons-material';
// import Dashboard from './pages/Dashboard';

function App() {
  // const [ProductAccessFactor, setProductAccessFactor] = useState("");
  const [data, setData] = useState([])

  

  

  

  return (
    <BrowserRouter>
      <Routes>
        {/* <IndexRedirect to="/Building" /> */}
        {/* <Route path="/" element={<Login/>} /> */}
        <Route path="/Body" element={<Body/>} />
        <Route path="/" element={<Dashboard/>} />
        <Route path="/Building" element={<Building/>} />
        <Route path="/Client" element={<Client/>}></Route>
        <Route path="/Registration" element={<Registration/>}></Route>
        
        <Route path="/Notice" element={<Noticemanagement/>}></Route>
        <Route path="/Gate" element={<Gate/>}></Route>
        <Route path="/Flat" element={<Flat/>}></Route>
        {/* <Route path="/Tower" element={<Tower/>}></Route> */}
        <Route path="/NoticeApproval" element={<NoticeApproval/>}></Route>
        <Route path="/Configuration" element={<Configuration/>}></Route>
        <Route path="/ProjectMachine" element={<ProjectMachine/>}></Route>
        <Route path="/Management" element={<Management/>}></Route>
        <Route path="/Approval" element={<Approval/>}></Route>
        <Route path="/UserApproval" element={<UserApproval/>}></Route>
        <Route path="/Resident" element={<Resident setdata = {setData} />}></Route>
        <Route path="/Familydetails" element={<Familydetails setdata = {setData} />}></Route>
        <Route path="/vehicleApproval" element={<vehicleApproval/>}></Route>
        <Route path="/Clients" element={<ClientMachine/>}></Route>
        <Route path="/Reports" element={<ReportMachine/>}></Route>
      </Routes>
  </BrowserRouter>
  );
}

export default App;
