import React, { useState } from "react";
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import '../assets/css/custom.css';  // Create a CSS file for custom styles
import TowerIcon from "../assets/img/config-tower.png";
import { Document, Page, pdfjs } from 'react-pdf';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd ,faEdit,faLocation,faLocationPin,faTrash, faUser, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { faPaperclip, faPlus } from "@fortawesome/fontawesome-free-solid";
// import { faTrash } from "@fortawesome/fontawesome-free-solid";

function ProjectDetails() {
    const [showFirstModal, setShowFirstModal] = useState(false);
    const [showSecondModal, setShowSecondModal] = useState(false);
    const [showVisitDetailModal, setShowVisitDetailModal] = useState(false);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [showMachineModal, setShowMachineModal] = useState(false);
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [pdfFile, setPdfFile] = useState(null); // State to hold the PDF file path
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [fileURL, setFileURL] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (file) {
    
          if (!/\.(jpeg|jpg|png)$/i.test(file?.name)) {
              alert("Please select an image in JPEG, JPG, or PNG format.");
          } else if (file?.size >= 500000000) {
              alert("Please select a logo with a size below 50KB.");
          } else {
              const reader = new FileReader();
    
              reader.onload = () => {
                  // atob(item?.logo)
                  // console.log("base64======", reader.result)
                  const SplitBase64 = reader.result.split("base64,");
                  // console.log("SplitBase64======", SplitBase64[1])
                  const encodedString = btoa(reader.result);
                  setFileURL(reader.result);
                //   setBase64(reader.result)
                 
              };
              reader.readAsDataURL(file);
          }
    
      }
        
      }

    const openCreateModalagain = () => {
        setShowFirstModal(true);
    };

    const openCreateModalSecond = () => {
        setShowSecondModal(true);
    };

    const openCreateModalThird = () => {
        setShowVisitDetailModal(true);
    };

    const openCreateModalFourth = () => {
        setShowDocumentModal(true);
    };

    const openMachineModalFourth = () => {
        setShowMachineModal(true);
    };

    // Function to open PDF in the modal
    // const handleViewPdf = (pdfPath) => {
    //     setPdfFile(pdfPath);
    //     setShowPdfModal(true);
    // };

    const handleViewPdf = (pdfUrl) => {
        window.open(pdfUrl, '_blank'); // Opens the PDF in a new tab
      };

    // Called when the PDF is loaded successfully
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    };

    const handleClose = () => setShowFirstModal(false);

    return (
        <> 
            <div className="mt-5">
            <div className="row mb-1" style={{ alignItems:'center' }}>
                <div className="col-md-2 mt-0 pt-2">
                    <div className="HeadingTop">
                        <h3> Project  </h3>
                        <span>  Detail of Project</span>
                    </div>
                    </div>
                    <div className="col-md-5 offset-md-5 mt-2 text-right">
                    <Button  onClick={() => openCreateModalagain()} className="createClr">
                            {" "}    Machine <FontAwesomeIcon icon={faPlus} />
                    </Button>{" "} 
                    <Button  onClick={() => openCreateModalSecond()} className="createClr">
                            {" "}    Contact Person <FontAwesomeIcon icon={faPlus} />
                    </Button>{" "} 
                    <Button  onClick={() => openCreateModalThird()} className="createClr">
                            {" "}    Visit Detail <FontAwesomeIcon icon={faPlus} />
                    </Button>{" "} 
                    <Button  onClick={() => openCreateModalFourth()} className="createClr">
                            {" "}    Document <FontAwesomeIcon icon={faPlus} />
                    </Button>{" "} 
                    </div>
            </div>        
                <div className="row mb-3 mt-0">
                    {/* First Column with Border and Shadow */}
                    <div className="col-md-2 custom-box">
                        {/* <div className="heading-section"> */}
                            {/* <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                <span>Next Visit Date</span>
                                <h6 className="mt-2" style={{backgroundColor: "#C8F526", padding: 4, marginBottom: 0, borderRadius: 3, color: "#000"}}>22-09-2024</h6>
                            </div>    
                            <h5 className="mt-2" style={{backgroundColor: "#EE3A8C", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff", width: "max-content"}}>Person: Deepak</h5>
                            <Button className="createClr mt-3">
                                Add Visit Detail
                            </Button> */}
                        {/* </div> */}
                        <div className="mt-1">
                            <div style={{display: "flex", alignItems: "baseline", justifyContent: "space-between"}}>
                                <span>Contact Person</span>
                                {/* <Button className="createClr mt-3">
                                    Add
                                </Button> */}
                            </div>   
                            <div className="document-card mt-3" style={{backgroundColor: "#00CD66"}}>
                                <div style={{position: "absolute", right: 26}}>
                                    <FontAwesomeIcon className="editNotice" icon={faEdit} />
                                    <FontAwesomeIcon className="editNotice" icon={faTrash} />
                                </div>
                                <FontAwesomeIcon className="editNotice" icon={faUserCircle} style={{fontSize: 40, color: "#fff"}} />
                                <h6 className="mt-0">Deepak</h6>
                                <h5 className="mt-2 mb-0">HR/Manager</h5>
                                <h5 className="mt-2 mb-0">9087654321</h5>
                                <h5 className="mt-2 mb-0">Deepak@gmail.com</h5>
                            </div>
                            <div className="document-card" style={{backgroundColor: "#FCB514"}}>
                                <div style={{position: "absolute", right: 26}}>
                                    <FontAwesomeIcon className="editNotice" icon={faEdit} />
                                    <FontAwesomeIcon className="editNotice" icon={faTrash} />
                                </div>
                                <FontAwesomeIcon className="editNotice" icon={faUserCircle} style={{fontSize: 40, color: "#fff"}} />
                                <h6 className="mt-0">Rahul</h6>
                                <h5 className="mt-2 mb-0">Sales/Manager</h5>
                                <h5 className="mt-2 mb-0">9087654321</h5>
                                <h5 className="mt-2 mb-0">rahul@gmail.com</h5>
                            </div>
                            <div className="document-card" style={{backgroundColor: "#FF7F50"}}>
                            <div style={{position: "absolute", right: 26}}>
                                    <FontAwesomeIcon className="editNotice" icon={faEdit} />
                                    <FontAwesomeIcon className="editNotice" icon={faTrash} />
                                </div>
                                <FontAwesomeIcon className="editNotice" icon={faUserCircle} style={{fontSize: 40, color: "#fff"}} />
                                <h6 className="mt-0">Sarvan</h6>
                                <h5 className="mt-2 mb-0">Accounts/Manager</h5>
                                <h5 className="mt-2 mb-0">9087654321</h5>
                                <h5 className="mt-2 mb-0">Sarvan@gmail.com</h5>
                            </div>
                        </div>
                    </div>

                    {/* Second Column with Border and Shadow */}
                    <div className="col-md-6 custom-box">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                                <div className="clientTiles" style={{ height: 145 }}>
                                    <div className="row">
                                        {/* Left Section: Image */}
                                        <div className="col-md-4">
                                            <div className="ClientImg" style={{justifyContent: "center"}}>
                                                <div className="tabs_img" style={{justifyContent: "center", marginTop: 26}}>
                                                    <img src={TowerIcon} className="img img-responsive" style={{width: 70, maxWidth: 70}} alt="client-img" />
                                                </div>
                                                {/* Uncomment if you need the edit and delete icons */}
                                                {/* <div className="bars_font">
                                                    <h3 className="font_trigger" onClick={() => { setInputValue(); }}>
                                                        <FontAwesomeIcon icon={faPenToSquare} />
                                                    </h3>
                                                    <h3 className="font_trigger" onClick={() => { ShowDeleteModal(); }}>
                                                        <FontAwesomeIcon icon={faTrashCan} />
                                                    </h3>
                                                </div> */}
                                            </div>
                                        </div>

                                        {/* Right Section: Text */}
                                        <div className="col-md-8">
                                            <div className="ClientText">
                                                <div className="l-two">
                                                    <h1>Ajnara City Apartments</h1>
                                                    <h4 style={{fontWeight: "bold"}}><b>AAKARSHAN</b></h4>
                                                    <h4 className="col-3 text-center" style={{backgroundColor: "#EE3A8C", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}><b>In-Active</b></h4>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="clientTiles" style={{ height: 145 }}>
                                    <div className="row">
                                        {/* Left Section: Image */}
                                       
                                        {/* Right Section: Text */}
                                            <div className="ClientText p-3">
                                                <div className="l-two">
                                                    <h4>Address:</h4>
                                                    <h4>Tekri, Opposite Omax Plaza,</h4>
                                                    <h4>Gurgaon, Haryana</h4>
                                                    <h4>122002</h4>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="d-flex" style={{alignItems: "baseline", justifyContent: "space-between"}}>
                            <div style={{display: "flex", alignItems: "baseline"}}>
                                <h2 className="mt-0 mb-0">Machine</h2>
                                {/* <h5 className="ml-2 mb-0">Latest 10</h5> */}

                            </div>
                            <Button className="view-btn" >View All</Button>
                        </div>       
                        
                        <div style={{ overflowX: 'auto' }}>  {/* Wrap the table in this div */}
                            <Table striped responsive="lg">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Modal</th>
                                        <th>S.No.</th>
                                        <th>Status</th>
                                        <th style={{whiteSpace: "nowrap"}}>Next Visit Date</th>
                                        <th style={{whiteSpace: "nowrap"}}>Total visit count</th>
                                        <th style={{whiteSpace: "nowrap"}}>Start date</th>
                                        <th style={{whiteSpace: "nowrap"}}>Expired In</th>
                                        <th style={{whiteSpace: "nowrap"}}>Last visit by</th>
                                        <th style={{whiteSpace: "nowrap"}}>Last visit status</th>
                                        <th style={{whiteSpace: "nowrap"}}>Last visit message</th>
                                        <th style={{whiteSpace: "nowrap"}}>Visit Date</th>
                                        <th style={{whiteSpace: "nowrap"}}>Visit Done Date</th>
                                        <th style={{whiteSpace: "nowrap"}}>Visit Person</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{backgroundColor: "#C3FFEB"}} >BMVH-1000A (Micro Vickers)</td>
                                        <td >BMVH-1000A (Micro Vickers)</td>
                                        <td>22030384 (HT-15) </td>
                                        <td style={{backgroundColor: "#ee3a8c"}}>2nd Visit</td>
                                        <td style={{backgroundColor: "#00cd66"}}>26-10-2024</td>
                                        <td>20</td>
                                        <td>02-10-2024</td>
                                        <td>20-10-2024</td>
                                        <td>Mohit</td>
                                        <td>Warranty</td>
                                        <td>Repair</td>
                                        <td>10-09-2024</td>
                                        <td>20-09-2024</td>
                                        <td>Rahul Sharma </td>
                                        <td >BMVH-1000A (Micro Vickers)</td>
                                        <td>
                                        <Link onClick={openMachineModalFourth}><FontAwesomeIcon className="editNotice" icon={faEdit} /></Link>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                    <td  style={{backgroundColor: "#D0FAEE"}}>BMVH-1000A (Micro Vickers)</td>
                                        <td >BMVH-1000A (Micro Vickers)</td>
                                        <td>22030384 (HT-15) </td>
                                        <td style={{backgroundColor: "#00cd66"}}>Single Visit</td>
                                        <td style={{backgroundColor: "#00cd66"}}>29-10-2024</td>
                                        <td>20</td>
                                        <td>01-10-2024</td>
                                        <td>20-10-2024</td>
                                        <td>Mohit</td>
                                        <td>Warranty</td>
                                        <td>Repair</td>
                                        <td>10-09-2024</td>
                                        <td>20-09-2024</td>
                                        <td>Rahul Sharma </td>
                                        <td >BMVH-1000A (Micro Vickers)</td>
                                        <td>
                                        <Link onClick={openMachineModalFourth}><FontAwesomeIcon className="editNotice" icon={faEdit} /></Link>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                    <td  style={{backgroundColor: "#0FDDAF"}}>BMVH-1000A (Micro Vickers)</td>
                                        <td >BMVH-1000A (Micro Vickers)</td>
                                        <td>22030384 (HT-15) </td>
                                        <td style={{backgroundColor: "#fcb514"}}>AMC</td>
                                        <td style={{backgroundColor: "#00cd66"}}>19-10-2024</td>
                                        <td>20</td>
                                        <td>01-10-2024</td>
                                        <td>20-10-2024</td>
                                        <td>Mohit</td>
                                        <td>Warranty</td>
                                        <td>Repair</td>
                                        <td>10-09-2024</td>
                                        <td>20-09-2024</td>
                                        <td>Rahul Sharma </td>
                                        <td >BMVH-1000A (Micro Vickers)</td>
                                        <td>
                                        <Link onClick={openMachineModalFourth}><FontAwesomeIcon className="editNotice" icon={faEdit} /></Link>
                                        </td>
                                        
                                    </tr>
                                </tbody>
                            </Table>
                        </div>    
                        
                        
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline", marginTop: 20}}>
                            
                            <div style={{display: "flex", alignItems: "baseline"}}>
                                <h2 className="mt-0 mb-0">Inventory</h2>
                                <h5 className="ml-2 mb-0">Latest 20</h5>

                            </div>
                            <Button onClick={openCreateModalagain} className="createClr mt-3">
                                View All
                            </Button>
                        </div>    
                        <Table striped responsive="lg">
                            <thead>
                                <tr>
                                    <th>Spare Name</th>
                                    <th>Machine</th>
                                    <th style={{whiteSpace: "nowrap"}}>Unit Consume</th>
                                    <th style={{whiteSpace: "nowrap"}}>Source Type</th>
                                    <th>Amount</th>
                                    <th>Paid</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td  >Test Block (60.7,33.6 HRC)</td>
                                    <td >BMVH-1000A (Micro Vickers)</td>
                                    <td>30 </td>
                                    <td>WARRANTY</td>
                                    <td>5000</td>
                                    <td>Online </td>
                                    <td >BMVH-1000A (Micro Vickers)</td>
                                </tr>
                                <tr>
                                <td  >DT T/B - 60.8 HRC, 33.9 HRC</td>
                                    <td >BMVH-1000A (Micro Vickers)</td>
                                    <td>70 </td>
                                    <td>AMC</td>
                                    <td>9000</td>
                                    <td>Card </td>
                                    <td >BMVH-1000A (Micro Vickers)</td>
                                </tr>
                                <tr>
                                <td  >Test Block - DT Make (33.7 HRC)</td>
                                    <td >BMVH-1000A (Micro Vickers)</td>
                                    <td>10 </td>
                                    <td>AMC</td>
                                    <td>90000</td>
                                    <td>Cash </td>
                                    <td >BMVH-1000A (Micro Vickers)</td>
                                </tr>
                            </tbody>
                        </Table>

                        
                    </div>

                    {/* Third Column with Border and Shadow */}
                    <div className="col-md-4 custom-box">
                        <div className="row document-grid">
                            <div className="col-md-12">
                                <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                    <h6>Visit Info (Last 10) </h6>
                                    <Link  onClick={() => handleViewPdf('/assets/Machine.pdf')}>View All</Link>
                                </div>    
                                <div className="document-card p-2">
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                        <h6>Visit Date: <span  style={{backgroundColor: "#EE3A8C", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>29-04-2023</span></h6>
                                        <Link  onClick={() => handleViewPdf('/assets/Machine.pdf')}>View</Link>
                                    </div> 
                                    <h6 className="col-2" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Machine </h6>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#ff7f50", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Warranty</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Single Visit</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    
                                    <p>Inventory</p> 
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Cheque</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Online</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Single Visit</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Cash</h6>
                                    </div>
                                    <h6><strong>Visit by:</strong> Kunal , Atul</h6>
                                </div>

                                <div className="document-card p-2">
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                        <h6>Visit Date: <span  style={{backgroundColor: "#EE3A8C", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>29-04-2023</span></h6>
                                        <Link  onClick={() => handleViewPdf('/assets/Machine.pdf')}>View</Link>
                                    </div> 
                                    <h6 className="col-2" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Machine </h6>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#ff7f50", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Warranty</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Single Visit</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    
                                    <p>Inventory</p> 
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Cheque</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Online</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Single Visit</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Cash</h6>
                                    </div>
                                    <h6><strong>Visit by:</strong> Kunal , Atul</h6>
                                </div>

                                <div className="document-card p-2">
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                        <h6>Visit Date: <span  style={{backgroundColor: "#EE3A8C", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>29-04-2023</span></h6>
                                        <Link  onClick={() => handleViewPdf('/assets/Machine.pdf')}>View</Link>
                                    </div> 
                                    <h6 className="col-2" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Machine </h6>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#ff7f50", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Warranty</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Single Visit</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    
                                    <p>Inventory</p> 
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Cheque</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Online</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Single Visit</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Cash</h6>
                                    </div>
                                    <h6><strong>Visit by:</strong> Kunal , Atul</h6>
                                </div>

                                <div className="document-card p-2">
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                        <h6>Visit Date: <span  style={{backgroundColor: "#EE3A8C", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>29-04-2023</span></h6>
                                        <Link  onClick={() => handleViewPdf('/assets/Machine.pdf')}>View</Link>
                                    </div> 
                                    <h6 className="col-2" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Machine </h6>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#ff7f50", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Warranty</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Single Visit</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    
                                    <p>Inventory</p> 
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Cheque</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Online</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Single Visit</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Cash</h6>
                                    </div>
                                    <h6><strong>Visit by:</strong> Kunal , Atul</h6>
                                </div>

                                <div className="document-card p-2">
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                        <h6>Visit Date: <span  style={{backgroundColor: "#EE3A8C", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>29-04-2023</span></h6>
                                        <Link  onClick={() => handleViewPdf('/assets/Machine.pdf')}>View</Link>
                                    </div> 
                                    <h6 className="col-2" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Machine </h6>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#ff7f50", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Warranty</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6> BMVH-1000A,</h6>
                                        <h6 className="mt-1">22030384,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Single Visit</h6>
                                        <h6> Amc Done By Deepanshu	</h6>
                                    </div>
                                    
                                    <p>Inventory</p> 
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Cheque</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#fcb514", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>AMC</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Online</h6>
                                    </div>
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline" , overflowX: 'auto'}}>
                                        <h6 className="mt-0">Test Block (60.7,33.6 HRC),</h6>
                                        <h6> 2,</h6>
                                        <h6 className="mt-1" style={{backgroundColor: "#00cd66", padding: 4, marginBottom: 0, borderRadius: 3, color: "#fff"}}>Single Visit</h6>
                                        <h6 className="mt-0">2000/-,</h6>
                                        <h6> Cash</h6>
                                    </div>
                                    <h6><strong>Visit by:</strong> Kunal , Atul</h6>
                                </div>


                                <div className="document-card">
                                    <div  style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                        <h6>Documents (Last 10) </h6>
                                        <Link  onClick={() => handleViewPdf('/assets/Machine.pdf')}>View All</Link>
                                    </div> 
                                    <div className="document-card mt-2 p-2">   
                                        <h6 className="mt-1">AMC Work Order 01-03-2023 to 29-04-2023</h6>
                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                            <p><strong>Upload Date:</strong> 2023-03-01 16:26:02</p>
                                            <Link  onClick={() => handleViewPdf('/assets/Machine.pdf')}>View</Link>
                                        </div>
                                    </div>
                                    <div className="document-card mt-2 p-2">   
                                        <h6 className="mt-1">AMC Work Order 01-03-2023 to 29-04-2023</h6>
                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                            <p><strong>Upload Date:</strong> 2023-03-01 16:26:02</p>
                                            <Link  onClick={() => handleViewPdf('/assets/Machine.pdf')}>View</Link>
                                        </div>
                                    </div>
                                    <div className="document-card mt-2 p-2">   
                                        <h6 className="mt-1">AMC Work Order 01-03-2023 to 29-04-2023</h6>
                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                            <p><strong>Upload Date:</strong> 2023-03-01 16:26:02</p>
                                            <Link  onClick={() => handleViewPdf('/assets/Machine.pdf')}>View</Link>
                                        </div>
                                    </div>
                                    <div className="document-card mt-2 p-2">   
                                        <h6 className="mt-1">AMC Work Order 01-03-2023 to 29-04-2023</h6>
                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                            <p><strong>Upload Date:</strong> 2023-03-01 16:26:02</p>
                                            <Link  onClick={() => handleViewPdf('/assets/Machine.pdf')}>View</Link>
                                        </div>
                                    </div>
                                    <div className="document-card mt-2 p-2">   
                                        <h6 className="mt-1">AMC Work Order 01-03-2023 to 29-04-2023</h6>
                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                            <p><strong>Upload Date:</strong> 2023-03-01 16:26:02</p>
                                            <Link  onClick={() => handleViewPdf('/assets/Machine.pdf')}>View</Link>
                                        </div>
                                    </div>
                                    <div className="document-card mt-2 p-2">   
                                        <h6 className="mt-1">AMC Work Order 01-03-2023 to 29-04-2023</h6>
                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                            <p><strong>Upload Date:</strong> 2023-03-01 16:26:02</p>
                                            <Link  onClick={() => handleViewPdf('/assets/Machine.pdf')}>View</Link>
                                        </div>
                                    </div>
                                </div>

                                
                            </div>
                            {/* Add more document cards here */}
                        </div>
                        
                    </div>
                    
                </div>

                {/* Modal for PDF Viewer */}
                <Modal size="lg" show={showPdfModal} onHide={() => setShowPdfModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>PDF Viewer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {pdfFile && (
                            <div>
                                <Document
                                    file={pdfFile}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page pageNumber={pageNumber} />
                                </Document>
                                <div>
                                    <p>
                                        Page {pageNumber} of {numPages}
                                    </p>
                                    <Button 
                                        onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
                                        disabled={pageNumber <= 1}
                                    >
                                        Previous
                                    </Button>
                                    <Button 
                                        onClick={() => setPageNumber(prev => Math.min(prev + 1, numPages))}
                                        disabled={pageNumber >= numPages}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowPdfModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

               

                {/* Modal */}
                <Modal size="md" show={showFirstModal} onHide={() =>  setShowFirstModal(false)} className="modal-100w modal-md clientmodal right text-end p-0" aria-labelledby="contained-modal-title-vcenter ">
                        <Modal.Header closeButton className='headerMDL' style={{backgroundColor: "#2e333f"}}>
                            <Modal.Title id="contained-modal-title-vcenter" onClick={openCreateModalagain}>
                                Add Machine
                                
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pt-0">

              <div className="container" style={{height: "77vh"}}>
                       <div className="card-no-border">
                      <div className="row" id="validation">
                    <div className="col-12">
                        <div className="card wizard-content">
                            <div className="card-body">
                         
                                <form action="#" className="validation-wizard wizard-circle wizard clearfix" role="application" id="steps-uid-2" novalidate="novalidate">
                                    
                                        <div className="content clearfix">
                                     
                                    
                                    <section id="steps-uid-2-p-0" role="tabpanel" aria-labelledby="steps-uid-2-h-0" className="body current pt-4" aria-hidden="false">
                                        <div className="row text-left">
                                            <div className="col-md-12 mb-2">
                                                <div className="form-group">
                                                    <label for="wfirstName2"> Machine Name  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Machine</option>
                                                        <option value="volvo">Machine 1</option>
                                                        <option value="saab">Machine 2</option>
                                                        <option value="mercedes">Machine 3</option>
                                                        <option value="audi">Machine 4</option>
                                                        <option value="audi">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Model Number   <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Model</option>
                                                        <option value="volvo">Model 1</option>
                                                        <option value="saab">Model 2</option>
                                                        <option value="mercedes">Model 3</option>
                                                        <option value="audi">Model 4</option>
                                                        <option value="audi">Other</option>
                                                    </select>
                                                </div>
                                                
                                                    
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Serial Number  <span className="danger">*</span> </label>
                                                    <input type="text"   className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> </div>
                                                
                                                    
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Status  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Status</option>
                                                        <option value="volvo">AMC</option>
                                                        <option value="saab">Warranty</option>
                                                        <option value="mercedes">Single-Visit</option>
                                                    </select>
                                                </div>
                                                
                                                    
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Service Start Date  <span className="danger">*</span> </label>
                                                    <input type="date" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                
                                                    
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Service End Date  <span className="danger">*</span> </label>
                                                    <input type="date" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                
                                                    
                                            </div>

                                        </div>  
                                    </section>
 
                                </div>
                                
                                <div className="actions clearfix mt-3">
                                    <ul role="menu" aria-label="Pagination">
                                        
                                        
                                            <div className="  align-self-start">
                                                <button type="button" className="btn border bg-white" > Cancel</button>
                                                <button type="button" className="btn btn-info"> Add</button>
                                            </div>
                                            
                                    </ul>
                                    </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
              </div>
        </Modal.Body>
                </Modal>

                <Modal size="md" show={showSecondModal} onHide={() =>  setShowSecondModal(false)} className="modal-100w modal-md clientmodal right text-end p-0" aria-labelledby="contained-modal-title-vcenter ">
                        <Modal.Header closeButton className='headerMDL' style={{backgroundColor: "#2e333f"}}>
                            <Modal.Title id="contained-modal-title-vcenter" onClick={openCreateModalagain}>
                                Add Contact Person
                                
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pt-0">

              <div className="container" style={{height: "100vh"}}>
                       <div className="card-no-border">
                      <div className="row" id="validation">
                    <div className="col-12">
                        <div className="card wizard-content pt-0">
                            <div className="card-body pt-0">
                         
                                <form action="#" className="validation-wizard wizard-circle wizard clearfix" role="application" id="steps-uid-2" novalidate="novalidate">
                                    
                                        <div className="content clearfix">
                                     
                                    
                                    <section id="steps-uid-2-p-0" role="tabpanel" aria-labelledby="steps-uid-2-h-0" className="body current pt-0" aria-hidden="false">
                                        <div className="row text-left">
                                            <div className="col-md-12 mb-2">
                                                <div className="form-group">
                                                    <label for="wfirstName2"> Person Image  <span className="danger">*</span> </label>
                                                    <div className="form-group parentUploadContainer">

                                                    <div className="" for="wfirstName2">

                                                        <label for="wfirstName2" className='uploadcontainer'> <FontAwesomeIcon icon={faPaperclip} />  &nbsp; Browse Image  <span className="danger">*</span>
                                                        <input type="file" onChange={(e) => { handleFileChange(e) }} value={''} className="form-control required" id="wfirstName2" name="firstName" aria-required="true" />
                                                        {fileURL && <img src={fileURL} alt="Selected" className="img-fluid" style={{ width: "100px" }} />}
                                                        </label>
                                                    </div>
                                                    

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Name <span className="danger">*</span> </label>
                                                    <input type="text"   className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> </div>
                                                
                                                    
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Department  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Department</option>
                                                        <option value="volvo">HR</option>
                                                        <option value="saab">Account</option>
                                                        <option value="mercedes">Sales</option>
                                                        <option value="mercedes">Other</option>
                                                    </select>
                                                </div>
                                                
                                                    
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Department  <span className="danger">*</span> </label>
                                                    <input type="text" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                
                                                    
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Color Coding  <span className="danger">*</span> </label>
                                                    <input type="Text" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                
                                                    
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Mobile Number  <span className="danger">*</span> </label>
                                                    <input type="number" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                
                                                    
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Email  <span className="danger">*</span> </label>
                                                    <input type="email" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                
                                                    
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Address  <span className="danger">*</span> </label>
                                                    <input type="email" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                
                                                    
                                            </div>

                                        </div>  
                                    </section>
 
                                </div>
                                
                                <div className="actions clearfix mt-3">
                                    <ul role="menu" aria-label="Pagination">
                                        
                                        
                                            <div className="  align-self-start">
                                                <button type="button" className="btn border bg-white" > Cancel</button>
                                                <button type="button" className="btn btn-info"> Add</button>
                                            </div>
                                            
                                    </ul>
                                    </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
              </div>
        </Modal.Body>
                </Modal>

                <Modal size="md" show={showVisitDetailModal} onHide={() =>  setShowVisitDetailModal(false)} className="modal-100w modal-md clientmodal right text-end p-0" aria-labelledby="contained-modal-title-vcenter ">
                        <Modal.Header closeButton className='headerMDL' style={{backgroundColor: "#2e333f"}}>
                            <Modal.Title id="contained-modal-title-vcenter" onClick={openCreateModalagain}>
                                Add Visit Detail
                                
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pt-0">

              <div className="container p-0" style={{height: "150vh"}}>
                       <div className="card-no-border p-0">
                      <div className="row p-0" id="validation">
                    <div className="col-12 p-0">
                        <div className="card wizard-content pt-0 p-0">
                            <div className="card-body pt-0 p-0">
                         
                                <form action="#" className="validation-wizard wizard-circle wizard clearfix" role="application" id="steps-uid-2" novalidate="novalidate">
                                    
                                        <div className="content clearfix">
                                     
                                    
                                    <section id="steps-uid-2-p-0" role="tabpanel" aria-labelledby="steps-uid-2-h-0" className="body current pt-0" aria-hidden="false">
                                        <div className="row text-left">
                                            <div className="col-md-12">
                                                <div className="form-group col-8 p-0">
                                                    <label for="wlastName2"> Visit Date  <span className="danger">*</span> </label>
                                                    <input type="date" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                            <div className="d-flex mt-2" style={{justifyContent: "space-between", alignItems: "baseline"}}>
                                                <h4>Machine Info</h4>
                                                <button type="button" className="btn btn-info"> Add</button>
                                            </div>
                                                
                                            <div className="d-flex">
                                                <div className="form-group col-4 pl-0">
                                                    <label for="wlastName2"> Machine Name <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Machine Name</option>
                                                        <option value="volvo">BMVH-1000A (Micro Vickers)	</option>
                                                        <option value="saab">BMVH-1000A (Micro Vickers)	</option>
                                                        <option value="mercedes">22030384 (HT-15)	</option>
                                                        <option value="mercedes">DT T/B - 60.8 HRC, 33.9 HRC	</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-4">
                                                    <label for="wlastName2"> Purpose  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Purpose</option>
                                                        <option value="volvo">AMC</option>
                                                        <option value="saab">2nd Visit</option>
                                                        <option value="mercedes">Warranty</option>
                                                        <option value="mercedes">Single Visit</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-4 pr-0">
                                                    <label for="wlastName2"> Comment  <span className="danger">*</span> </label>
                                                    <input type="text" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                            </div>  

                                            <div className="d-flex">
                                                <div className="form-group col-4 pl-0">
                                                    <label for="wlastName2"> Machine Name <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Machine Name</option>
                                                        <option value="volvo">BMVH-1000A (Micro Vickers)	</option>
                                                        <option value="saab">BMVH-1000A (Micro Vickers)	</option>
                                                        <option value="mercedes">22030384 (HT-15)	</option>
                                                        <option value="mercedes">DT T/B - 60.8 HRC, 33.9 HRC	</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-4">
                                                    <label for="wlastName2"> Purpose  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Purpose</option>
                                                        <option value="volvo">AMC</option>
                                                        <option value="saab">2nd Visit</option>
                                                        <option value="mercedes">Warranty</option>
                                                        <option value="mercedes">Single Visit</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-4 pr-0">
                                                    <label for="wlastName2"> Comment  <span className="danger">*</span> </label>
                                                    <input type="text" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                            </div>  

                                            <div className="d-flex">
                                                <div className="form-group col-4 pl-0">
                                                    <label for="wlastName2"> Machine Name <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Machine Name</option>
                                                        <option value="volvo">BMVH-1000A (Micro Vickers)	</option>
                                                        <option value="saab">BMVH-1000A (Micro Vickers)	</option>
                                                        <option value="mercedes">22030384 (HT-15)	</option>
                                                        <option value="mercedes">DT T/B - 60.8 HRC, 33.9 HRC	</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-4">
                                                    <label for="wlastName2"> Purpose  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Purpose</option>
                                                        <option value="volvo">AMC</option>
                                                        <option value="saab">2nd Visit</option>
                                                        <option value="mercedes">Warranty</option>
                                                        <option value="mercedes">Single Visit</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-4 pr-0">
                                                    <label for="wlastName2"> Comment  <span className="danger">*</span> </label>
                                                    <input type="text" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                            </div>    
                                                        
                                            </div>
                                            
                                            <div className="d-flex mt-4" style={{justifyContent: "space-between", alignItems: "baseline"}}>
                                                <h4>Inventory</h4>
                                                <button type="button" className="btn btn-info"> Add</button>
                                            </div>
                                            <div className="col-md-12 d-flex">
                                                <div className="form-group col-3 p-0">
                                                    <label for="wlastName2"> Spare name  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select  Spare</option>
                                                        <option value="volvo"> Spare 1</option>
                                                        <option value="saab">Spare 2</option>
                                                        <option value="mercedes">Spare 3</option>
                                                        <option value="mercedes">Spare 4</option>
                                                        <option value="mercedes">Other</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-2 p-0">
                                                    <label for="wlastName2"> Unit   <span className="danger">*</span> </label>
                                                    <input type="number" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                <div className="form-group col-2 p-0">
                                                    <label for="wlastName2"> Purpose   </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Purpose</option>
                                                        <option value="volvo">AMC</option>
                                                        <option value="saab">2nd Visit</option>
                                                        <option value="mercedes">Warranty</option>
                                                        <option value="mercedes">Single Visit</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-2 p-0">
                                                    <label for="wlastName2"> Charge  <span className="danger">*</span> </label>
                                                    <input type="email" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                <div className="form-group col-3 p-0">
                                                    <label for="wlastName2"> Payment Mode  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Purpose</option>
                                                        <option value="volvo">Cash</option>
                                                        <option value="saab">Online</option>
                                                        <option value="mercedes">Cheque</option>
                                                    </select>
                                                </div>
                                                
                                                    
                                            </div>

                                            <div className="col-md-12 d-flex">
                                                <div className="form-group col-3 p-0">
                                                    <label for="wlastName2"> Spare name  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select  Spare</option>
                                                        <option value="volvo"> Spare 1</option>
                                                        <option value="saab">Spare 2</option>
                                                        <option value="mercedes">Spare 3</option>
                                                        <option value="mercedes">Spare 4</option>
                                                        <option value="mercedes">Other</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-2 p-0">
                                                    <label for="wlastName2"> Unit   <span className="danger">*</span> </label>
                                                    <input type="number" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                <div className="form-group col-2 p-0">
                                                    <label for="wlastName2"> Purpose   </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Purpose</option>
                                                        <option value="volvo">AMC</option>
                                                        <option value="saab">2nd Visit</option>
                                                        <option value="mercedes">Warranty</option>
                                                        <option value="mercedes">Single Visit</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-2 p-0">
                                                    <label for="wlastName2"> Charge  <span className="danger">*</span> </label>
                                                    <input type="email" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                <div className="form-group col-3 p-0">
                                                    <label for="wlastName2"> Payment Mode  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Purpose</option>
                                                        <option value="volvo">Cash</option>
                                                        <option value="saab">Online</option>
                                                        <option value="mercedes">Cheque</option>
                                                    </select>
                                                </div>
                                                
                                                    
                                            </div>

                                            <div className="col-md-12 d-flex">
                                                <div className="form-group col-3 p-0">
                                                    <label for="wlastName2"> Spare name  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select  Spare</option>
                                                        <option value="volvo"> Spare 1</option>
                                                        <option value="saab">Spare 2</option>
                                                        <option value="mercedes">Spare 3</option>
                                                        <option value="mercedes">Spare 4</option>
                                                        <option value="mercedes">Other</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-2 p-0">
                                                    <label for="wlastName2"> Unit   <span className="danger">*</span> </label>
                                                    <input type="number" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                <div className="form-group col-2 p-0">
                                                    <label for="wlastName2"> Purpose   </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Purpose</option>
                                                        <option value="volvo">AMC</option>
                                                        <option value="saab">2nd Visit</option>
                                                        <option value="mercedes">Warranty</option>
                                                        <option value="mercedes">Single Visit</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-2 p-0">
                                                    <label for="wlastName2"> Charge  <span className="danger">*</span> </label>
                                                    <input type="email" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                <div className="form-group col-3 p-0">
                                                    <label for="wlastName2"> Payment Mode  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Purpose</option>
                                                        <option value="volvo">Cash</option>
                                                        <option value="saab">Online</option>
                                                        <option value="mercedes">Cheque</option>
                                                    </select>
                                                </div>
                                                
                                                    
                                            </div>

                                            <div className="col-md-12">
                                                

                                                <label for="wfirstName2">Add Service Report <span className="danger">*</span> </label>
                                                    <div className="form-group parentUploadContainer">

                                                    <div className="" for="wfirstName2">

                                                        <label for="wfirstName2" className='uploadcontainer'> <FontAwesomeIcon icon={faPaperclip} />  &nbsp;  Upload Report  <span className="danger">*</span>
                                                        <input type="file"  value={''} className="form-control required" id="wfirstName2" name="firstName" aria-required="true" />
                                                        </label>
                                                    </div>
                                                    </div>
                                                    
                                            </div>

                                            <div className="d-flex mt-2" style={{justifyContent: "space-between", alignItems: "baseline"}}>
                                                <h4>Visit By</h4>
                                                <button type="button" className="btn btn-info"> Add</button>
                                            </div>
                                                
                                            <div className="d-flex" style={{alignItems: "center"}}>
                                                <div className="form-group col-6 pl-0" style={{alignItems: "center"}}>
                                                    <h4 for="wlastName2"> Employee </h4>
                                                </div>
                                                <div className="form-group col-6 pr-0">
                                                    <label for="wlastName2"> Employee name  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Purpose</option>
                                                        <option value="volvo">Employee 1</option>
                                                        <option value="saab">Employee 2</option>
                                                        <option value="mercedes">Employee 3</option>
                                                        <option value="mercedes">Employee 4</option>
                                                    </select>
                                                </div>
                                            </div>  

                                            <div className="d-flex" style={{alignItems: "center"}}>
                                                <div className="form-group col-6 pl-0">
                                                    <h4 for="wlastName2"> Employee </h4>
                                                </div>
                                                <div className="form-group col-6 pr-0">
                                                    <label for="wlastName2"> Employee name  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Purpose</option>
                                                        <option value="volvo">Employee 1</option>
                                                        <option value="saab">Employee 2</option>
                                                        <option value="mercedes">Employee 3</option>
                                                        <option value="mercedes">Employee 4</option>
                                                    </select>
                                                </div>
                                            </div>  

                                            <div className="d-flex" style={{alignItems: "center"}}>
                                                <div className="form-group col-6 pl-0">
                                                    <h4 for="wlastName2"> Employee </h4>
                                                </div>
                                                <div className="form-group col-6 pr-0">
                                                    <label for="wlastName2"> Employee name  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                        <option value="volvo">Select Purpose</option>
                                                        <option value="volvo">Employee 1</option>
                                                        <option value="saab">Employee 2</option>
                                                        <option value="mercedes">Employee 3</option>
                                                        <option value="mercedes">Employee 4</option>
                                                    </select>
                                                </div>
                                            </div>  

                                        </div>  
                                    </section>
 
                                </div>
                                
                                <div className="actions clearfix mt-3">
                                    <ul role="menu" aria-label="Pagination">
                                        
                                        
                                            <div className="  align-self-start">
                                                <button type="button" className="btn border bg-white" > Cancel</button>
                                                <button type="button" className="btn btn-info"> Add</button>
                                            </div>
                                            
                                    </ul>
                                    </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
              </div>
        </Modal.Body>
                </Modal>

                <Modal size="md" show={showDocumentModal} onHide={() =>  setShowDocumentModal(false)} className="modal-100w modal-md clientmodal right text-end p-0" aria-labelledby="contained-modal-title-vcenter ">
                        <Modal.Header closeButton className='headerMDL' style={{backgroundColor: "#2e333f"}}>
                            <Modal.Title id="contained-modal-title-vcenter" onClick={openCreateModalagain}>
                                Add Document
                                
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pt-0">

              <div className="container" style={{height: "77vh"}}>
                       <div className="card-no-border">
                      <div className="row" id="validation">
                    <div className="col-12">
                        <div className="card wizard-content pt-0">
                            <div className="card-body pt-0">
                         
                                <form action="#" className="validation-wizard wizard-circle wizard clearfix" role="application" id="steps-uid-2" novalidate="novalidate">
                                    
                                        <div className="content clearfix">
                                     
                                    
                                    <section id="steps-uid-2-p-0" role="tabpanel" aria-labelledby="steps-uid-2-h-0" className="body current pt-0" aria-hidden="false">
                                        <div className="row text-left">
                                            <div className="col-md-12 mb-2">
                                                <div className="form-group">
                                                    <label for="wfirstName2"> Document<span className="danger">*</span> </label>
                                                    <div className="form-group parentUploadContainer">

                                                    <div className="" for="wfirstName2">

                                                        <label for="wfirstName2" className='uploadcontainer'> <FontAwesomeIcon icon={faPaperclip} />  &nbsp; Upload Document <span className="danger">*</span>
                                                        <input type="file"  value={''} className="form-control required" id="wfirstName2" name="firstName" aria-required="true" accept = "application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                                        {/* {fileURL && <img src={fileURL} alt="Selected" className="img-fluid" style={{ width: "100px" }}  />} */}
                                                        </label>
                                                    </div>
                                                    

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Title <span className="danger">*</span> </label>
                                                    <input type="text"   className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> </div>
                                                
                                                    
                                            </div>
                                           
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Document Type  <span className="danger">*</span> </label>
                                                    <input type="text" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                
                                                    
                                            </div>
                                           
                                        </div>  
                                    </section>
 
                                </div>
                                
                                <div className="actions clearfix mt-3">
                                    <ul role="menu" aria-label="Pagination">
                                        
                                        
                                            <div className="  align-self-start">
                                                <button type="button" className="btn border bg-white" > Cancel</button>
                                                <button type="button" className="btn btn-info"> Add</button>
                                            </div>
                                            
                                    </ul>
                                    </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
              </div>
        </Modal.Body>
                </Modal>

                <Modal size="md" show={showMachineModal} onHide={() =>  setShowMachineModal(false)} className="modal-100w modal-md clientmodal right text-end p-0" aria-labelledby="contained-modal-title-vcenter ">
                        <Modal.Header closeButton className='headerMDL' style={{backgroundColor: "#2e333f"}}>
                            <Modal.Title id="contained-modal-title-vcenter" onClick={openCreateModalagain}>
                                Update Machine
                                
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pt-0">

              <div className="container" style={{height: "77vh"}}>
                       <div className="card-no-border">
                      <div className="row" id="validation">
                    <div className="col-12">
                        <div className="card wizard-content pt-0">
                            <div className="card-body pt-0">
                         
                                <form action="#" className="validation-wizard wizard-circle wizard clearfix" role="application" id="steps-uid-2" novalidate="novalidate">
                                    
                                        <div className="content clearfix">
                                     
                                    
                                    <section id="steps-uid-2-p-0" role="tabpanel" aria-labelledby="steps-uid-2-h-0" className="body current pt-0" aria-hidden="false">
                                        <div className="row text-left">
                                            <div className="form-group col-12 pr-0">
                                                <label for="wlastName2"> Status <span className="danger">*</span> </label>
                                                <select name="cars" id="cars" className="form-control required">
                                                    <option value="volvo">Select Status</option>
                                                    <option value="volvo">AMC</option>
                                                    <option value="saab">Warranty</option>
                                                    <option value="mercedes">Single Visit</option>
                                                </select>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Start Date <span className="danger">*</span> </label>
                                                    <input type="datetime-local"   className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> </div>
                                                
                                                    
                                            </div>
                                           
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Expired Date  <span className="danger">*</span> </label>
                                                    <input type="datetime-local" id="birthday" name="birthday" className="form-control required"/>
                                                </div>
                                                
                                                    
                                            </div>
                                           
                                        </div>  
                                    </section>
 
                                </div>
                                
                                <div className="actions clearfix mt-3">
                                    <ul role="menu" aria-label="Pagination">
                                        
                                        
                                            <div className="  align-self-start">
                                                <button type="button" className="btn border bg-white" > Cancel</button>
                                                <button type="button" className="btn btn-info"> Add</button>
                                            </div>
                                            
                                    </ul>
                                    </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
              </div>
        </Modal.Body>
                </Modal>
            </div>
        </>
    );
}

export default ProjectDetails;
