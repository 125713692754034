import React , {useEffect, useRef, useState} from 'react'; 
import '../assets/css/custom.css'; 
import '../assets/css/client.css'; 
import '../assets/css/font-awesome.css';
import '../assets/css/sweetalert.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/steps.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import {
    faEdit,
    faPlus,
    faSort,
    faTrash,
    faPenToSquare,
    faTimes,
    faTowerBroadcast,
    faScrewdriver,
    faScrewdriverWrench,
    faMessage,
  } from "@fortawesome/free-solid-svg-icons";
// import { faEdit, faPlus, faSort } from '@fortawesome/free-solid-svg-icons';
import clientlogo from '../assets/img/user1.jpg'; 
import clientlogo2 from '../assets/img/user2.jpg'; 
import clientlogo3 from '../assets/img/user3.jpg'; 
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import ReactDOM from "react-dom";
// import { Wizard, Steps, Step, WithWizard } from "react-multistep-wizard";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { Stepper, Step, StepLabel } from '@mui/material';
import { Textarea } from '@material-tailwind/react';
import { Circle } from '@mui/icons-material';
import {commonAPI} from "../services/CommonAPIService"
import {ErrorSource} from '../common/constants'
import Menus from "./menus";
import Multiselect from 'multiselect-react-dropdown';
import Action from '../assets/img/Action.png';
import BroadCastImg from '../assets/img/megaphones.png';
import chat from '../assets/img/bubble-chat.png';
import JoditEditor from 'jodit-react';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';


  function NoticeApproval() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Step 1', 'Step 2', 'Step 3'];
    const [Show, setShow] = useState(false);
    const [ApproveShow, setApproveShow] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [NoticeList, setNoticeList] = useState("");
    const [TowerList, setTowerList] = useState("");
    const [TowerListGet, setTowerListGet] = useState("");
    const [FlatList, setFlatList] = useState("");
    const [FlatListGet, setFlatListGet] = useState("");
    const [FamilyList, setFamilyList] = useState("");
    const [NoticeSubject, setNoticeSubject] = useState("");
    const [NoticeDescription, setNoticeDescription] = useState("");
    const [NoticeEmail, setNoticeEmail] = useState();
    const [Importance, setImportance] = useState();
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const [NoticeType, setNoticeType] = useState("");
    const [NoticeTypeId, setNoticeTypeId] = useState();
    const [NoticeStatus, setNoticeStatus] = useState();
    const [NoticeTypeText, setNoticeTypeText] = useState("");
    const [NoticeId,setNoticeId] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageTwo,setSelectedImageTwo] = useState(null)
    const [firstSectionButton,setFirstSectionButton] = useState(false);
    const [TowerFlatArry,setTowerFlatArry] = useState([]);
    const [CurrentStatusSelected,setCurrentStatusSelected] = useState("PENDING");
    const [UserData, setUserData] = useState("");
    const [TowerSelected, setTowerSelected] = useState([]);
    const [FlatSelected, setFlatSelected] = useState([]);
    const [tower_, setTower_] = useState("");
    const [Flat_, setFlat_] = useState("");
    const [NoticeImages, setNoticeImages] = useState("");
    const [NoticeAddId, setNoticeAddId] = useState("");
    const [ApproverComment, setApproverComment] = useState([]);
    const [ApproverCommentInput, setApproverCommentInput] = useState("");
    const [fullscreen, setFullscreen] = useState(true);
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [updatedTowerItem, setUpdatedTowerItem ] = useState(null);
    const [removedTowerItem, setRemovedTowerItem ] = useState(null);
    const [updatedFlatItem, setUpdatedFlatItem ] = useState(null);
    const [removedFlatItem, setRemovedFlatItem ] = useState(null);
    console.log("TowerSelectedget", TowerSelected)

    console.log("updated Tower Id", updatedTowerItem)



    // console.log("towerSelect", TowerSelected, "flatSelected", FlatSelected);

    console.log("flat list data", FlatList)
    console.log("flatSelected", FlatSelected);

    const options = [
        { value: 'chocolate', label: 'Chocolate', id: '1' },
        { value: 'strawberry', label: 'Strawberry', id: '2' },
        { value: 'vanilla', label: 'Vanilla', id: '3' }
      ]
    
    useEffect(() => {
        // GetFlatList();
        if(TowerSelected.length){
            console.log("i am run")
            const temp = TowerSelected.map((item)=>{
                return{
                    towerId: item.id,
                    flatId: null
                }
            })
            setTower_(temp)}

    }, [TowerSelected])
    console.log("this is towerSelected, ", tower_)

    const BroadCastMeseg = (item) => {
        setShow(true)
        console.log("item", item)
        setNoticeSubject(item.noticeSubject)
        setNoticeDescription(item.noticeDescription)
        setNoticeTypeId(item.noticeTypeId)
        setNoticeTypeText(item.noticeTypeText)
        setNoticeStatus(item.currentStatus)
        setNoticeEmail(item.notifyOnEmail == true ? "Yes" : "No")
        setImportance(item.importance)
        setStartDate(item.effectiveStartDt)
        setEndDate(item.effectiveEndDt)
        setSelectedImage()
        setSelectedImageTwo();
    };
   
    const openCreateModal = () => {
        setShow(true)
        setNoticeSubject("")
        setNoticeDescription("")
        setNoticeEmail("")
        setImportance("")
        setStartDate("")
        setEndDate("")
        setSelectedImage("")
        setSelectedImageTwo("");
    }

    function ImageUpload() {
        const [selectedImage, setSelectedImage] = useState(null);

        const handleFileInputChange = (event) => {
            const file = event.target.files[0];
            if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
            }
        };
    }

    const convertISTTime = (date) => {
        if(date != null || date != "" || date != undefined ){
            const [datePart, TimePart] = date.split(" ");
            const [mm, dd, yyyy ] = datePart.split("/");
            // const [hh, mn, ss] = TimePart.split(':')
            const formatedTime = `${dd}-${mm}-${yyyy}`;
            return formatedTime;
        }else{
            console.warn("input date string may be null, empty string or undefined");
        }
    }

    const convertISSet = (date) => {
        if(date != null || date != "" || date != undefined ){
            let [datePart, TimePart] = date.split(" ");
            let [mm, dd, yyyy ] = datePart.split("/");
            // const [hh, mn, ss] = TimePart.split(':')
            // if(mm<10){mm.padStart(2, "0")};
            // if(dd<10){dd.padStart(2, "0")};
            if(mm<10){
               mm = "0"+mm
            }
            if(dd<10){
               dd = "0"+dd
            }
            const formatedTime = `${yyyy}-${mm}-${dd}`;
            return formatedTime;
        }else{
            console.warn("input date string may be null, empty string or undefined");
        }
    }

    const endPointVariableKey = {
        urlVariable:null,
        buildingId: 33
    }
    
    const GetNoticeList = async () => {
        const bodydata = { 
            "noticeId": 0,
            "currentStatus": CurrentStatusSelected,
            "validUpto": null
          };
        try {
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            setUserData(myUserData);
            console.log("myuserData", myUserData)
            const endPointVariableKey = {
                urlVariable:null,
                buildingId: myUserData.buildingId
            }
            let response = await commonAPI("Notice", bodydata, endPointVariableKey)
            // console.log("Notice List", response?.data)
            if (response?.success === true){
                console.log("NoticeList====================", response)
                setNoticeList(response?.data);
            }else{
                toast.error("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": UserData?.userId,
                "mobileNumber": UserData?.mobileNumber, // Need to be change
                "requestJSON": bodydata,
                "uri": "/api/Master/Notice",
                "errorNumber": "NL01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "NoticeApproval.js",
                "functionName": "GetNoticeList",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true & response?.message?.length == null){
                toast.error("Something went wrong");
                setLoading(false); 
            }else{
                toast.error("Something went wrong");
                setLoading(false); 
            }
        }

    }

    const GetNoticeImages = async () => {
        const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
        const endPointVariableKey    = {
            urlVariable: NoticeId,
            buildingId: myUserData?.buildingId
        }
        try {
            setLoading(true);
            let response = await commonAPI("NoticeImages", undefined, endPointVariableKey)
            if (response?.success===true && response?.message?.length == 0){
                console.log("Notice Get Images", response)
                setNoticeImages(response?.data)
                setLoading(false);
            }else{
                setLoading(false);
                toast.error("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": UserData?.userId,
                "mobileNumber": UserData?.mobileNumber, // Need to be change
                "requestJSON": null,
                "uri": "/api/Master/Notice/Images",
                "errorNumber": "NI01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "GetNoticeImages",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true && response?.message?.length == 0){
                toast.error("Something went wrong ");
                setLoading(false); 
            }else{
                toast.error("Something went wrong ");
                setLoading(false); 
            }
        }

    }

    useEffect(() => {
        if(NoticeId){
            GetNoticeImages();
        }    
    }, [NoticeId])

    const GetNoticeType = async () => {

        try {
            let response = await commonAPI("NoticeType", undefined, endPointVariableKey)
            // console.log("Notice List", response.data)
            if (response?.success===true){
                // console.log("NoticeType", response)
                setNoticeType(response?.data)
            }else{
                alert("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            alert("Something went wrong");
        }

    }

    const GetTowerList = async () => {
        try {
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            setUserData(myUserData);
            console.log("myuserData", myUserData)
            const endPointVariableKey = {
                urlVariable:null,
                buildingId: myUserData.buildingId
            }
            let response = await commonAPI("Tower", undefined, endPointVariableKey)
            // console.log("Tower List", response?.data)
            if (response?.success === true){
                console.log("tower]]]]]]]]]]", response)
                setTowerListGet(response?.data)
                const tempData = response?.data.map((item) => {return {value: item?.towerId, label: item?.towerName,}});
                setTowerList(tempData);
                // console.log("tower]]]]]]]]]]", tempData)
                

                // for(var k=0;k<noticeRecipientTransitData.length;k++){
                    
                // }

                // var count = Object.keys(response.responseData.transports).length;
                // let transportValue = [];
                // for (var i = 0; i < count; i++) {
                //     transportValue.push({
                //     value: response.responseData.transports[i],
                //     });
                // }

            }else{
                alert("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": UserData?.userId,
                "mobileNumber": UserData?.mobileNumber, // Need to be change
                "requestJSON": "",
                "uri": "/api/Master/Notice",
                "errorNumber": "NL01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "GetNoticeList",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true & response?.message?.length == null){
                toast.error("Something went wrong");
                setLoading(false); 
            }else{
                toast.error("Something went wrong");
                setLoading(false); 
            }
        }

    }

    const GetFlatList = async () => {

        console.log("this function run")
        try {
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            setUserData(myUserData);
            console.log("myuserData", myUserData)
            const endPointVariableKey = {
                urlVariable:null,
                buildingId: myUserData.buildingId
            }
            let response = await commonAPI("Flat", undefined, endPointVariableKey)
            // console.log("Flat List", response?.data)
            if (response?.success === true){
                // console.log("Flat List", response)
                setFlatListGet(response?.data)
                // console.log("updatedValue", updatedTowerItem)
                setRemovedTowerItem(null);
                const temp_Data = response?.data.filter(item => item.towerId == updatedTowerItem?.value);
                // const tempDataFlat = response?.data?.map((item) => {return {value: item.flatId, label: item.flatName}});
                const tempDataFlat = temp_Data?.map((item) => {return {value: item?.flatId, label: item?.flatName, towerId: item?.towerId}});
                console.log("Flat IS THERE", temp_Data);
                // setFlatList(tempDataFlat);
                // setFlaList((prev) =>  )
                setFlatList((prev) => (prev ? prev.concat(tempDataFlat) : tempDataFlat));                // setFlatList(tempDataFlat);
                 
                return;

                // var count = Object.keys(FlatListGet[i]?.towerId).length;
                const dataFlat = response?.data;
                const TowerFlat = [];
                setTowerFlatArry(TowerFlat);
                for (var i = 0; i < dataFlat.length; i++) {  
                    var dateGet=false;
                    var tower={};
                    for(var j = 0; j < TowerFlat.length; j++){
                        if(TowerFlat[j].towerId==dataFlat[i].towerId){
                            dateGet=true;
                            tower=TowerFlat[j];
                            break;
                        }
                    }
                    if(dateGet==true){
                        tower.flats.push(dataFlat[i]);
                    }else{
                        tower.towerId=dataFlat[i].towerId;
                        tower.display=false;
                        tower.flats=[];
                        tower.flats.push(dataFlat[i]);
                        TowerFlat.push(tower);
                    }
                }

                console.log("TowerFlatArry", TowerFlat)

                const SelectedTowerArry = [];
                const FilterFlatArry = [];
                for(var k=0;k<TowerSelected.length;k++){
                    SelectedTowerArry.push(TowerSelected[k].id);
                }
                for(var j=0;j<TowerFlatArry.length;j++){
                    TowerFlatArry[j].display=false;
                    if(SelectedTowerArry.indexOf(TowerFlatArry[j].towerId)!==-1){
                        TowerFlatArry[j].display=true;
                        FilterFlatArry.push(TowerFlatArry[j].flats)
                    }
                }
                
                const FilterFlatListArry = [];
                for(var l=0;l<FilterFlatArry.length;l++){
                    FilterFlatListArry.push(FilterFlatArry[l]);
                }
                
                let concatenatedArray = [];
                for (let i = 0; i < FilterFlatListArry.length; i++) {
                    concatenatedArray = concatenatedArray.concat(FilterFlatListArry[i]);
                }
                 console.log("this is concatanated array", concatenatedArray)

                const Temp_ = FilterFlatListArry?.map((item)=>{return {
                    flatId:item?.flatId,
                    towerId:item?.towerId
                }})
                setFlat_(Temp_);

                const tempData = concatenatedArray?.map((item) => {return {value: item.flatId, label: item.flatName}});
                setFlatList(tempData);
                console.log("FilterFlatArry0000000000", FilterFlatListArry)
                // const filterDataFlat = FilterFlatArry[0]?.map((item) => {return {name: item?.flatName, id: item?.flatId}});
                // setFlatList(filterDataFlat)
                // const selectFlatObj = [];
                // for(var l=0;l<TowerSelected.length;l++){
                //     for(var m=0;m<selectFlatObj.length;m++){
                //         // if(selectFlatObj[m].towerId==TowerSelected[l].towerId){
                //         //     // exists=true;
                //         //     // setFlatList(selectFlatObj)
                //         //     // break;
                //         // }
                //     }
                // }   
                // console.log("selectedFlatObj*********", selectFlatObj) 
                // const tempDataFlatFilter = response?.data?.filter(item => item.towerId == TowerSelected,);
                // const temp_DataFilter = TowerListGet.filter(item => item.towerId == temp_Data,);
            }else{
                alert("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = JSON.stringify({ 
                "userId": UserData?.userId,
                "mobileNumber": UserData?.mobileNumber, // Need to be change
                "requestJSON": "",
                "uri": "/api/Master/Notice",
                "errorNumber": "NL01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "GetNoticeList",
            });
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true & response?.message?.length == null){
                toast.error("Something went wrong");
                setLoading(false); 
            }else{
                toast.error("Something went wrong");
                setLoading(false); 
            }
        }

    }

    useEffect(() => {
        GetNoticeType();
        GetTowerList();
        // GetFlatList();
        // GetPublishList();
    }, [])

    useEffect(() => {
        if(updatedTowerItem){
            console.log("flatlist set on tower update")
            GetFlatList();
        }
    }, [updatedTowerItem])


    useEffect(() => {
        GetNoticeList();
    }, [CurrentStatusSelected])

    

    const GetPublishList = async () => {
        try {
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            setUserData(myUserData);
            console.log("myuserData", myUserData)
            const endPointVariableKey = {
                urlVariable:NoticeId,
                buildingId: myUserData.buildingId
            }
            let response = await commonAPI("NoticeRecipient", undefined, endPointVariableKey)
            console.log("Notice Recipient List", response?.data)
            if (response?.success === true){
                // console.log("Notice Recipient", response);
                const selectedTowers = [];
                const noticeRecipientTransitData = response?.data?.noticeRecipientTransitData;
                // console.log("selected=================", TowerListGet);
                
                for(var k=0;k<noticeRecipientTransitData.length;k++){
                    for(var l=0;l<TowerListGet.length;l++){
                        if(noticeRecipientTransitData[k].towerId ==  TowerListGet[l].towerId){
                            var exists=false;
                            for(var m=0;m<selectedTowers.length;m++){
                                if(selectedTowers[m].towerName==TowerListGet[l].towerName){
                                    exists=true;
                                    break;
                                }
                            }
                            if(exists==false){
                                const selectedTowersObj={};
                                selectedTowersObj.towerId = noticeRecipientTransitData[k].towerId ;
                                selectedTowersObj.towerName = TowerListGet[l].towerName ;
                                selectedTowers.push(selectedTowersObj);
                            }
                            break;
                        }
                    }
                }
                // console.log("selected=================", noticeRecipientTransitData,  selectedTowers, TowerListGet);
                const tempData = selectedTowers?.map((item) => {return {value: item?.towerId, label: item?.towerName}});

                const selectedFlat = [];
                setTowerSelected(tempData);
                console.log("selected=================", tempData);
                
                for(var k=0;k<noticeRecipientTransitData.length;k++){
                    for(var l=0;l<FlatListGet.length;l++){
                        if(noticeRecipientTransitData[k].flatId ==  FlatListGet[l].flatId){
                            var exists=false;
                            for(var m=0;m<selectedFlat.length;m++){
                                if(selectedFlat[m].flatName==FlatListGet[l].flatName){
                                    exists=true;
                                    break;
                                }
                            }
                            if(exists==false){
                                const selectedFlatObj={};
                                selectedFlatObj.flatId = noticeRecipientTransitData[k].flatId ;
                                selectedFlatObj.flatName = FlatListGet[l].flatName ;
                                selectedFlat.push(selectedFlatObj);
                            }
                            break;
                        }
                    }
                }
                console.log("selected=================", noticeRecipientTransitData,  selectedFlat, selectedFlat);
                const tempDataFlat = selectedFlat?.map((item) => {return {value: item.flatId, label: item.flatName, towerId: item?.towerId}});
                setFlatSelected(tempDataFlat);
                
                
                
                
                

                
            }else{
                toast.error("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": UserData?.userId,
                "mobileNumber": UserData?.mobileNumber, // Need to be change
                "requestJSON": "",
                "uri": "/api/Master/Notice",
                "errorNumber": "NL01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "GetNoticeList",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true & response?.message?.length == null){
                toast.error("Something went wrong");
                setLoading(false); 
            }else{
                toast.error("Something went wrong");
                setLoading(false); 
            }
        }

    }

    useEffect(() => {
        if (NoticeId){
            GetPublishList();
            // toast.error("publice Fuc Call");
        }
    }, [NoticeId])


    // handle remove item for tower list
    useEffect(() =>{
        if(removedTowerItem){
            setUpdatedTowerItem(null);
            console.log("removed item", removedTowerItem);
            const temp_Data = FlatListGet.filter(item => item.towerId == removedTowerItem?.value);
            console.log("temp Data", temp_Data);
            console.log("FlatList",FlatList);
            const tempDataFlat = temp_Data?.map((item) => {return {value: item?.flatId, label: item?.flatName}});
            const filteredMasterState = FlatList.filter(
                item => !tempDataFlat.some(tempItem => tempItem.value === item.value)
              );
              setFlatList(filteredMasterState);
              console.log("flatList value", filteredMasterState)

              if(FlatSelected){
                const filteredState = FlatSelected?.filter(
                    item => !tempDataFlat?.some(tempItem => tempItem?.value === item?.value)
                  );
                  setFlatSelected(filteredState);

              }
        }

    }, [removedTowerItem])
    
//   handle on change for towerList
  const handleTowerChange = (selectedOptions) => {
    if (selectedOptions.length > TowerSelected.length) {
      const addedItem = selectedOptions.find(
        item => !TowerSelected.some(selected => selected.value === item.value)
      );
      console.log('Added item:', addedItem);
      
      setUpdatedTowerItem(addedItem);
    }

    if (selectedOptions.length < TowerSelected.length) {
      const removedItem = TowerSelected.find(
        item => !selectedOptions.some(selected => selected.value === item.value)
      );
      console.log('Removed item:', removedItem);
      setRemovedTowerItem(removedItem);

    }

    setTowerSelected(selectedOptions);
  };


  //   handle on change for flatList
  const handleFlatChange = (selectedOptions) => {
    console.log("selected option for flat", selectedOptions)
    if (selectedOptions.length > TowerSelected.length) {
      const addedItem = selectedOptions.find(
        item => !FlatSelected.some(selected => selected.value === item.value)
      );
      console.log('Added item:', addedItem);
      
      setUpdatedFlatItem(addedItem);
    }

    if (selectedOptions.length < FlatSelected.length) {
      const removedItem = FlatSelected.find(
        item => !selectedOptions.some(selected => selected.value === item.value)
      );
      console.log('Removed item:', removedItem);
      setRemovedFlatItem(removedItem);

    }

    setFlatSelected(selectedOptions);
  };

  //handle remove item for flatList

//   useEffect(() =>{
//     if(removedFlatItem){
       
//     }

// }, [removedTowerItem])
  

    const checkBox = [
        // options: [{name: 'Option 1', id: 1},{name: 'Option 2️', id: 2}],
        TowerList
    ];
    // onSelect(selectedList, selectedItem) {
    //     console.log("selected or unsec", selectedList, selectedItem)
    // }  

    
    
    // onRemove(selectedList, removedItem) {
    //     ...
    // }

    const FlatcheckBox = {
        // options: [{name: 'Option 1', id: 1},{name: 'Option 2️', id: 2}],
        options: FlatList
    };

    const BroadCast = async () => {
        let body_Data;
        const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            setUserData(myUserData);
            console.log("myuserData", myUserData)
        if(TowerSelected.length ==0 && FlatSelected.length == 0){
            body_Data = [{
                "towerId": null,
                "flatId": null
              }]
        }else if(TowerSelected.length && FlatSelected.length == 0){
           body_Data = TowerSelected.map((item) => ({"towerId": item.value,
           "flatId": null}));
        }else{
            body_Data = FlatSelected?.map((item) => ({
                "towerId": item.towerId,
                "flatId": item.value
            }))
        }

        
        const bodydata ={
            "noticeId": NoticeId,
            "createdUserId": JSON.stringify(myUserData.userId),
            "noticeRecipientTransitData": body_Data
        }

        console.log("bodyRequest", bodydata)

        try {
            
            const endPointVariableKey = {
                urlVariable:null,
                buildingId: myUserData.buildingId
            }
            let response = await commonAPI("NoticeRecipientAdd", bodydata, endPointVariableKey)
            console.log("NoticeRecipientAdd", response)
            if (response?.success === true){
                // console.log("tower]]]]]]]]]]", response)
                toast.success("Notification Send");
                setShow(false)

                // for(var k=0;k<noticeRecipientTransitData.length;k++){
                    
                // }

                // var count = Object.keys(response.responseData.transports).length;
                // let transportValue = [];
                // for (var i = 0; i < count; i++) {
                //     transportValue.push({
                //     value: response.responseData.transports[i],
                //     });
                // }

            }else{
                toast.error("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": UserData?.userId,
                "mobileNumber": UserData?.mobileNumber, // Need to be change
                "requestJSON": "",
                "uri": "/api/Master/Notice",
                "errorNumber": "NL01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "GetNoticeList",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
            console.log("Error", response)
            if(response?.success === true & response?.message?.length == null){
                toast.error("Something went wrong");
                setLoading(false); 
            }else{
                toast.error("Something went wrong");
                setLoading(false); 
            }
        }

    }

    const ApproveModal = (item) => {
        setApproveShow(true);
        console.log("itemApproveModal", item)
        setNoticeSubject(item.noticeSubject)
        setContent(item?.noticeDescription)
        setNoticeDescription(item.noticeDescription)
        setNoticeTypeText(item.noticeTypeText)
        setImportance(item.importance)
        setNoticeStatus(item.currentStatus)
        setStartDate(convertISSet(item?.effectiveStartDt))
        setEndDate(convertISSet(item?.effectiveEndDt))
        setNoticeId(item.noticeId)
        const approverComment = item?.approverComment.split("\r\n")
        console.log("approverComment", approverComment)
        setApproverComment(approverComment)
        setApproverCommentInput("")
    }

    const BroadCastModal = (item) => {
        setShow(true);
        console.log("itemApproveModal", item)
        setNoticeSubject(item.noticeSubject)
        setContent(item?.noticeDescription)
        setNoticeDescription(item.noticeDescription)
        setNoticeTypeText(item.noticeTypeText)
        setImportance(item.importance)
        setNoticeStatus(item.currentStatus)
        setStartDate(convertISSet(item.effectiveStartDt))
        setEndDate(convertISSet(item.effectiveEndDt))
        setNoticeId(item.noticeId)
    }

    const ApproveAPi = async(e) => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        const endPointVariableKey = {
            urlVariable:null,
            buildingId: myUserData.buildingId
        }
        setApproveShow(true);
        console.log("e value", e)
        const bodydata = { 
            "NoticeId": NoticeId,
            "CurrentStatus": e,
            "ApproverComment": ApproverCommentInput,
            "ApproverUserName": myUserData.userName,
            "modifiedUserId": myUserData.userName, 
            // "modifiedUserId": "RadiusAdmin", // Need to be replaced
          };
        try {
            setLoading(true);
            if(!ApproverCommentInput){
                toast.warning("Please enter Comment"); 
            }else{
                console.log("body", bodydata);
                let response = await commonAPI("ApproveNotice", bodydata, endPointVariableKey)
                //   const response = await fetch(`http://api-test.curonext.com/api/Master/Building/`, {
                //     // let response = await fetch(`${apiURL}/DAR/DARReportToHOD`, {  
                //       method: "DELETE",
                //       headers: { 'Content-Type': 'application/json'},
                //       body: bodydata
                    
                //   })
                //   const statusCode = response
                console.log("ApproveNotice", response)
                if(response?.success === true & response?.message.length == 0){
                    // toast.success(`Notice ${e}`);  
                    // setApproveShow(false);
                    // setLoading(false);
                    // GetNoticeList();
                

                if(e == "PENDING"){
                    setLoading(false);
                    const endPointVariableKey = {
                        urlVariable:null,
                        buildingId: myUserData.buildingId
                    }
                    let responsePending = await commonAPI("Notice", bodydata, endPointVariableKey)
                    const tempData = responsePending?.data?.filter((item)=> item?.noticeId == NoticeId)
                    console.log("tempData", tempData)
                    const approverComment = tempData[0]?.approverComment?.split("\r\n")
                    console.log("approverComment", approverComment)
                    setApproverComment(approverComment)
                    setApproverCommentInput("")
                    toast.success(`Comment Added`);
                    setLoading(false);
                    GetNoticeList();
                    // toast.success(`Vehicle ${e}`);  
                }else if(e == "APPROVE") {
                    setLoading(false);
                    const endPointVariableKey = {
                        urlVariable:null,
                        buildingId: myUserData.buildingId
                    }
                    let responsePending = await commonAPI("Notice", bodydata, endPointVariableKey)
                    const tempData = responsePending?.data?.filter((item)=> item?.noticeId == NoticeId)
                    console.log("tempData", tempData)
                    const approverComment = tempData[0]?.approverComment?.split("\r\n")
                    console.log("approverComment", approverComment)
                    setApproverComment(approverComment)
                    setApproverCommentInput("")
                    toast.success(`Notice ${e}`); 
                    setApproveShow(false);
                    setLoading(false);
                    GetNoticeList();
                }else{
                    setLoading(false);
                    const endPointVariableKey = {
                        urlVariable:null,
                        buildingId: myUserData.buildingId
                    }
                    let responsePending = await commonAPI("Notice", bodydata, endPointVariableKey)
                    const tempData = responsePending?.data?.filter((item)=> item?.noticeId == NoticeId)
                    console.log("tempData", tempData)
                    const approverComment = tempData[0]?.approverComment?.split("\r\n")
                    console.log("approverComment", approverComment)
                    setApproverComment(approverComment)
                    setApproverCommentInput("")
                    toast.success(`Notice ${e}`);  
                    setApproveShow(false);
                    setLoading(false);
                    GetNoticeList();
                }
                }else{
                    toast.error(response.message[0]);
                    setLoading(false);
                }
            }    
        } catch (error) {
            setLoading(false);
            const bodyError = JSON.stringify({ 
                "userId": UserData.userId,
                "mobileNumber": UserData.mobileNumber, // Need to be change
                "requestJSON": bodydata,
                "uri": "/api/Master/Notice",
                "errorNumber": "DN01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "DeleteNotice",
            });
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response.success === true && response.message.length == 0){
                toast.error("Something went wrong");
                setLoading(false); 
            }else{
                toast.error("Something went wrong");
                setLoading(false); 
            }
            
        }
        
    }
  
    const handleNext = () => {
       
        setActiveStep((prevActiveStep) => {
            if (prevActiveStep >= steps.length - 1) { // Assuming 'steps' is your array of step labels
                setFirstSectionButton(false)
                console.log(prevActiveStep)
                return 0; // Reset to first step
            } else {
                // console.log(inpRef.current.value)
                setFirstSectionButton(true)
                console.log(prevActiveStep)
                 return prevActiveStep + 1;
                
            }
        });
    };
  
    const handleBack = () => {
        
        setFirstSectionButton(false)
      setActiveStep((prevActiveStep) => {
        if(prevActiveStep==1){
            setFirstSectionButton(false)
            console.log(prevActiveStep)
            return prevActiveStep - 1
        }
        else{
            setFirstSectionButton(true)
            return prevActiveStep - 1
        }
       
    });
    };

    



    const [file, setFile] = useState();
    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    // console.log("img data",AltSPOCEmail)


    // const getStepContent = (stepIndex) => {
    //     console.log(stepIndex)
    //     const handleFileInputChange = (event) => {
    //         const file = event.target.files[0];
    //         if (file) {
    //           const reader = new FileReader();
    //           reader.onload = () => {
    //             setSelectedImage(reader.result);
    //           };
    //           reader.readAsDataURL(file);
    //         }
    //       };
    //       const handleFileInputChangeTwo = (event) => {
    //         const file = event.target.files[0];
    //         if (file) {
    //           const reader = new FileReader();
    //           reader.onload = () => {
    //             setSelectedImageTwo(reader.result);
    //           };
    //           reader.readAsDataURL(file);
    //         }
    //       };
    //   switch (stepIndex) {
    //     case 0:
    //         return <div><section id="steps-uid-0-p-0" role="tabpanel" aria-labelledby="steps-uid-0-h-0" className="body current" aria-hidden="false">
    //         <div className="row mt-5">
            
    //             <div className="col-md-6">
                
    //                 <div className="form-group">
    //                     <label for="wfirstName2">  Notice Subject : <span className="danger">*</span> </label>
    //                     <input type="text" value={NoticeSubject} onChange={(e) => {setNoticeSubject(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
    //                      </div>
    //                     <br/>
    //                      <div className="form-group">
    //                   <label for="jobTitle2">Notice Type :</label>

    //                 <br/>

    //                 <Form.Select size="md" className="sltareamodal" onChange={(e) => {
    //                     console.log("selection", e.target.value)
    //                     setNoticeTypeId(e.target.value);
    //                 }
    //                     }>
    //                     <option value="Select">Select</option>
    //                     {NoticeType ? NoticeType.map((item, index) =>  <option key={index} value={item.noticeTypeId}>{item.noticeTypeText}</option>): null}
    //                 </Form.Select>
    //                 {/* <Form.Select size="md" className="sltareamodal" >
    //                     <option>General</option>
    //                     <option>Maintainance</option>
    //                     <option>Security</option>
    //                     <option>Renovation</option>
    //                     <option>Health & Security</option>
    //                     <option>Alert</option>
    //                     <option>Warning</option>
    //                     <option>Information</option>
    //                     <option>Emergency</option>
    //                     <option>Meeting</option>
    //                     <option>Invitation</option>
    //                 </Form.Select> */}





    //                   {/* <input type="text" value={AltEmail} onChange={(e) => setAltEmail(e.target.value)} className="form-control required" id="jobTitle2" aria-required="true" /> */}
    //               </div>
    //               {
    //                 !firstSectionButton && 
    //                 <div className='btn_trigger'>
    //                     <button value="back" className="back_btn btn btn-primary" disabled={activeStep === 0} onClick={handleBack}> Back </button>
    //                     <button value="next" className="back_btn btn btn-primary" onClick={handleNext}> Next </button>
    //                 </div>
    //             }
    //             </div>
    //             <div className="col-md-6">
    //                 <div className="form-group">
    //                     <label for="wlastName2"> Notice Description : <span className="danger">*</span> </label>
    //                 <Textarea cols={37} rows={8} value={NoticeDescription} onChange={(e) => {setNoticeDescription(e.target.value);}} className='txtareamodal'> </Textarea>
    //                     {/* <input type="text" value={ShortName} className="form-control required" onChange={(e) => {setShortName(e.target.value);}} id="wlastName2" name="lastName" aria-required="true" />*/} </div> 
    //             </div>
    //             {/* <div className="col-md-4">
    //                 <div className="form-group">
    //                     <label for="wlastName2"> Website : <span className="danger">*</span> </label>
    //                     <input type="text" value={Website} onChange={(e) => setWebsite(e.target.value)} className="form-control required" id="wlastName2" name="lastName" aria-required="true" /> </div>
    //             </div> */}
    //         </div>
    //         {/* <div className="row">
    //             <div className="col-md-4">
    //                 <div className="form-group">
    //                     <label for="wemailAddress2"> Phone Number : <span className="danger">*</span> </label>
    //                     <input type="email" value={PhoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="form-control required" id="wemailAddress2" name="emailAddress" aria-required="true" /> </div>
    //             </div>
    //             <div className="col-md-4">
    //                 <div className="form-group">
    //                     <label for="wphoneNumber2">Alt Phone Number :</label>
    //                     <input type="tel" value={AltPhoneNumber} onChange={(e) => setAltPhoneNumber(e.target.value)} className="form-control" id="wphoneNumber2" /> </div>
    //             </div>
    //             <div className="col-md-4">
    //                 <div className="form-group">
    //                     <label for="wlastName2"> Email : <span className="danger">*</span> </label>
    //                     <input type="text" value={Email} onChange={(e) => setEmail(e.target.value)} className="form-control required" id="wlastName2" name="lastName" aria-required="true" /> </div>
    //             </div>
    //         </div> */}
            
    //         </section></div>
    //         setFirstSectionButton(false)
    //     case 1:
    //       return <div><section id="steps-uid-0-p-1" role="tabpanel" aria-labelledby="steps-uid-0-h-1" className="body" aria-hidden="true">
    //       <div className="row mt-5">
 

    //           <div className="col-md-6">
    //                   <div className="form-group">
    //                       <label for="jobTitle2">Image 1:</label>
    //                       <input type="file" onChange={handleFileInputChange} className="form-control required" id="jobTitle2" aria-required="true" />
    //                         <div className='img_uploaded'>
    //                             {selectedImage && <img src={selectedImage} alt="Selected Image" />}
    //                         </div>
    //                   </div>
    //           </div>
    //               <div className="col-md-6">
    //                   <div className="form-group">
    //                       <label for="webUrl3">Image 2 :</label>
    //                       <input type="file" value={selectedImageTwo} onChange={handleFileInputChangeTwo} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
    //                       <div className='img_uploaded'>
    //                          {selectedImageTwo && <img src={selectedImageTwo} alt="Selected Image" />}
    //                     </div>
    //                       </div>
    //                   </div>
    //           {/* <div className="col-md-4">
    //               <div className="form-group">
    //                   <label for="webUrl3">SPOC Mobile Number :</label>
    //                   <input type="url" value={SpocMobileNumber} onChange={(e) => setSpocMobileNumber(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //           </div> */}
    //           </div>
    //           {/* <div className="row">
    //           <div className="col-md-6">
    //               <div className="form-group">
    //                   <label for="jobTitle2">Image 1:</label>
    //                   <input type="text" value={SpocEmail} onChange={(e) => setSpocEmail(e.target.value)} className="form-control required" id="jobTitle2" aria-required="true" />
    //               </div>
    //           </div>
    //           <div className="col-md-6">
    //               <div className="form-group">
    //                   <label for="webUrl3">Image 2 :</label>
    //                   <input type="url" value={AltSPOCName} onChange={(e) => setAltSPOCName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //           </div>
    //           <div className="col-md-4">
    //               <div className="form-group">
    //                   <label for="webUrl3">Alt SPOC Mobile Number:</label>
    //                   <input type="url" value={AltSPOCMobileNumber} onChange={(e) => setAltSPOCMobileNumber(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //           </div>
    //           </div>  */}
    //       </section></div>;
    //     case 2:
    //       return <div>  
    //       <section id="steps-uid-0-p-2" role="tabpanel" aria-labelledby="steps-uid-0-h-2" className="body" aria-hidden="true">
    //           <div className="row mt-5">

    //           <div className="col-md-6">
    //               <div className="form-group">
    //                   <label for="webUrl3">Notify On Email :</label>
    //                   <Form.Select size="md" className="sltareamodal" value={NoticeEmail} onChange={(e) => {setNoticeEmail(e.target.value);}}>
    //                     <option value="Select">Select</option>
    //                     <option value={"Yes"}> Yes </option>
    //                     <option value={"No"}> No</option> 
 
    //                 </Form.Select>
    //                    </div>
    //           </div>
 
    //           <div className="col-md-6">
    //                     <div className="form-group">
    //                         <label for="jobTitle2">Importance:</label>

    //                         <br/>


    //                     <Form.Select size="md" className="sltareamodal" value={Importance} onChange={(e) => {setImportance(e.target.value);}}>
    //                         <option value="Select">Select</option>
    //                         <option value={"High"}> High </option>
    //                         <option value={"Medium"}> Medium</option>
    //                         <option value={"Low"}> Low</option>
                    
    //                     </Form.Select>
    //                         {/* <input type="text" value={PinCode} onChange={(e) => setPinCode(e.target.value)} className="form-control required" id="jobTitle2" aria-required="true" /> */}
    //                     </div>
    //                 </div>
    //                 </div>
    //                 <br/>
    //                 <div className='row'>
                  
    //                                     <div className="col-md-6">
    //                     <div className="form-group">
    //                         <label for="webUrl3">Effective Start Date :</label>
    //                         <input type="date" value={StartDate} onChange={(e) => setStartDate(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //                 </div>
 
    //                 <div className="col-md-6">
    //                     <div className="form-group">
    //                         <label for="webUrl3"> Effective End Date:</label>
    //                         <input type="date" value={EndDate} onChange={(e) => setEndDate(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //                     </div>

    //               {/* <div className="col-md-4">
    //                   <div className="form-group">
    //                   <label for="webUrl3">Address Line 2:</label>
    //                   <input type="url" value={AddressLine2} onChange={(e) => setAddressLine2(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //                   </div> */}
    //               </div> 
      
    //               {/* <div className="row">
    //                   <div className="col-md-4">
    //                       <div className="form-group">
    //                           <label for="jobTitle2">City:</label>
    //                           <input type="text" value={City} onChange={(e) => setCity(e.target.value)} className="form-control required" id="jobTitle2" aria-required="true" />
    //                       </div>
    //                   </div>
    //               <div className="col-md-4">
    //                   <div className="form-group">
    //                       <label for="webUrl3">State:</label>
    //                       <input type="url" value={State} onChange={(e) => setState(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //                   </div>
    //               <div className="col-md-4">
    //                   <div className="form-group">
    //                       <label for="webUrl3">Country:</label>
    //                       <input type="url" value={Country} onChange={(e) => setCountry(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //                   </div>
    //               </div>  */}
                  
    //       </section>
          
    //       </div>;
    //     default:
    //       return 'Unknown step';
    //   }
    // };  
  
    

     return (
    <>
    {Loading? 
        <div className="loaderDiv">
            <div className="loader"></div>
        </div> 
    :null}
    <ToastContainer />
    {/* <Menus /> */}
    <section id="client-builder">
   <div className="container-fluid pt-2">
   <div className="row mt-5 pt-2" style={{alignItem:'center'}}>
            <div className="col-lg-6 col-md-6 px-0">
                <div className="HeadingTop">
                    <h3> Notice Approval </h3>
                    <span> List of Curo Solution Notice Approval</span>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 px-0  text-right" style={{alignContent:'center'}}>
              <select size="md" className="sltareamodal col-4 ml-auto bg-white"  onChange={(e) => {
                            console.log("selection", e.target.value)
                            setCurrentStatusSelected(e.target.value);
                        }
                            }>
                <option value="PENDING">Pending</option>
                <option value="APPROVED">Approved</option>
                <option value="REJECTED">Rejected</option>
              </select>
            </div>
        </div>
      
      {/*  BroadCast Modal */}
      <Modal fullscreen={fullscreen}  show={Show} onHide={() =>
         setShow(false)} className="modal-100w  modal-fullscreen" aria-labelledby="example-custom-modal-styling-title">
         <Modal.Header className="headerMDL" closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
               Approve Notice
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
         <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="row">
                                    <div class="col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                                        <div className="form-group">
                                            <label for="wfirstName2" style={{fontSize: "small"}}>
                                            {" "}
                                            Notice Type {" "}
                                            {/* <span className="danger">*</span>{" "} */}
                                            </label>
                                            <input type="text" className="form-control required" disabled value={NoticeTypeText} onChange={(e) => {setNoticeTypeText(e.target.value);}}/>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                                        <div className="form-group">
                                            <label for="wfirstName2" style={{fontSize: "small"}}>
                                            {" "}
                                            Importance {" "}
                                            {/* <span className="danger">*</span>{" "} */}
                                            </label>
                                            <input type="text" className="form-control required" disabled value={Importance} onChange={(e) => {setImportance(e.target.value);}}/>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                                        <div className="form-group">
                                            <label for="wfirstName2" style={{fontSize: "small"}}>
                                            {" "}
                                            Effective Start Date {" "}
                                            {/* <span className="danger">*</span>{" "} */}
                                            </label>
                                            <input type="date" className="form-control required" disabled value={StartDate} onChange={(e) => {setStartDate(e.target.value);}}/>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                                        <div className="form-group">
                                            <label for="wfirstName2" style={{fontSize: "small"}}>
                                            {" "}
                                            Effective End Date {" "}
                                            {/* <span className="danger">*</span>{" "} */}
                                            </label>
                                            <input type="date" className="form-control required" disabled value={EndDate} onChange={(e) => {setEndDate(e.target.value);}}/>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label for="wfirstName2" style={{fontSize: "small"}}>
                                            {" "}
                                            Notice Subject {" "}
                                            {/* <span className="danger">*</span>{" "} */}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control required"
                                                id="wfirstName2"
                                                name="firstName"
                                                aria-required="true"
                                                value={NoticeSubject} disabled onChange={(e) => {setNoticeSubject(e.target.value);}}/>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                                        <div className="form-group">
                                            <label for="wfirstName2" style={{fontSize: "small"}}>
                                            {" "}
                                            Notice Description {" "}
                                            {/* <span className="danger">*</span>{" "} */}
                                            </label>
                                            <JoditEditor ref={editor} value={content} tabIndex={1} onBlur={newContent =>
                                                    setContent(newContent)}/>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12 col-12 px-0">
                                <div className="uploadedfilesection" style={{border: "1px solid #dadada"}}>
                                    <span className='modalImageheader'>Images</span>
                                <ul style={{marginTop: "60px"}}>
                                {NoticeImages ? NoticeImages?.map((item, index) =>
                                    <li key={index} >
                                        {/* <div className='RelativeDel'>
                                            <FontAwesomeIcon icon={faTimes} onClick={() => ShowDeleteImgModal(item)} className="deleteupload" />
                                        </div> */}
                                        <img src={atob(item?.docImage)} className="img img-responsive" />
                                        
                                    </li>
                                ):null}    
                                </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 px-0">
                                <div className="form-group bg-white" >
                                    <div className='' style={{border: "1px solid #dadada", padding: "10px"}}>
                                        <span style={{fontSize: "12px"}}> BroadCast Message</span>
                                    </div>

                                    <div className="uploadedfilesection" style={{height: "439px"}}>
                                    <div className="card-body row">
                                        <div className="col-md-12 mt-3">
                                            <label for="wfirstName2">  Tower  <span className="danger">*</span> </label>
                                            <Select
                                                // defaultValue={TowerSelected}
                                                isMulti
                                                options={TowerList}
                                                value={TowerSelected}
                                                // onChange={(event) =>(setTowerSelected(event))}
                                                onChange={handleTowerChange}

                                                // selectedValues={TowerSelected} // Preselected value to persist in dropdown
                                                // onSelect={(event) =>(setTowerSelected(event))} // Function will trigger on select event
                                                // onRemove={(event) =>(setTowerSelected(event))} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                                
                                            />
                                            {/* <Multiselect
                                                options={checkBox.options} // Options to display in the dropdown
                                                showCheckbox={true}
                                                selectedValues={TowerSelected} // Preselected value to persist in dropdown
                                                onSelect={(event) =>(setTowerSelected(event))} // Function will trigger on select event
                                                onRemove={(event) =>(setTowerSelected(event))} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                            />  */}
                                            {/* flat list shown here */}
                                        </div>    
                                        <div className="col-md-12 mt-3">
                                            <label for="wfirstName2">  Flat  <span className="danger">*</span> </label>
                                            <Select
                                                isMulti
                                                options={FlatList? FlatList:[]}
                                                value={FlatSelected}
                                                // onChange={(event) =>(setFlatSelected(event))}
                                                onChange={handleFlatChange}
                                                // selectedValues={FlatSelected} // Preselected value to persist in dropdown
                                                // onSelect={(event) =>(FlatSelected(event))} // Function will trigger on select event
                                                // onRemove={(event) =>(setFlatSelected(event))} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                                
                                            />
                                            {/* <Multiselect
                                                options={FlatcheckBox.options} // Options to display in the dropdown
                                                selectedValues={FlatSelected} // Preselected value to persist in dropdown
                                                onSelect={(event) =>(setFlatSelected(event))} // Function will trigger on select event
                                                onRemove={(event) =>(setFlatSelected(event))} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                            />   */}
                                        </div>    
                                        {/* <div className="col-md-4 mt-3">
                                            <label for="wfirstName2">  Family : <span className="danger">*</span> </label>
                                            <div id="list3" className="dropdown-check-list2" tabindex="100">
                                                <span className="anchor">Select Family</span>
                                                <ul className="items">
                                                    {TowerList ? TowerList.map((item, index) =>  <li key={index}><input type="checkbox" value={item.towerId} style={{position: "relative", opacity: 1, marginRight: 10}} />{item.towerName} </li>): null}
                                                </ul>
                                            </div>
                                        </div>     */}


                        
                        

                         

                                    </div>
                                    <div className='row mb-5 mt-3'>
                                        <div className='col-md-6 text-center'>
                                            <button value={"APPROVED"} onClick={() => (BroadCast())} className='col-9 btn btn-info'>BroadCast</button>
                                        </div>    
                                        {/* <div className='col-md-6 text-center'>
                                            <button value={"REJECTED"} onClick={(e) => (ApproveAPi(e.target.value))} className='col-5 btn btn-danger'>Reject</button>
                                        </div>     */}
                                    </div>                
                                    </div>
                                </div>
                            </div>
                           
                        </div>
         </Modal.Body>
      </Modal>

      {/*  Approve/Reject Modal */}
      <Modal fullscreen={fullscreen}  show={ApproveShow} onHide={() =>
         setApproveShow(false)} className="modal-100w  modal-fullscreen" aria-labelledby="example-custom-modal-styling-title">
         <Modal.Header className="headerMDL" closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
               Approve Notice
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className="row" id="validation">
                <div className="col-12">
                    <form
                    action="#"
                    className="validation-wizard wizard-circle wizard clearfix"
                    role="application"
                    id="steps-uid-0"
                    novalidate="novalidate"
                    >
                        <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="row">
                                <div class="col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                                    <div className="form-group">
                                        <label for="wfirstName2">
                                        {" "}
                                        Notice Type {" "}
                                        {/* <span className="danger">*</span>{" "} */}
                                        </label>
                                        <input type="text" className="form-control required" disabled value={NoticeTypeText} onChange={(e) => {setNoticeTypeText(e.target.value);}}/>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                                    <div className="form-group">
                                        <label for="wfirstName2">
                                        {" "}
                                        Importance {" "}
                                        {/* <span className="danger">*</span>{" "} */}
                                        </label>
                                        <input type="text" className="form-control required" disabled value={Importance} onChange={(e) => {setImportance(e.target.value);}}/>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                                    <div className="form-group">
                                        <label for="wfirstName2">
                                        {" "}
                                        Effective Start Date {" "}
                                        {/* <span className="danger">*</span>{" "} */}
                                        </label>
                                        <input type="date" className="form-control required" disabled value={StartDate} onChange={(e) => {setStartDate(e.target.value);}}/>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                                    <div className="form-group">
                                        <label for="wfirstName2">
                                        {" "}
                                        Effective End Date {" "}
                                        {/* <span className="danger">*</span>{" "} */}
                                        </label>
                                        <input type="date" className="form-control required" disabled value={EndDate} onChange={(e) => {setEndDate(e.target.value);}}/>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="form-group">
                                        <label for="wfirstName2">
                                        {" "}
                                        Notice Subject {" "}
                                        {/* <span className="danger">*</span>{" "} */}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control required"
                                            id="wfirstName2"
                                            name="firstName"
                                            aria-required="true"
                                            value={NoticeSubject} disabled onChange={(e) => {setNoticeSubject(e.target.value);}}/>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                                    <div className="form-group">
                                        <label for="wfirstName2">
                                        {" "}
                                        Notice Description {" "}
                                        {/* <span className="danger">*</span>{" "} */}
                                        </label>
                                        <JoditEditor ref={editor} value={content} tabIndex={1} onBlur={newContent =>
                                                setContent(newContent)}/>
                                    </div>
                                </div>  
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12 col-12 px-0">
                                <div className="uploadedfilesection" style={{border: "1px solid #dadada"}}>
                                    <span className='modalImageheader'>Images</span>
                                <ul style={{marginTop: "60px"}}>
                                {NoticeImages ? NoticeImages?.map((item, index) =>
                                    <li key={index} >
                                        {/* <div className='RelativeDel'>
                                            <FontAwesomeIcon icon={faTimes} onClick={() => ShowDeleteImgModal(item)} className="deleteupload" />
                                        </div> */}
                                        <img src={atob(item?.docImage)} className="img img-responsive" />
                                        
                                    </li>
                                ):null}    
                                </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 px-0">
                                <div className="form-group bg-white" >
                                    <div className='' style={{border: "1px solid #dadada", padding: "10px"}}>
                                        <span style={{fontSize: "12px"}}>Comments</span>
                                    </div>

                                        <div className="uploadedfilesection" style={{height: "400px"}}>
                                        {ApproverComment?.map((item,index) =>
                                        <h6 className='mt-3' style={{wordWrap: "break-word"}}>{item}</h6>
                                        )}
                                        </div>
                                    

                                        <div className='Notice_modal_comment'>
                                            <div className="comment_Sec">
                                                <input
                                                    type="text"
                                                    className="form-control required border-0"
                                                    id="wfirstName2"
                                                    name="firstName"
                                                    aria-required="true"
                                                    placeholder='Add comment here'
                                                    value={ApproverCommentInput}
                                                    onChange={(e) => setApproverCommentInput(e.target.value)}
                                                    />
                                            </div>
                                            <div className='comment_btns'  >
                                            {/* <Button className='btn btn-default p-0'> */}
                                                    {/* <FontAwesomeIcon className="editNotice" icon={faMessage} style={{fontSize:"20px"}}/> */}
                                                {/* </Button> */}
                                                {/* <img src={chat} alt="homepage" className="dark-logo" value={"PENDING"} onClick={(e) => (ApproveAPi(e.target.value))} style={{width: "19%", paddingRight: 20}}/>  */}
                                                <img src={chat} alt="homepage" className="dark-logo" style={{width: "19%", paddingRight: 20}}/>
                                                <Button value={"PENDING"} onClick={(e) => (ApproveAPi(e.target.value))}  className=" border-0 m-0" id="webUrl3" name="webUrl3" aria-required="true" style={{backgroundColor: "transparent", position: "absolute", padding: "20px", boxShadow: "none", left: "265px"}}>
                                                        {" "}
                                                        
                                                </Button>
                                            
                                            <div className="cmt-btn">
                                                
                                                <Button value={"APPROVED"} onClick={(e) => (ApproveAPi(e.target.value))}  className="bg-success form-control notice-comment m-0" id="webUrl3" name="webUrl3" aria-required="true" >
                                                        {" "}
                                                        Approve
                                                    </Button>
                                                    <Button value={"REJECTED"} onClick={(e) => (ApproveAPi(e.target.value))}  className="bg-danger form-control notice-comment m-0" id="webUrl3" name="webUrl3" aria-required="true" >
                                                        {" "}
                                                        Reject
                                                    </Button>
                                            </div>
                                    </div>
    </div>




                                    












                                </div>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
         </Modal.Body>
      </Modal>
      <div className="row">
         {/* <div className="col-12">
            <div className="card">
               <div className="card-body">
                  <h4 className="card-title">Notice Managment</h4>
                  <div className="table-responsive m-t-20">
                     <div id="example23_wrapper" className="dataTables_wrapper">
                        <table id="example23" className="display nowrap table table-hover table-striped table-bordered dataTable" cellSpacing="0"  role="grid" aria-describedby="example23_info" style={{width: '100%'}}>
                            <thead>
                            <tr role="row">
                                <th className="sorting_asc" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Position: activate to sort column descending" style={{width: '210px'}} aria-sort="ascending">Notice Subject 
                                <FontAwesomeIcon icon={faSort} className='sortclr' />
                                </th>
                                <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Office: activate to sort column ascending" style={{width: '200px'}}>Notice Type 
                                <FontAwesomeIcon icon={faSort} className='sortclr' />
                                </th>
                                <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{width: '180px'}}> Notice Description 
                                <FontAwesomeIcon icon={faSort} className='sortclr' />
                                </th>
                                <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Start date: activate to sort column ascending" style={{width: '180px'}}>Importance 
                                <FontAwesomeIcon icon={faSort} className='sortclr' />
                                </th>
                                <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{width: '180px'}}> Status 
                                <FontAwesomeIcon icon={faSort} className='sortclr' />
                                </th>
                                {CurrentStatusSelected?.toUpperCase() == "REJECTED" ? null:
                                    <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Salary: activate to sort column ascending" style={{width: '129px'}}>Take Action</th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {NoticeList ? NoticeList.map((item, index) =>  
                            <tr role="row" key={index} className="odd">
                                <td>{item?.noticeSubject}</td>
                                <td>{item?.noticeTypeText}</td>
                                <td><h5 style={{width: 150, overflow: "hidden", textOverflow: "ellipsis", marginTop: 3}}>{item?.noticeDescription}</h5></td>
                                <td>{item?.importance}</td>
                                {item?.currentStatus?.toUpperCase() == "PENDING" ? 
                                <td>Pending</td>
                                :null
                                }
                                {item?.currentStatus?.toUpperCase() == "APPROVED" ? 
                                <td>Approved</td>
                                :null
                                }
                                {item?.currentStatus?.toUpperCase() == "REJECTED" ? 
                                <td>Rejected</td>
                                :null
                                }
                                {CurrentStatusSelected?.toUpperCase() == "REJECTED" ? null:
                                    <td >
                                        {item?.currentStatus?.toUpperCase() == "PENDING" ? 
                                            <button onClick={() => ApproveModal(item)} className='btn btn-info'>Approve</button>
                                            :
                                            null
                                        }
                                        {item?.currentStatus?.toUpperCase() == "APPROVED" ? 
                                            <button onClick={() => BroadCastModal(item)}  className='btn btn-info ml-2'>Broadcast</button>
                                            :null
                                        }
                                    </td>
                                }
                                
                            </tr>
                            ): null}  
                            </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div> */}
      </div>
      <div className='row'>
        {NoticeList ? NoticeList.map((item, index) =>  
        <div key={index} className="OneTile">
            <div className="ex_feild">
                {item?.importance.toUpperCase() == "MEDIUM" ?
                    <span className="imp_med"> </span>
                :null}

                {item?.importance.toUpperCase() == "HIGH" ?
                    <span className="imp_high"> </span>
                :null}

                {item?.importance.toUpperCase() == "LOW" ?
                    <span className="imp_Low"> </span>
                :null}
            </div>
            <div className="NoticeFeild">
                <h1> {item.noticeSubject}</h1>
                <h2> {item.noticeTypeText} </h2>

                <div className="onboardFlex">
                    {/* <h4> Current Status </h4> */}
                    <span> {item.currentStatus} </span>
                </div>
                <br/>
                <div className="onboardFlex">
                    <h4> Expiring On   <span> {convertISTTime(item.effectiveEndDt)} </span></h4>
                   
   

                </div>

                {item?.currentStatus?.toUpperCase() == "PENDING" ?     
                    <div className="onboardFont">    
                        <img src={Action} onClick={() => ApproveModal(item)} alt="homepage" className="dark-logo"/> 
                    </div>
                    :null
                } 
                {item?.currentStatus?.toUpperCase() == "APPROVED" ? 
                    <div className="onboardFont">
                        <img src={BroadCastImg} onClick={() => BroadCastModal(item)} alt="homepage" className="dark-logo"/> 
                    </div>
                    :null
                }
            </div>
        </div>
        ): null}  
    </div>
   </div>
</section> 
        </>)

} 
  export default NoticeApproval 