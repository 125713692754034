import React , {useEffect, useState} from 'react';
import Client from "./client";
import Building from "./building";
import Flat from "./flat";
// import Tower from "./tower";
import Menus from "./menus";
import $ from 'jquery';
// import Tower from "./tower";
// import Building from "./building";
import Gate from "./Gate";
import Noticemanagement from "./Noticemanagement";
 
 

const Body = ()=>{
 
    const [RouteParam, setRouteParam] = useState("Noticemanagement");

    $(function () {
        
        $(function () {
            $(".preloader").fadeOut();
        });
        $(document).on('click', '.mega-dropdown', function (e) {
            e.stopPropagation()
        });
        
        var set = function () {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            var topOffset = 0;
            if (width < 5170) {
                $("body").addClass("mini-sidebar");
                $(".sidebartoggler i").addClass("ti-menu");
            } else {
                $("body").removeClass("mini-sidebar");
            }
            // if (width < 767) {
            //     $('.scroll-sidebar').perfectScrollbar();
            //     $('.navbar-brand span').hide();
            // } else {
            //     $('.scroll-sidebar').perfectScrollbar('destroy').removeClass('ps');
            //     $('.navbar-brand span').show();
            // }
            var height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
            height = height - topOffset;
            if (height < 1) height = 1;
            if (height > topOffset) {
                $(".page-wrapper").css("min-height", (height) + "px");
            }

        };
        $(window).ready(set);
        $(window).on("resize", set);

        // ============================================================== 
        // Theme options
        // ==============================================================     
        $(".sidebartoggler").on('click', function () {
            if ($("body").hasClass("mini-sidebar")) {
                $("body").trigger("resize");
                $("body").removeClass("mini-sidebar");
                $('.navbar-brand span').show();

            } else {
                $("body").trigger("resize");
                $("body").addClass("mini-sidebar");
                $('.navbar-brand span').hide();

            }
        });

        // this is for close icon when navigation open in mobile view
        $(".nav-toggler").click(function () {
            $("body").toggleClass("show-sidebar");
            $(".nav-toggler i").toggleClass("ti-menu");
            $(".nav-toggler i").addClass("ti-close");
        });

        $(".search-box a, .search-box .app-search .srh-btn").on('click', function () {
            $(".app-search").toggle(200);
        });
        // ============================================================== 
        // Right sidebar options
        // ============================================================== 
        $(".right-side-toggle").click(function () {
            $(".right-sidebar").slideDown(50);
            $(".right-sidebar").toggleClass("shw-rside");
        });
        // ============================================================== 
        // This is for the floating labels
        // ============================================================== 
        $('.floating-labels .form-control').on('focus blur', function (e) {
            $(this).parents('.form-group').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
        }).trigger('blur');

        // ============================================================== 
        // Auto select left navbar alert("Something went wrong catch");
        // ============================================================== 
        $(function () {
            var url = window.location;
            var element = $('ul#sidebarnav a').filter(function () {
                return this.href === url;
            }).addClass('active').parent().addClass('active');
            while (true) {
                if (element.is('li')) {
                    element = element.parent().addClass('in').parent().addClass('active');
                } else {
                    break;
                }
            }

        });
        // ============================================================== 
        //tooltip
        // ============================================================== 
        // $(function () {
        //     $('[data-toggle="tooltip"]').tooltip()
        // })
        // ============================================================== 
        //Popover
        // ============================================================== 
        // $(function () {
        //     $('[data-toggle="popover"]').popover()
        // })
        // ============================================================== 
        // Sidebarmenu
        // ============================================================== 
        // $(function () {
        //     $('#sidebarnav').AdminMenu();
        // });
        
        
        $(window).scroll(function () {
            if ($(window).scrollTop() >= 200) {
                $('body').addClass('fix-sidebar');
                $('.left-sidebar').addClass('animated slideInDown');
            } else {
                $('body').removeClass('fix-sidebar');
                $('.left-sidebar').removeClass('animated slideInDown');
            }
        });
        
        // ============================================================== 
        // Perfact scrollbar
        // ============================================================== 
        // $('.right-side-panel, .message-center, .right-sidebar').perfectScrollbar();

        // ============================================================== 
        // Resize all elements
        // ============================================================== 
        $("body").trigger("resize");
        // ============================================================== 
        // To do list
        // ============================================================== 
        $(".list-task li label").click(function () {
            $(this).toggleClass("task-done");
        });



        // ============================================================== 
        // Collapsable cards
        // ==============================================================
        $('a[data-action="collapse"]').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.card').find('[data-action="collapse"] i').toggleClass('ti-minus ti-plus');
            $(this).closest('.card').children('.card-body').collapse('toggle');

        });
        // Toggle fullscreen
        $('a[data-action="expand"]').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.card').find('[data-action="expand"] i').toggleClass('mdi-arrow-expand mdi-arrow-compress');
            $(this).closest('.card').toggleClass('card-fullscreen');
        });

        // Close Card
        $('a[data-action="close"]').on('click', function () {
            $(this).closest('.card').removeClass().slideUp('fast');
        });

    });




   return <>
        {/* <Menus/> */}
        <Client/>
        {/* < RouteParam /> */}
         {/* <Building/>  */}
         {/* <Flat /> */}
         {/* <Tower /> */}
         {/* <Noticemanagement /> */}
         {/* <Gate /> */}
    </>
} 
export default Body