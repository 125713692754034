import React , {useEffect, useRef, useState} from 'react'; 
import { useLocation, Link } from 'react-router-dom';
import '../assets/css/custom.css';  
import '../assets/css/font-awesome.css';
import '../assets/css/sweetalert.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/steps.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faChevronCircleRight, faEdit, faIdBadge, faPlus, faSearch, faSort } from '@fortawesome/free-solid-svg-icons';
import clientlogo from '../assets/img/user1.jpg'; 
import clientlogo2 from '../assets/img/user2.jpg'; 
import clientlogo3 from '../assets/img/user3.jpg'; 
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Menus from "./menus";
import ReactDOM from "react-dom";
// import { Wizard, Steps, Step, WithWizard } from "react-multistep-wizard";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { Stepper, Step, StepLabel } from '@mui/material';
import { Textarea } from '@material-tailwind/react';
import { Circle, FactCheck } from '@mui/icons-material';
import familyavatar from '../assets/img/family-avatar.png';
import familyavatar2 from '../assets/img/family-avatar-2.png';
import familyavatar3 from '../assets/img/family-avatar-3.png';
import familyavatar4 from '../assets/img/family-avatar-4.png';

import approvaltick from '../assets/img/approval.png';
import { faCheckCircle } from '@fortawesome/fontawesome-free-solid';
import Familydetails from './Familydetails';
import {commonAPI} from "../services/CommonAPIService"
import {ErrorSource} from '../common/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Resident()  { 
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Step 1', 'Step 2', 'Step 3'];
    const [show, setShow] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [ParentList, setParentList] = useState("");
    const [ParentUpdate, setParentUpdate] = useState("");
    const [UserName, setUserName] = useState("");
    const [FullName, setFullName] = useState("");
    const [MobileNumber, setMobileNumber] = useState("");
    const [EmailId, setEmailId] = useState("");
    const [ClientName, setClientName] = useState("");
    const [Role, setRole] = useState("");
    const [TowerList, setTowerList] = useState("");
    const [TowerIdSelect, setTowerIdSelect] = useState([]);
    const [FlatList, setFlatList] = useState("");
    const [FlatIdSelect, setFlatIdSelect] = useState([]);
    const [FlatFull, setFlatFull] = useState(false);
    const [SpocName, setSpocName] = useState();
    const [selectedImageTwo,setSelectedImageTwo] = useState(null)
    const [selectedImageThree,setSelectedImageThree] = useState(null)
    const [selectedImageFour,setSelectedImageFour] = useState(null)
    console.log("FlatFull", FlatFull)
   
    const openCreateModal = () => {
        if(!TowerIdSelect[0]){
            toast.warning("Please select Tower");
        }else if (!FlatIdSelect[0]){    
            toast.warning("Please select Flat");
        }else{
            if(FlatFull === true){
                toast.warning("Flat is already occupied");
            }else{
                setShow(true)
                setUserName("")
                setMobileNumber("")
                setEmailId("")
                
            }    
        }
    };

    const GetTowerList = async () => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        try {
            setLoading(true);
            const endPointVariableKey    = {
                urlVariable:null,
                buildingId: myUserData?.buildingId
            
            }
            setLoading(true);
            let response = await commonAPI("Tower", undefined, endPointVariableKey)
            if (response.success===true){
                setTowerList(response.data);
                setLoading(false);
            }else{
                toast.error("Something went wrong");
                setLoading(false);
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber,
                "requestJSON": null,
                "uri": "/api/Master/Tower/",
                "errorNumber": "GT01",
                "errorSource": "CuroNextPortal",
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Resident.js",
                "functionName": "GetTowerList",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }

    }

    const GetFlatList = async () => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        try {
            setLoading(true);
            const endPointVariableKey    = {
                urlVariable:null,
                buildingId: myUserData.buildingId
            
            }
            let response = await commonAPI("Flat", undefined, endPointVariableKey);
            if (response.success===true){
                const tempData = response.data.filter((item)=> item.towerId == TowerIdSelect[0])
                setFlatList(tempData);
                setLoading(false);
            }else{
                toast.error("Something went wrong");
                setLoading(false);
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber,
                "requestJSON": null,
                "uri": "/api/Master/Flat",
                "errorNumber": "GF01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Resident.js",
                "functionName": "getFlatList",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }

    } 

    const GetParentList = async () => {

        try {
            let response = await commonAPI("CuroNextUserFamily")
            setLoading(true); 
            
                   
                if (response?.success == true){
                    console.log("Parent Table", response)

                    // if(!TowerIdSelect[0]){  
                    //     setParentList(response?.data)
                    //     // console.log("it is run again");
                    //     setLoading(false);
                    //     return;
                    //  }else if (!FlatIdSelect[0]){
                    //     setParentList(response?.data)
                    //     // console.log("it is run again");
                    //     setLoading(false);
                    //     return;

                    //  }

                    // if(TowerIdSelect.length > 0){
                    //     const tempData = response.data.filter((item)=> item?.towerId == TowerIdSelect[0])
                    //     console.log("tempData tower",tempData)
                    //     setParentList(tempData);
                    //     setLoading(false);
                    // }else if(FlatIdSelect.length > 0){
                    //     const tempData = response.data.filter((item)=> item?.flatId == FlatIdSelect[0])
                    //     console.log("tempData flat",tempData)
                    //     setParentList(tempData);
                    //     setLoading(false);
                    // }else {
                    //     console.log("it is run 2");
                    //     setParentList(response?.data);
                    //     setLoading(false);
                    // }
                    setParentList(response?.data);
                    setParentUpdate(response?.data);
                    setLoading(false);
                }else{
                    toast.error("Something went wrong");
                    setLoading(false);
                }
            // }
            // else  {

            // }  

        } catch (error) {
            console.log(error);
            setLoading(false);
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber,
                "requestJSON": null,
                "uri": "/api/User/CuroNextUserFamily",
                "errorNumber": "GP01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Resident.js",
                "functionName": "GetParentList",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }

    }

    useEffect(() => {
        GetTowerList();
        GetParentList();
        // GetFlatList();
    }, [])

    // useEffect(() => {
    //     // if(TowerIdSelect.length > 0){
    //         GetParentList();
    //     // }    
    // }, [TowerIdSelect, FlatIdSelect])

    useEffect(() => {
        if(!TowerIdSelect[0]){  
            setParentUpdate(ParentList)
            // console.log("it is run again");
            setLoading(false);
            return;
         }
        
        
        if(TowerIdSelect){
            const tempData = ParentList.filter((item)=> item?.towerId == TowerIdSelect[0])
            console.log("tempData tower",tempData)
            setParentUpdate(tempData);
            setLoading(false);
        }else{
            console.log("it is run 2");
            setParentUpdate(ParentList);
            setLoading(false);
        }
    }, [TowerIdSelect])

    useEffect(() => {
        if (!FlatIdSelect[0]){
            setParentUpdate(ParentList)
            // console.log("it is run again");
            setLoading(false);
            return;

        }
        if(FlatIdSelect){
            const tempData = ParentList.filter((item)=> item?.flatId == FlatIdSelect[0])
            console.log("tempData flat",tempData)
            setParentUpdate(tempData);
            if(tempData[0]){
                setFlatFull(true);
            }else{
                setFlatFull(false);
            }
            setLoading(false);
        }else {
            console.log("it is run 2");
            setParentUpdate(ParentList);
            setLoading(false);
        }  
    }, [FlatIdSelect])

    useEffect(() => {
        if(TowerIdSelect){
            GetFlatList();
        }    
    }, [TowerIdSelect])

    const ResidentUser = async () => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        const bodydata = { 
            "createdUserId": myUserData.userId.toString(),
            "userName": UserName,
            "mobileNumber": MobileNumber,
            "termsAccepted": true,
            "productAccessFactor": myUserData.productAccessFactor,
            "emailId": EmailId,
          };
        try {
            if(!UserName){
                toast.warning("Please enter Name");
                setLoading(false);
            }else if(!MobileNumber){
                toast.warning("Please enter Mobile Number");
                setLoading(false);
            }else if(!Role){
                toast.warning("Please select Role");
                setLoading(false);
            }else{
            console.log("body", bodydata);
            let response = await commonAPI("User", bodydata)
            console.log("responsebody", response);
            setLoading(true);
                if(response.success == true && response.message.length == 0){
                    const bodyUserMapping = { 
                        "createdUserId": myUserData?.userId.toString(),
                        "userId": response?.data?.userId,
                        "buildingId": myUserData?.buildingId,
                        "buildingName": myUserData?.buildingName,
                        "towerId": TowerIdSelect[0],
                        "towerName": TowerIdSelect[1],
                        "flatId": FlatIdSelect[0],
                        "flatName": FlatIdSelect[1],
                        "roleName": Role,
                        "isParent": true,
                        "currentStatus": "APPROVED",
                        "approverComment": null,
                        "approverUserName": myUserData?.userName,
                        "approvalDt": new Date(),
                    };
                    console.log("body", bodyUserMapping);
                    let responseMapping = await commonAPI("CuroNextUserMappingAdd", bodyUserMapping)
                    console.log("responseMapping", responseMapping);
                    if(responseMapping.success == true && responseMapping.message.length == 0){
                        toast.success("Primary Resident Added");
                        setShow(false);
                        setLoading(false);
                        GetParentList();
                    }else{
                        toast.error(response.message[0]);
                        setLoading(false);
                    }
                
                }else{
                    toast.error(response.message[0]);
                }
            }    
        } catch (error) {
            console.log(error);
            setLoading(false);
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber,
                "requestJSON": null,
                "uri": "/api/User/User",
                "errorNumber": "RU01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Resident.js",
                "functionName": "ResidentUser",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }
        
          
          
    }
    const handleFileInputChangeTwo = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setSelectedImageTwo(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
      const handleFileInputChangeThree = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setSelectedImageThree(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
      const handleFileInputChangeFour = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setSelectedImageFour(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => {
            if (prevActiveStep >= steps.length - 1) { // Assuming 'steps' is your array of step labels
                return 0; // Reset to first step
            } else {
                // console.log(inpRef.current.value)
                 return prevActiveStep + 1;
                
            }
        });
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const getStepContent = (stepIndex) => {
        console.log(stepIndex)
        
      switch (stepIndex) {
        case 0:
            return <div><section id="steps-uid-0-p-0" role="tabpanel" aria-labelledby="steps-uid-0-h-0" className="body current" aria-hidden="false">
                 <div className="row mt-4">  
                 <div className="col-md-4">
                     <div className="form-group">
                              <label for="wfirstName2">  Resident Name : <span className="danger">*</span> </label>
                              <input type="text" value={ClientName} onChange={(e) => {setClientName(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
                     </div>
                 </div>
                 <div className="col-md-4">
                          <div className="form-group">
                      <label for="webUrl3">Email Id:</label>
                      <input type="text" value={ClientName} onChange={(e) => {setClientName(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
                           </div>
                      </div>
                <div className="col-md-4">
                          <div className="form-group">
                      <label for="webUrl3">Mobile Number:</label>
                      <input type="text" value={ClientName} onChange={(e) => {setClientName(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
                           </div>
                      </div>

            </div>
            <div className="row mt-4">
            <div className="col-md-6">
                    <div className="form-group">
                        <label for="wlastName2"> Tower : <span className="danger">*</span> </label>
      <Form.Select size="md" className="sltareamodal" >
      <option>   Tower A </option>
        <option>    Tower B </option> 
        <option>   Tower C </option>
        <option>    Tower D </option> 
        <option>   Tower E </option>
        <option>    Tower F </option> 
        <option>   Tower G </option>
        <option>    Tower H </option> 
 
      </Form.Select>
                        </div>
                </div> 
            <div className="col-md-6">
                   <div className="form-group">
                        <label for="wfirstName2">  Flat : <span className="danger">*</span> </label>
    <Form.Select size="md" className="sltareamodal" >
    <option>   Flat 101 </option>
        <option>    Flat 102 </option> 
        <option>   Flat 103 </option>
        <option>    Flat 104 </option> 
        <option>  Flat 105 </option>
        <option>   Flat 106 </option> 
        <option>  Flat 107 </option>
        <option>    Flat 108 </option> 
    </Form.Select>
                    </div>
                 </div>  
    </div>
            
            </section></div>
        case 1:
          return <div>
            <section id="steps-uid-0-p-1" role="tabpanel" aria-labelledby="steps-uid-0-h-1" className="body" aria-hidden="true">

            <div className="row mt-4">


<div className="col-md-4">
      <div className="form-group">
          <label for="jobTitle2">Identify Proof ID  :</label>
          <Form.Select size="md" className="sltareamodal" >
<option>   Aadhar Card </option>
<option>    Voter ID Card</option> 

</Form.Select>
      </div> 
      </div>
      <div className="col-md-4">
      <div className="form-group">
          <label for="webUrl3">Identity Proof Number :</label>
          <input type="text" value={ClientName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
          </div>
  </div> 
  <div className="col-md-4">
            <div className="form-group">
          <label for="webUrl3">Identify Proof Doc :</label>
          <input type="file" value={SpocName} onChange={(e) => handleFileInputChangeTwo(e)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" />
           
          </div>
          </div>
  </div>
  <div className="row mt-4 text-center">



            <div className="col-md-12">
                <div className='img_uploaded'>
                        {selectedImageTwo && <img src={selectedImageTwo} alt="Selected Image" />}
                </div>  
            </div>


  </div> 

 
          </section></div>;
        case 2:
          return <div>  
            
          <section id="steps-uid-0-p-2" role="tabpanel" aria-labelledby="steps-uid-0-h-2" className="body" aria-hidden="true">
         
          <div className="row mt-4">


<div className="col-md-4">
      <div className="form-group">
          <label for="jobTitle2">Address Proof ID  :</label>
          <Form.Select size="md" className="sltareamodal" >
<option>   Aadhar Card </option>
<option>    Voter ID Card</option> 

</Form.Select>
      </div> 
      </div>
      <div className="col-md-4">
      <div className="form-group">
          <label for="webUrl3">Address Proof Number :</label>
          <input type="text" value={ClientName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
          </div>
  </div> 
  
  <div className="col-md-4">
            <div className="form-group">
          <label for="webUrl3">Address Proof Doc :</label>
          <input type="file" value={SpocName} onChange={(e) => handleFileInputChangeTwo(e)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" />
           
          </div>
          </div>
  </div>
  <div className="row mt-4 text-center">


            <div className="col-md-12">
                <div className='img_uploaded'>
                        {selectedImageTwo && <img src={selectedImageTwo} alt="Selected Image" />}
                </div>  
            </div>


  </div>
         
          {/* <div className="row mt-4">


                    <div className="col-md-4">
                      <div className="form-group">
                      <label for="webUrl3">Vehicle Number :</label>
                      <input type="text" value={SpocName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
                      </div>
                         </div>
                    <div className="col-md-4">
                      <div className="form-group">
                          <label for="webUrl3">Tag Id :</label>
                          <input type="text" value={SpocName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
                          </div> 
                          </div>
                    <div className="col-md-4">
                      <div className="form-group">
                          <label for="jobTitle2">Vehicle Type:</label>
                          <Form.Select size="md" className="sltareamodal" >
        <option>   4 Wheeler </option>
        <option>    2 Wheeler</option>  
      </Form.Select>
                      </div>
                          </div>
                     </div>  <div className="row mt-4">
                          <div className="col-md-4">
                      <div className="form-group">
                          <label for="webUrl3">Make :</label>
                          <input type="text" value={SpocName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
                          </div> 
                          </div>
                          <div className="col-md-4">
                      <div className="form-group">
                          <label for="webUrl3">Color :</label>
                          <input type="text" value={SpocName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
                          </div> 
                          </div>
                          <div className="col-md-4">
                      <div className="form-group">
                          <label for="webUrl3">Modal :</label>
                          <input type="text" value={SpocName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
                          </div> 
                          </div>

</div> */}
 

          </section>
          </div>;
//           case 3:
//             return <div>
//             <section id="steps-uid-0-p-3" role="tabpanel" aria-labelledby="steps-uid-0-h-3" className="body" aria-hidden="true">
 
//             <div className="row mt-4">
//               <div className="col-md-6">
//                       <div className="form-group">
//                           <label for="jobTitle2">Registration Date:</label>
//                           <input type="text" value={SpocName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
//                       </div>
//                      </div>

//                           <div className="col-md-6">
//                       <div className="form-group">
//                           <label for="webUrl3">Manufacturing Year :</label>
//                           <input type="text" value={SpocName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
//                           </div> 
//                           </div>
//                           </div>
//                           <div className="row mt-4">
//                      <div className="col-md-6">
//                       <div className="form-group">
//                           <label for="webUrl3">Registration Certificate :</label>
//                           <input type="file" value={SpocName} onChange={(e) => handleFileInputChangeThree(e)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
//                           <div className='img_uploaded'>
//                                     {selectedImageThree && <img src={selectedImageThree} alt="Selected Image" />}
//                             </div>  
//                           </div> 
//                           </div>
//                           <div className="col-md-6">
//                       <div className="form-group">
//                           <label for="webUrl3">PUC :</label>
//                           <input type="file" value={SpocName} onChange={(e) => handleFileInputChangeFour(e)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
//                           <div className='img_uploaded'>
//                                     {selectedImageFour && <img src={selectedImageFour} alt="Selected Image" />}
//                             </div> 
//                           </div> 
//                           </div>
//               </div> 






// {/* 
//                     <div className="col-md-12 align-self-end text-right">
//                         <button type="button" className="btn btn-info" onClick={() => openCreateModal()}> Submit</button>
//                     </div> */}
//             </section></div>;
        default:
          return 'Unknown step';
      }
    };
  
    

     return (
    <>
    {Loading? 
        <div className="loaderDiv">
          <div className="loader"></div>
        </div> 
    :null}
    <Menus /> 
    <ToastContainer />
    <section id="client-builder">
        <div className="container-fluid pt-5" style={{paddingTop: "80px !important"}}>







        <div className="row mt-3" style={{alignItem:'center'}}>
            <div className="col-lg-4 col-md-4">
                <div className="HeadingTop">
                    <h3>  Primary Resident Details  </h3>
                    <span> List of Curo Solution Resident Details</span>
                </div>
            </div>
            <div className="col-lg-8 col-md-8 px-0  text-right" style={{alignContent:'center'}}>
            <div className='srcbtn'>
                        <select size="md" className="sltareamodal col-6 ml-auto" onChange={(e) => {
                            console.log("selection", e.target.value)
                            setTowerIdSelect(e.target.value.split(","));}}>
                            <option value={[]}>All Tower</option>
                            {TowerList ? TowerList.map((item, index) =>  <option key={index} value={[item?.towerId, item?.towerName]}>{item.towerName}</option>): null}
                        </select>
                        <select size="md" className="sltareamodal col-6 ml-auto" onChange={(e) => {
                            console.log("selection", e.target.value)
                            setFlatIdSelect(e.target.value.split(","));}}>
                            <option value={[]}>Select Flat</option>
                            {FlatList ? FlatList.map((item, index) =>  <option key={index} value={[item?.flatId, item?.flatName]}>{item.flatName}</option>): null}
                        </select>
                        {/* <Form.Select size="md" className="sltareamodal">
                            <option> 1A </option>
                            <option> 1B </option>
                            <option> 1C </option>
                            <option> 1D </option>
                            <option> 1E </option>
                            <option> 1F </option>
                            <option> 1G </option>
                            <option> 2A </option>
                            <option> 2B </option>
                            <option> 2C </option>
                            <option> 2D </option>
                            <option> 2E </option>
                            <option> 2F </option>
                            <option> 2G </option>
                            <option> 3A </option>
                            <option> 3B </option>
                            <option> 3C </option>
                            <option> 3D </option>
                            <option> 3E </option>
                            <option> 3F </option>
                            <option> 3G </option>
                        </Form.Select> */}
                        {/* <Form.Select size="md" className="sltareamodal">
                            <option> 1A-101 </option>
                            <option> 1B-102 </option>
                            <option> 1C-201 </option>
                            <option> 1D-202 </option>
                            <option> 2A-101 </option>
                            <option> 2A-102 </option>
                            <option> 2A-201 </option>
                            <option> 2A-202 </option>
                            <option> 3A-101 </option>
                            <option> 3A-102 </option>
                            <option> 3A-202 </option>
                            <option> 3D-101 </option>
                        </Form.Select> */}
                        {/* <button type="button" className="btn btn-info">
                            <FontAwesomeIcon icon={faSearch} />
                        </button> */}
                    </div>
                    <button type="button" className="btn btn-info" onClick={()=>
                        openCreateModal()}>
                        <FontAwesomeIcon icon={faPlus} />
                        Create New
                    </button>
            </div>
        </div>


 
            <Modal show={show} onHide={()=>
                setShow(false)} className="modal-100w modal-md clientmodal right text-end p-0" aria-labelledby="contained-modal-title-vcenter "> 
                <Modal.Header closeButton className='headerMDL'>
                    <Modal.Title id="example-custom-modal-styling-title"> Add Primary Resident </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='card-body'>
                        <div className="row" id="validation">
                            <div className="col-md-12 mb-2">
                                <div className="form-group text-left mt-2">
                                    <label for="wfirstName2"> Name  <span className="danger">*</span> </label>
                                    <input type="text" value={UserName} onChange={(e) => setUserName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
                            </div>
                            <div className="col-md-12 mb-2">
                                <div className="form-group text-left mt-2">
                                    <label for="wfirstName2"> Full Name  <span className="danger">*</span> </label>
                                    <input type="text" value={FullName} onChange={(e) => setFullName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group text-left mt-2">
                                    <label for="wlastName2"> Mobile Number  <span className="danger">*</span> </label>
                                    <input type="text" value={MobileNumber} onChange={(e) => setMobileNumber(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" />
                                </div>
                                    
                            </div>
                            <div className="col-md-12">
                                <div className="form-group text-left mt-2">
                                    <label for="wlastName2"> Email </label>
                                    <input type="text" value={EmailId} onChange={(e) => setEmailId(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" />
                                </div>
                                    
                            </div>
                            <div className="col-md-12">
                                <div className="form-group text-left mt-2">
                                    <label for="fav_language"> Role <span className="danger">*</span> </label><br />
                                    <input type="radio" id="Owner" name="fav_language" value="Owner"  onChange={(e) => setRole(e.target.value)}/>
                                    <label for="Owner">Owner</label><br />
                                    <input type="radio" id="Tenant" name="fav_language" value="Tenant"  onChange={(e) => setRole(e.target.value)}/>
                                    <label for="Tenant">Tenant</label><br />
                                    {/* <input type="radio"  name="fav_language" value="Tenant" />
                                    <label for="javascript">JavaScript</label> */}
                                    
                                </div>
                            </div>
                            <div className="  text-left mt-3">
                                <button type="button" className="btn border bg-white" onClick={() => setShow(false)}> Cancel</button>
                                <button type="button" className="btn btn-info" onClick={() => ResidentUser()}> Submit</button>
                            </div>
                            {/* <button  onClick={ResidentUser} className='mt-2'>Submit  </button> */}
                            {/* <div className="col-12">
                                <div className="card wizard-content">
                                    <div className="card-body">
                                        <form action="#" className="validation-wizard wizard-circle wizard clearfix" role="application" id="steps-uid-0" novalidate="novalidate">
                                            
                                            <div>
                                                <Stepper activeStep={activeStep}>
                                                    {steps.map((label) => ( 
                                                    <Step key={label}>
                                                        <StepLabel>{label}</StepLabel>
                                                    </Step>
                                                    ))} 
                                                </Stepper>
                                            </div>
                                            <div>
                                                {getStepContent(activeStep)} 
                                                <div>
                                                    <Button className="back_btn" disabled={activeStep===0} onClick={handleBack}> Back </Button>
                                                    <Button variant="contained" className="next_btn" onClick={handleNext}> {activeStep === steps.length - 1 ? 'Finish' : 'Next'} </Button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>    
                </Modal.Body>
            </Modal>
            <div className="row">
                <div className="col-12">
                    <div>
                        <div>
                     
                            {/* 
                            <h6 className="card-subtitle">Export details to Copy, CSV, Excel, PDF & Print</h6>
                            */} 
                            <div className="m-t-20">
                                <div id="example23_wrapper" className="dataTables_wrapper p-0">
                                    {/* 
                                    <div className="dt-buttons">
                                        <a className="dt-button buttons-copy buttons-html5" tabIndex="0" aria-controls="example23" href="#">
                                        <span>Copy</span>
                                        </a>
                                        <a className="dt-button buttons-csv buttons-html5" tabIndex="0" aria-controls="example23" href="#">
                                        <span>CSV</span>
                                        </a>
                                        <a className="dt-button buttons-excel buttons-html5" tabIndex="0" aria-controls="example23" href="#">
                                        <span>Excel</span>
                                        </a>
                                        <a className="dt-button buttons-pdf buttons-html5" tabIndex="0" aria-controls="example23" href="#">
                                        <span>PDF</span>
                                        </a>
                                        <a className="dt-button buttons-print" tabIndex="0" aria-controls="example23" href="#">
                                        <span>Print</span>
                                        </a>
                                    </div>
                                    <div id="example23_filter" className="dataTables_filter">
                                        <label>Search: <input type="search" className="" placeholder="" aria-controls="example23" />
                                        </label>
                                    </div>
                                    */} 
                                    <div className='row'>
                                    {ParentUpdate ? ParentUpdate.map((item, index) => <>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12 mb-4">
                                            <div class="card card-body bg-white boxshdw">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-4 text-center">
                                                        <a href="app-contact-detail.html">
                                                            {item?.userImage ? 
                                                             <img src={"data:image/jpeg;base64,"+item?.userImage} alt="user" class="img-circle img-responsive" />   
                                                            :<img src={familyavatar} alt="user" class="img-circle img-responsive" />}
                                                        
                                                        </a>
                                                    </div>
                                                    <div class="col-md-7 col-lg-8 padding-none">
                                                        <h4 class="box-title"> {item?.userName}</h4>
                                                        <img src={approvaltick} alt="user" className="apptick" /> {/* <small>Web Designer</small> */} 
                                                        <h6> Flat no / Tower : {item?.flatName} </h6>
                                                        <h6> Family Group ID : {item?.familyGroupId} </h6>
                                                        <Link to={"/Familydetails"} state={[{item: item, familyGroupId: item?.familyGroupId, userName: item?.userName, Email: item?.emailId, Address1: item?.addressLine1, Address2: item?.addressLine2, MobileNumber: item?.mobileNumber}]}>View more</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>): null} 
                                        {/* <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                            <div class="card card-body bg-white boxshdw">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-4 text-center">
                                                        <a href="app-contact-detail.html">
                                                        <img src={familyavatar} alt="user" class="img-circle img-responsive" />
                                                        </a>
                                                    </div>
                                                    <div class="col-md-7 col-lg-8 padding-none">
                                                        <h4 class="box-title"> Aman Mallik</h4>
                                                         <img src={approvaltick} alt="user" className="apptick" /> <small>Web Designer</small> 
                                                        <h6> Flat no / Tower : B-418 </h6>
                                                        <h6> Family Group ID : 118 </h6>
                                                        <Link to={Familydetails}>
                                                        View more</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                            <div class="card card-body bg-white boxshdw">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-4 text-center">
                                                        <a href="app-contact-detail.html">
                                                        <img src={familyavatar2} alt="user" class="img-circle img-responsive" />
                                                        </a>
                                                    </div>
                                                    <div class="col-md-7 col-lg-8 padding-none">
                                                        <h4 class="box-title"> Roshni Sharma </h4>
                                                        <img src={approvaltick} alt="user" className="apptick" /> <small>Web Designer</small> 
                                                        <h6> Flat no / Tower : B-418 </h6>
                                                        <h6> Family Group ID : 118 </h6>
                                                        <Link to="">
                                                        View more</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                            <div class="card card-body bg-white boxshdw">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-4 text-center">
                                                        <a href="app-contact-detail.html">
                                                        <img src={familyavatar3} alt="user" class="img-circle img-responsive" />
                                                        </a>
                                                    </div>
                                                    <div class="col-md-7 col-lg-8 padding-none">
                                                        <h4 class="box-title"> Aastha Verma </h4>
                                                        <img src={approvaltick} alt="user" className="apptick" /> <small>Web Designer</small> 
                                                        <h6> Flat no / Tower : B-418 </h6>
                                                        <h6> Family Group ID : 118 </h6>
                                                        <Link to="">
                                                        View more</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                            <div class="card card-body bg-white boxshdw">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-4 text-center">
                                                        <a href="app-contact-detail.html">
                                                        <img src={familyavatar4} alt="user" class="img-circle img-responsive" />
                                                        </a>
                                                    </div>
                                                    <div class="col-md-7 col-lg-8 padding-none">
                                                        <h4 class="box-title"> Shubham Yadav</h4>
                                                        <img src={approvaltick} alt="user" className="apptick" /> <small>Web Designer</small> 
                                                        <h6> Flat no / Tower : B-418 </h6>
                                                        <h6> Family Group ID : 118 </h6>
                                                        <Link to="">
                                                        View more</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <br />
                                    {/* <div className='row'>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                            <div class="card card-body boxshdw">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-4 text-center">
                                                        <a href="app-contact-detail.html">
                                                        <img src={familyavatar} alt="user" class="img-circle img-responsive" />
                                                        </a>
                                                    </div>
                                                    <div class="col-md-7 col-lg-8 padding-none">
                                                        <h4 class="box-title"> Aman Mallik</h4>
                                                        <img src={approvaltick} alt="user" className="apptick" />  <small>Web Designer</small>                                                        <h6> Flat no / Tower : B-418 </h6>
                                                        <h6> Family Group ID : 118 </h6>
                                                        <Link to="">
                                                        View more</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                            <div class="card card-body bg-white boxshdw">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-4 text-center">
                                                        <a href="app-contact-detail.html">
                                                        <img src={familyavatar2} alt="user" class="img-circle img-responsive" />
                                                        </a>
                                                    </div>
                                                    <div class="col-md-7 col-lg-8 padding-none">
                                                        <h4 class="box-title"> Roshni Sharma </h4>
                                                        <img src={approvaltick} alt="user" className="apptick" /> <small>Web Designer</small> 
                                                        <h6> Flat no / Tower : B-418 </h6>
                                                        <h6> Family Group ID : 118 </h6>
                                                        <Link to="">
                                                        View more</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                            <div class="card card-body bg-white boxshdw">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-4 text-center">
                                                        <a href="app-contact-detail.html">
                                                        <img src={familyavatar3} alt="user" class="img-circle img-responsive" />
                                                        </a>
                                                    </div>
                                                    <div class="col-md-7 col-lg-8 padding-none">
                                                        <h4 class="box-title"> Aastha Verma </h4>
                                                        <img src={approvaltick} alt="user" className="apptick" /> <small>Web Designer</small> 
                                                        <h6> Flat no / Tower : B-418 </h6>
                                                        <h6> Family Group ID : 118 </h6>
                                                        <Link to="">
                                                        View more</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                            <div class="card card-body bg-white boxshdw">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-4 text-center">
                                                        <a href="app-contact-detail.html">
                                                        <img src={familyavatar4} alt="user" class="img-circle img-responsive" />
                                                        </a>
                                                    </div>
                                                    <div class="col-md-7 col-lg-8 padding-none">
                                                        <h4 class="box-title"> Shubham Yadav</h4>
                                                        <img src={approvaltick} alt="user" className="apptick" /> <small>Web Designer</small> 
                                                        <h6> Flat no / Tower : B-418 </h6>
                                                        <h6> Family Group ID : 118 </h6>
                                                        <Link to="">
                                                        View more</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>)

}

export default Resident