import React , {useEffect, useRef, useState} from 'react'; 
import '../assets/css/custom.css'; 
import '../assets/css/font-awesome.css';
import '../assets/css/sweetalert.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/steps.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faCar, faChevronCircleRight, faChevronLeft, faEdit, faIdBadge, faL, faMotorcycle, faPlus, faSort, faTrash } from '@fortawesome/free-solid-svg-icons';
import clientlogo from '../assets/img/user1.jpg'; 
import oldy from '../assets/img/oldy.jpg'; 
import clientlogo2 from '../assets/img/user2.jpg'; 
import clientlogo3 from '../assets/img/user3.jpg'; 
import car from '../assets/img/car.png';
import Bike from '../assets/img/bike.png';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Menus from "./menus";
import { Link, useLocation } from 'react-router-dom';
import ReactDOM from "react-dom";
// import { Wizard, Steps, Step, WithWizard } from "react-multistep-wizard";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import stopMark from "../assets/img/stop.png";
import { Stepper, Step, StepLabel, Input } from '@mui/material';
import { Textarea } from '@material-tailwind/react';
import { Circle, FactCheck } from '@mui/icons-material';
import socialbg from '../assets/img/socialbg.jpg';
import familyavatar2 from '../assets/img/family-avatar-2.png';
import familyavatar3 from '../assets/img/family-avatar-3.png';
import familyavatar4 from '../assets/img/family-avatar-4.png';

import approvaltick from '../assets/img/approval.png';
import { faCheckCircle } from '@fortawesome/fontawesome-free-solid';
import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import cbadd from '../assets/img/cbadd.jpg'
import family from '../assets/img/family.jpg'
import {commonAPI} from "../services/CommonAPIService"
import {ErrorSource} from '../common/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Familydetails(props)  { 
    const [ParamsData, setParamsData] = useState();
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Step 1', 'Step 2', 'Step 3'];
    const [show, setShow] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [showVehicle, setShowVehicle] = useState(false);
    const [ClientName, setClientName] = useState("");
    const [UserName, setUserName] = useState("");
    const [MobileNumber, setMobileNumber] = useState("");
    const [Email, setEmail] = useState("");
    const [SpocName, setSpocName] = useState("");
    const [selectedImageTwo,setSelectedImageTwo] = useState(null)
    const [selectedImageThree,setSelectedImageThree] = useState(null)
    const [selectedImageFour,setSelectedImageFour] = useState(null)
    const [FamilyList, setFamilyList] = useState()
    const [VehicleList, setVehicleList] = useState()
    const [VehicleNumber, setVehicleNumber] = useState()
    const [VehicleId, setVehicleId] = useState()
    const [TagId, setTagId] = useState()
    const [VehicleType, setVehicleType] = useState("4WHEELER")
    const [FamilyGroupId, setFamilyGroupId] = useState()
    const [OwnerName, setOwnerName] = useState()
    const [SecurityLock, setSecurityLock] = useState()
    const [FuelList, setFuelList] = useState()
    const [FuelType, setFuelType] = useState()
    const [BrandList, setBrandList] = useState()
    const [BrandListUpdate, setBrandListUpdate] = useState()
    const [SearchBrand, setSearchBrand] = useState()
    const [BrandSelect, setBrandSelect] = useState()
    const [ShowUpdateBtn, setShowUpdateBtn] = useState(false)
    const [DeleteShow, setDeleteShow] = useState(false);
    const [VehicleTypeBtnBike, setVehicleTypeBtnBike] = useState(false);
    const [VehicleTypeBtnCar, setVehicleTypeBtnCar] = useState(true);
    const [showBrand, setShowBrand] = useState(false);
    // const [VehicleTypeBtn, set] = useState(false);
    const [UserData, setUserData] = useState("");
    console.log("BrandSelect", SearchBrand)
    const location = useLocation();
    const data = location.state
    console.log("dataparam", data)
    // setParamsData(data);

    
    const setInputValue = (item) => {
        setShowVehicle(true)
        console.log("item", item)
        setShowUpdateBtn(true)
        setFamilyGroupId(item.familyGroupId)
        setVehicleNumber(item.lpNumber)
        setFuelType(item?.fuelType)
        setOwnerName(item?.ownerName)
        setSecurityLock(item?.securityLock)
        setTagId(item?.tagId)
        setVehicleType(item?.vehicleType)
        setBrandSelect(item?.brand)
        setVehicleId(item?.vehicleId)
        if(item?.vehicleType == "4WHEELER"){
            setVehicleTypeBtnCar(true)
            setVehicleTypeBtnBike(false)
        }else{
            setVehicleTypeBtnCar(false)
            setVehicleTypeBtnBike(true)
        }
    };

    const openCreateModal = () => {
        setShowVehicle(true)
        setShowUpdateBtn(false)
        setFamilyGroupId("")
        setVehicleNumber("")
        setFuelType("")
        setOwnerName("")
        setSecurityLock("")
        setTagId("")
        setVehicleType("4WHEELER")
        setBrandSelect("")
        setVehicleId("")
        setVehicleTypeBtnCar(true)
        setVehicleTypeBtnBike(false)
    };


    const GetFamilyList = async () => {

        try {
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            setUserData(myUserData);
            const endPointVariableKey    = {
                urlVariable:data[0]?.familyGroupId,
                buildingId: myUserData?.buildingId
            
            }
            let response = await commonAPI("CuroNextUserFamilyList", undefined, endPointVariableKey)
            setLoading(true);
            if (response?.success== true){
                console.log("Family Table", response)
                setFamilyList(response.data);
                setLoading(false);
            }else{
                setLoading(false);
                toast.error("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber,
                "requestJSON": null,
                "uri": "/api/User/CuroNextUserFamily",
                "errorNumber": "RU01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "GetFamilyList.js",
                "functionName": "ResidentUser",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }

    }

    const GetVehicleList = async () => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        setUserData(myUserData);
        const bodyData = { 
            "familyGroupId": data[0]?.familyGroupId,
        };
        console.log("Vehicle Table bodyData", bodyData)
        try {
            const endPointVariableKey    = {
                buildingId: myUserData?.buildingId
            
            }  
            let response = await commonAPI("GetResidentVehicle", bodyData, endPointVariableKey)
            if (response?.success== true){
                console.log("Vehicle Table", response)
                setVehicleList(response.data);
            }else{
                toast.error("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
              const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber,
                "requestJSON": null,
                "uri": "/api/User/ResidentVehicle",
                "errorNumber": "RU01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "Familydetails.js",
                "functionName": "GetVehicleList",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }

    }

    useEffect(() => {
        GetVehicleList();
        GetFamilyList();
    }, [])

    const ChildUser = async () => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        const bodydata = { 
            "createdUserId": myUserData.userId.toString(),
            "userName": UserName,
            "mobileNumber": MobileNumber,
            "termsAccepted": true,
            "productAccessFactor": myUserData.productAccessFactor,
            "emailId": Email,
          };
        try {
            setLoading(true);
            if(!UserName){
                toast.warning("Please enter Name");
                setLoading(false);
            }else if(!MobileNumber){
                toast.warning("Please enter Mobile Number");
                setLoading(false);
            }else{
                console.log("body", bodydata);
                let response = await commonAPI("User", bodydata)
                setLoading(true);
                console.log("responsebody", response);
                if(response.success == true && response.message.length == 0){
                    const bodyUserMapping = { 
                        "createdUserId": myUserData?.userId.toString(),
                        "userId": response?.data?.userId,
                        "buildingId": myUserData?.buildingId,
                        "buildingName": myUserData?.buildingName,
                        "towerId": data[0]?.item?.towerId,
                        "towerName": data[0]?.item?.towerName,
                        "flatId": data[0]?.item?.flatId,
                        "flatName": data[0]?.item?.flatName,
                        "roleName": data[0]?.item?.roleName,
                        "isParent": false,
                        "currentStatus": "APPROVED",
                        "approverComment": null,
                        "approverUserName": myUserData?.userName,
                        "approvalDt": new Date(),
                        "familyGroupId": data[0]?.item?.familyGroupId,
                    };
                    console.log("body", bodyUserMapping);
                    let responseMapping = await commonAPI("CuroNextUserMappingAdd", bodyUserMapping)
                    console.log("responseMapping", responseMapping);
                    if(responseMapping.success == true && responseMapping.message.length == 0){
                        toast.success("Child Added");
                        setShow(false);
                        setLoading(false);
                        GetFamilyList();
                    }else{
                        toast.error(response.message[0]);
                        setLoading(false);
                    }
              
                }else{
                    toast.error(response.message[0]);
                    setLoading(false);
                }

            }    
            
        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber,
                "requestJSON": null,
                "uri": "/api/User/User",
                "errorNumber": "RU01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Familydetails.js",
                "functionName": "ResidentUser",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }
        
          
          
    }




    const GetFuelList = async () => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        try {
            let response = await commonAPI("VehicleFuelType")
            setLoading(true);
            if (response?.success== true){
                console.log("setFuelList", response)
                setFuelList(response.data);
                setLoading(false);
            }else{
                setLoading(false);
                toast.error("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            
            const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber,
                "requestJSON": null,
                "uri": "/api/Common/VehicleFuelType",
                "errorNumber": "FL01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Familydetails.js",
                "functionName": "GetFuelList",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }

    }

    const GetBrandList = async () => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        try {
            let response = await commonAPI("VehicleBrand")
            setLoading(true);
            if (response?.success== true){
                console.log("setBrand", response)
                setBrandList(response.data);
                setBrandListUpdate(response.data);
                setLoading(false);
            }else{
                setLoading(false);
                toast.error("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            
            const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber,
                "requestJSON": null,
                "uri": "/api/Common/VehicleBrand",
                "errorNumber": "BL01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Familydetails.js",
                "functionName": "GetBrandList",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }

    }

    // const GetVehicleResident = async () => {
    //     const auth = localStorage.getItem("userData");
    //     const myUserData = JSON.parse(auth);
    //     try {
    //         let response = await commonAPI("ResidentVehicleList")
    //         setLoading(true);
    //         if (response?.success== true){
    //             console.log("setFuelList", response)
    //             setVehicleListGet(response.data);
    //             setLoading(false);
    //         }else{
    //             setLoading(false);
    //             toast.error("Something went wrong");
    //         }

    //     } catch (error) {
    //         console.log(error);
    //         setLoading(false);
            
    //         const bodyError = { 
    //             "userId": myUserData?.userId,
    //             "mobileNumber": myUserData?.mobileNumber,
    //             "requestJSON": null,
    //             "uri": "/api/Vehicle/ResidentVehicle",
    //             "errorNumber": "VL01",
    //             "errorSource": ErrorSource,
    //             "errorSummary": error.message,
    //             "errorDescription": error.message,
    //             "fileClassName": "Familydetails.js",
    //             "functionName": "GetVehicleResident",
    //         };
    //         let response = await commonAPI("ErrorLogMaster", bodyError);
    //         console.log("statusCode", response);
    //         if (response.success == true && response.message.length == 0) {
    //             toast.error("Something went wrong");
    //             setLoading(false);
    //         } else {
    //             setLoading(false);
    //             toast.error("Something went wrong");
    //         }
    //     }

    // }

    useEffect(() => {
        GetFuelList();
        GetBrandList();
    }, [])

    const AddVehicle = async () => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        const bodyData = { 
            "createdUserId": myUserData.userName,
            "familyGroupId": data[0]?.familyGroupId,
            "gateAccessFactor": 11,
            "lpNumber":VehicleNumber.toUpperCase().replaceAll(" ",""),
            "fuelType": FuelType,
            "brand": BrandSelect,
            "ownerName": myUserData.userName,
            "securityLock": false,
            "tagId": TagId,
            "vehicleType": VehicleType
          };
        try {
            if(!VehicleNumber){
                toast.warning("Please enter Vehicle Number");
                setLoading(false);
            }else if(!TagId){
                toast.warning("Please enter Tag Id");
                setLoading(false);
            }else if(!FuelType){
                toast.warning("Please select Fuel Type");
                setLoading(false);
            }else if(!BrandSelect){
                toast.warning("Please select Brand");
                setLoading(false);  
            }else if(!VehicleType){
                toast.warning("Please select Vehicle Type");
                setLoading(false);        
            }else{  
                console.log("bodyData", bodyData)
                setLoading(true);
                const endPointVariableKey = {
                    buildingId:myUserData.buildingId
                }
                let response = await commonAPI("ResidentVehicleAdd", bodyData, endPointVariableKey);
                console.log("bodyDataresponse", response)
                if(response.success == true && response.message.length == 0){
                    toast.success("Vehicle Added"); 
                    GetVehicleList();
                    setLoading(false); 
                    setShowVehicle(false)
                }else{
                    toast.error(response.message[0]);
                    setLoading(false);
                }
            }    
            
        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber,
                "requestJSON": bodyData,
                "uri": "/api/Vehicle/ResidentVehicle",
                "errorNumber": "AV01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Familydetails.js",
                "functionName": "AddVehicle",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }
    }

    const UpdateVehicle = async () => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        const bodyData = { 
            "familyGroupId": FamilyGroupId,
            "gateAccessFactor": 11,
            "lpNumber": VehicleNumber.toUpperCase().replaceAll(" ",""),
            "fuelType": FuelType,
            "ownerName": OwnerName,
            "securityLock": SecurityLock,
            "tagId": TagId,
            "vehicleType": VehicleType,
            "vehicleId": VehicleId,
            "modifiedUserId":myUserData.userId.toString(),
            "brand": BrandSelect
          };
        try {
            if(!VehicleNumber){
                toast.warning("Please enter Vehicle Number");
                setLoading(false);
            }else if(!TagId){
                toast.warning("Please enter Tag Id");
                setLoading(false);
            }else if(!FuelType){
                toast.warning("Please select Fuel Type");
                setLoading(false);
            }else if(!BrandSelect){
                toast.warning("Please select Brand");
                setLoading(false);  
            }else if(!VehicleType){
                toast.warning("Please select Vehicle Type");
                setLoading(false);        
            }else{    
                console.log("bodyData", bodyData)
                setLoading(true);
                const endPointVariableKey = {
                    buildingId:myUserData.buildingId
                }
                let response = await commonAPI("ResidentVehicleAdd", bodyData, endPointVariableKey);
                console.log("bodyDataresponse", response)
                if(response.success == true && response.message.length == 0){
                    toast.success("Vehicle Updated"); 
                    GetVehicleList();
                    setLoading(false); 
                    setShowVehicle(false)
                }else{
                    toast.error(response.message[0]);
                    setLoading(false);
                }
            }    
            
        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber,
                "requestJSON": bodyData,
                "uri": "/api/Vehicle/ResidentVehicle",
                "errorNumber": "AV01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Familydetails.js",
                "functionName": "AddVehicle",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }
    }

    const ShowDeleteModal = (item) => {
        setDeleteShow(true);
        setVehicleId(item?.vehicleId)

    }

    const DeleteVehicle = async () => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        const bodyData = { 
            "vehicleId": VehicleId,
            "modifiedUserId":myUserData.userId.toString()
          };
        try {
            console.log("bodyData", bodyData)
            setLoading(true);
            const endPointVariableKey = {
                buildingId:myUserData.buildingId
            }
            let response = await commonAPI("ResidentVehicleDelete", bodyData, endPointVariableKey);
            console.log("bodyDataresponse", response)
              if(response.success == true && response.message.length == 0){
                toast.success("Delete Vehicle"); 
                GetVehicleList();
                setLoading(false); 
                setDeleteShow(false)
              }else{
                toast.error(response.message[0]);
                setLoading(false);
              }
            
        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber,
                "requestJSON": bodyData,
                "uri": "/api/Vehicle/ResidentVehicle",
                "errorNumber": "AV01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Familydetails.js",
                "functionName": "DeleteVehicle",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError);
            console.log("statusCode", response);
            if (response.success == true && response.message.length == 0) {
                toast.error("Something went wrong");
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Something went wrong");
            }
        }
    }

    const VehicleCar = async () => {
        setVehicleTypeBtnCar(true)
        setVehicleTypeBtnBike(false)
        setVehicleType("4WHEELER")
    }

    const VehicleBike = async () => {
        setVehicleTypeBtnCar(false)
        setVehicleTypeBtnBike(true)
        setVehicleType("2WHEELER")
    }

    const BarndNameSelected = async (item) => {
        console.log("brand Name Selected", item)
        setBrandSelect(item?.brandName)
        setShowBrand(false)
    }

    function filterData (text){
        setSearchBrand(text)
        //console.log(filterImg)
        const oldData = BrandList
        // console.log(oldLogo,"LOGO")
        console.log(text.toLowerCase())
        const newLogo=BrandListUpdate?.filter((BrandList)=>{
            return BrandList?.brandName?.toLowerCase()?.includes(text.toLowerCase())
        })
        // console.log(newLogo)
        setBrandList(newLogo)
        // console.log(logo)
    }
    
    
    const handleFileInputChangeTwo = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setSelectedImageTwo(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
      const handleFileInputChangeThree = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setSelectedImageThree(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
      const handleFileInputChangeFour = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setSelectedImageFour(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => {
            if (prevActiveStep >= steps.length - 1) { // Assuming 'steps' is your array of step labels
                return 0; // Reset to first step
            } else {
                // console.log(inpRef.current.value)
                 return prevActiveStep + 1;
                
            }
        });
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const getStepContent = (stepIndex) => {
        console.log(stepIndex)
        
      switch (stepIndex) {
        case 0:
            return <div><section id="steps-uid-0-p-0" role="tabpanel" aria-labelledby="steps-uid-0-h-0" className="body current" aria-hidden="false">
                 <div className="row mt-4">  
                 <div className="col-md-4">
                     <div className="form-group">
                              <label for="wfirstName2">  Resident Name : <span className="danger">*</span> </label>
                              <input type="text" value={ClientName} onChange={(e) => {setClientName(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
                     </div>
                 </div>
                 <div className="col-md-4">
                          <div className="form-group">
                      <label for="webUrl3">Email Id:</label>
                      <input type="text" value={ClientName} onChange={(e) => {setClientName(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
                           </div>
                      </div>
                <div className="col-md-4">
                          <div className="form-group">
                      <label for="webUrl3">Mobile Number:</label>
                      <input type="text" value={ClientName} onChange={(e) => {setClientName(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
                           </div>
                      </div>

            </div>
            <div className="row mt-4">
            <div className="col-md-6">
                    <div className="form-group">
                        <label for="wlastName2"> Tower : <span className="danger">*</span> </label>
      <Form.Select size="md" className="sltareamodal" >
        <option>   Tower A </option>
        <option>   Tower B </option>
        <option>   Tower C </option>
        <option>   Tower D </option>
        <option>   Tower E </option>
        <option>   Tower F </option>
        <option>   Tower G </option>
        <option>   Tower H </option>

 
      </Form.Select>
                        </div>
                </div> 
            <div className="col-md-6">
                   <div className="form-group">
                        <label for="wfirstName2">  Flat : <span className="danger">*</span> </label>
    <Form.Select size="md" className="sltareamodal" >
        <option>   Flat 101 </option>
        <option>   Flat 102 </option>
        <option>   Flat 103 </option>
        <option>   Flat 104 </option>
        <option>   Flat 105 </option>
        <option>   Flat 106 </option>
        <option>   Flat 107 </option>

    </Form.Select>
                    </div>
                 </div>  
    </div>
            
            </section></div>
        case 1:
          return <div>
            <section id="steps-uid-0-p-1" role="tabpanel" aria-labelledby="steps-uid-0-h-1" className="body" aria-hidden="true">

            <div className="row mt-4">


<div className="col-md-4">
      <div className="form-group">
          <label for="jobTitle2">Identify Proof ID  :</label>
          <Form.Select size="md" className="sltareamodal" >
<option>   Aadhar Card </option>
<option>    Voter ID Card</option> 

</Form.Select>
      </div> 
      </div>
      <div className="col-md-4">
      <div className="form-group">
          <label for="webUrl3">Identity Proof Number :</label>
          <input type="text" value={ClientName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
          </div>
  </div> 
  <div className="col-md-4">
            <div className="form-group">
          <label for="webUrl3">Identify Proof Doc :</label>
          <input type="file" value={SpocName} onChange={(e) => handleFileInputChangeTwo(e)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" />
           
          </div>
          </div>
  </div>
  <div className="row mt-4 text-center">



            <div className="col-md-12">
                <div className='img_uploaded'>
                        {selectedImageTwo && <img src={selectedImageTwo} alt="Selected Image" />}
                </div>  
            </div>


  </div> 

 
          </section></div>;
        case 2:
          return <div>  
          <section id="steps-uid-0-p-2" role="tabpanel" aria-labelledby="steps-uid-0-h-2" className="body" aria-hidden="true">
         
          <div className="row mt-4">


<div className="col-md-4">
      <div className="form-group">
          <label for="jobTitle2">Address Proof ID  :</label>
          <Form.Select size="md" className="sltareamodal" >
<option>   Aadhar Card </option>
<option>    Voter ID Card</option> 

</Form.Select>
      </div> 
      </div>
      <div className="col-md-4">
      <div className="form-group">
          <label for="webUrl3">Address Proof Number :</label>
          <input type="text" value={ClientName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
          </div>
  </div> 
  <div className="col-md-4">
            <div className="form-group">
          <label for="webUrl3">Address Proof Doc :</label>
          <input type="file" value={SpocName} onChange={(e) => handleFileInputChangeTwo(e)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" />
           
          </div>
          </div>
  </div>
  <div className="row mt-4 text-center">



            <div className="col-md-12">
                <div className='img_uploaded'>
                        {selectedImageTwo && <img src={selectedImageTwo} alt="Selected Image" />}
                </div>  
            </div>


  </div>
         
          {/* <div className="row mt-4">


                    <div className="col-md-4">
                      <div className="form-group">
                      <label for="webUrl3">Vehicle Number :</label>
                      <input type="text" value={SpocName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
                      </div>
                         </div>
                    <div className="col-md-4">
                      <div className="form-group">
                          <label for="webUrl3">Tag Id :</label>
                          <input type="text" value={SpocName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
                          </div> 
                          </div>
                    <div className="col-md-4">
                      <div className="form-group">
                          <label for="jobTitle2">Vehicle Type:</label>
                          <Form.Select size="md" className="sltareamodal" >
        <option>   4 Wheeler </option>
        <option>    2 Wheeler</option>  
      </Form.Select>
                      </div>
                          </div>
                     </div>  <div className="row mt-4">
                          <div className="col-md-4">
                      <div className="form-group">
                          <label for="webUrl3">Make :</label>
                          <input type="text" value={SpocName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
                          </div> 
                          </div>
                          <div className="col-md-4">
                      <div className="form-group">
                          <label for="webUrl3">Color :</label>
                          <input type="text" value={SpocName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
                          </div> 
                          </div>
                          <div className="col-md-4">
                      <div className="form-group">
                          <label for="webUrl3">Modal :</label>
                          <input type="text" value={SpocName} onChange={(e) => setSpocName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
                          </div> 
                          </div>

</div> */}
 

          </section>
          </div>;
        default:
          return 'Unknown step';
      }
    };
    
  



    const getStepContentvehicle = (stepIndexVehicle) => {
        console.log(stepIndexVehicle)
        
        switch (stepIndexVehicle) {
            case 0:
                return <div>
                <section id="steps-uid-0-p-0" role="tabpanel" aria-labelledby="steps-uid-0-h-0" className="body current" aria-hidden="false">
                     <div className="row mt-4">  
                     <div className="col-md-6">
                         <div className="form-group">
                                  <label for="wfirstName2">  Vehicle Number : <span className="danger">*</span> </label>
                                  <input type="text" value={ClientName} onChange={(e) => {setClientName(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
                         </div>
                     </div>
                     <div className="col-md-6">
                              <div className="form-group">
                          <label for="webUrl3">Registration Date:</label>
                          <input type="text" value={ClientName} onChange={(e) => {setClientName(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
                               </div>
                          </div>
    
    
                </div>
                <div className="row mt-4">
                <div className="col-md-6">
                              <div className="form-group">
                          <label for="webUrl3">Manufacturing Year:</label>
                          <input type="text" value={ClientName} onChange={(e) => {setClientName(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
                               </div>
                          </div>
                <div className="col-md-6">
                       <div className="form-group">
                            <label for="wfirstName2">  Vehicle Type : <span className="danger">*</span> </label>
        <Form.Select size="md" className="sltareamodal" >
            <option>   4 Wheeler </option>
            <option>    2 Wheeler</option> 
        </Form.Select>
                        </div>
                     </div>  
        </div>
                
                </section>
                </div>
            case 1:
              return <div>
                <section id="steps-uid-0-p-1" role="tabpanel" aria-labelledby="steps-uid-0-h-1" className="body" aria-hidden="true">
    
                <div className="row mt-4">
    
    
    <div className="col-md-6">
    <div className="form-group">
                          <label for="webUrl3">Color:</label>
                          <input type="text" value={ClientName} onChange={(e) => {setClientName(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
                               </div>
          </div>
          <div className="col-md-6">
          <div className="form-group">
                          <label for="webUrl3">Model:</label>
                          <input type="text" value={ClientName} onChange={(e) => {setClientName(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
                               </div>
      </div> 
      </div>
      <div className="row mt-4">
    
      <div className="col-md-6">
      <div className="form-group">
                          <label for="webUrl3">Tag ID:</label>
                          <input type="text" value={ClientName} onChange={(e) => {setClientName(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
                               </div>
              </div>
              <div className="col-md-6">
      <div className="form-group">
                          <label for="webUrl3">Make:</label>
                          <input type="text" value={ClientName} onChange={(e) => {setClientName(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
                               </div>
              </div>
    
    
    
    
      </div> 
    
     
              </section>
              </div>;
            case 2:
              return <div>  
              <section id="steps-uid-0-p-2" role="tabpanel" aria-labelledby="steps-uid-0-h-2" className="body" aria-hidden="true">
             
              <div className="row mt-4">
                                   <div className="col-md-6">
                           <div className="form-group">
                               <label for="webUrl3">Registration Certificate :</label>
                               <input type="file" value={SpocName} onChange={(e) => handleFileInputChangeThree(e)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
                               <div className='img_uploaded'>
                                         {selectedImageThree && <img src={selectedImageThree} alt="Selected Image" />}
                                 </div>  
                               </div> 
                               </div>
                              <div className="col-md-6">
                          <div className="form-group">
                               <label for="webUrl3">PUC :</label>
                               <input type="file" value={SpocName} onChange={(e) => handleFileInputChangeFour(e)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
                               <div className='img_uploaded'>
                                         {selectedImageFour && <img src={selectedImageFour} alt="Selected Image" />}
                                </div> 
                             </div> 
                               </div>
       
      </div>
              </section>
              </div>;
        default:
          return 'Unknown step';
      }
    };
    
    const [key, setKey] = useState('Family-Details');
    
     return (
    <>
        {Loading? 
            <div className="loaderDiv">
                <div className="loader"></div>
            </div> 
        :null}
        <Menus /> 
        <ToastContainer />
        <section id="client-builder">
            <div className="container-fluid pt-4">
                <div className="row page-titles">
                    <div className="col-md-12 align-self-end text-right">
                        <Link to ={"/Resident"} >
                        <FontAwesomeIcon icon={faChevronLeft} className='bck'/>
                        <span> Back</span> </Link>
                    </div>
                </div>
                <Modal centered show={DeleteShow} onHide={() =>
            setDeleteShow(false)} dialogclassName="modal-100w" aria-labelledby="contained-modal-title-vcenter ">
                <Modal.Header className="headerMDL" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" centered onClick={openCreateModal}>
                        <h4 className="mb-0"> Delete Tower </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row" id="validation">
                        <div className="col-12">
                            <div className="FnFItem">
                            <div className="FnFimg">
                                <img src={stopMark} className='img img-fluid img-responsive'  alt="placeholder" />
                            </div>
                            <div className="FnFtxt">
                                <h3> Are your Sure You want to Delete? </h3>
                            </div>
                            </div>
                            <div className='confirmation_btn'>
                            <div className='both_ConfrmBTN' onClick={DeleteVehicle}>
                                <Button variant="danger">Delete</Button>{' '}
                            </div>
                            <div className='both_ConfrmBTN' onClick={() => setDeleteShow(false)} >
                                <Button variant="light"> Cancel</Button>{' '}
                            </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                </Modal>
                <Modal show={show} onHide={() =>
                    setShow(false)} className="modal-100w modal-md clientmodal right text-end p-0" aria-labelledby="contained-modal-title-vcenter ">
                    <Modal.Header closeButton className='headerMDL'>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Family Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='card-body'>
                            <div className="row" id="validation">
                                <div className="col-md-12 mb-2">
                                    <div className="form-group text-left mt-2">
                                        <label for="wfirstName2"> Name  <span className="danger">*</span> </label>
                                        <input type="text" value={UserName} onChange={(e) => setUserName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group text-left mt-2">
                                        <label for="wlastName2"> Mobile Number  <span className="danger">*</span> </label>
                                        <input type="text" value={MobileNumber} onChange={(e) => setMobileNumber(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" />
                                    </div>
                                        
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group text-left mt-2">
                                        <label for="wlastName2"> Email </label>
                                        <input type="text" value={Email} onChange={(e) => setEmail(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" />
                                    </div>
                                        
                                </div>
                                <div className="  text-left mt-3">
                                    <button type="button" className="btn border bg-white" onClick={() => setShow(false)}> Cancel</button>
                                    <button type="button" className="btn btn-info" onClick={() => ChildUser()}> Submit</button>
                                </div>
                            
                            </div>
                        </div>   
                        {/* <div className="row" id="validation">
                            <div className="col-12">
                                <div className="card wizard-content">
                                    <div className="card-body">
                                        <form action="#" className="validation-wizard wizard-circle wizard clearfix" role="application" id="steps-uid-0" novalidate="novalidate">
                                            <div>
                                                <Stepper activeStep={activeStep}>
                                                    {steps.map((label) => (
                                                    <Step key={label}>
                                                        <StepLabel>{label}</StepLabel>
                                                    </Step>
                                                    ))}
                                                </Stepper>
                                            </div>
                                            <div>
                                                {getStepContent(activeStep)}
                                                <div>
                                                    <Button className="back_btn" disabled={activeStep === 0} onClick={handleBack}>
                                                    Back
                                                    </Button>
                                                    <Button variant="contained" className="next_btn"  onClick={handleNext}>
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </Modal.Body>
                </Modal>

                <Modal show={showBrand} onHide={() =>
                    setShowBrand(false)} className="modal-100w modal-md clientmodal right text-end p-0" aria-labelledby="contained-modal-title-vcenter" style={{width: "100%"}}>
                    <Modal.Header closeButton className='headerMDL'>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Select Vehicle Brand
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='card-body pt-0'>
                            <div className="row" id="validation">
                                 
                                <div className="col-md-12 mb-2 pl-2">
                                    <div className="form-group text-left mt-2">
                                        {/* <label for="wfirstName2"> Vehicle Number  </label> */}
                                        <input type="text" value={SearchBrand} placeholder='Search' onChange={(e) => filterData(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
                                </div>
                                <div className='row mt-3'>
                                {BrandList ? BrandList.map((item, index) =>  <button key={index} className='col-4 text-center m-0' value={item?.brandName} onClick={(e) => BarndNameSelected(item)}>
                                        <img  className="card-img" style={{width: "100%", height:100, border: "1px solid #dadada", borderRadius: 10, padding: 5}} src={"data:image/png;base64,"+(item?.logo)} alt="Card image" />
                                        <h6 className='mt-2'>{item?.brandName}</h6>
                                    </button>): null}
                                    
                                    
                                </div>
                                
                                {/* {ShowUpdateBtn ?
                                    <div className="  text-left mt-3">
                                        <button type="button" className="btn border bg-white" onClick={() => setShowBrand(false)}> Cancel</button>
                                        <button type="button" className="btn btn-info" onClick={() => UpdateVehicle()}> Update</button>
                                    </div>:
                                    <div className="  text-left mt-3">
                                        <button type="button" className="btn border bg-white" onClick={() => setShowBrand(false)}> Cancel</button>
                                        <button type="button" className="btn btn-info" onClick={() => AddVehicle()}> Submit</button>
                                    </div>
                                } */}
                            
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>        

                <Modal show={showVehicle} onHide={() =>
                    setShowVehicle(false)} className="modal-100w modal-md clientmodal right text-end p-0" aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header closeButton className='headerMDL'>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Edit Vehicle Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='card-body pt-0'>
                            <div className="row" id="validation">
                                 <div className="col-md-12 mb-2 text-center">
                                          <div className='fam_vehicleBtn'>
                                                {VehicleTypeBtnCar ? 
                                                    <FontAwesomeIcon icon={faCar} onClick={() => VehicleCar()} className='fam_vehicleBtnicon bg-info text-white' />
                                                    :
                                                    <FontAwesomeIcon icon={faCar} onClick={() => VehicleCar()} className='fam_vehicleBtnicon text-info border-info' />
                                                }
                                                {VehicleTypeBtnBike ? 
                                                    <FontAwesomeIcon icon={faMotorcycle} onClick={() => VehicleBike()} className='fam_vehicleBtnicon bg-info text-white'/>
                                                    :
                                                    <FontAwesomeIcon icon={faMotorcycle} onClick={() => VehicleBike()} className='fam_vehicleBtnicon text-info border-info'/>
                                                    
                                                    
                                                }
                                          </div>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <div className="form-group text-left mt-2">
                                        <label for="wfirstName2"> Vehicle Number  <span className="danger">*</span> </label>
                                        <input type="text" value={VehicleNumber} onChange={(e) => setVehicleNumber(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group text-left mt-2">
                                        <label for="wlastName2"> Tag Id  <span className="danger">*</span> </label>
                                        <input type="text" value={TagId} onChange={(e) => setTagId(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" />
                                    </div>
                                        
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group text-left mt-3">
                                        <label for="wlastName2"> Fuel Type </label><br />
                                        <select size="md" className="form-control required" value={FuelType} onChange={(e) => {
                                            console.log("selection", e.target.value)
                                            setFuelType(e.target.value);}}>
                                            <option value={""}>Select Type</option>
                                            {FuelList ? FuelList.map((item, index) =>  <option key={index} value={item?.fuelTypeName}>{item.fuelTypeName}</option>): null}
                                        </select>
                                    </div>
                                        
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group text-left mt-3">
                                        <label for="wlastName2"> Brand Type </label><br />
                                        <div onClick={() => setShowBrand(true)}>
                                            <input type="text" value={BrandSelect} placeholder='Select Brand' className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" />
                                        </div>    
                                        {/* <select size="md" className="form-control required" value={BrandSelect} onChange={(e) => {
                                            console.log("selection", e.target.value)
                                            setBrandSelect(e.target.value);}}>
                                            <option value={""}>Select Type</option>
                                            {BrandList ? BrandList.map((item, index) =>  <option key={index} value={item?.brandName}>{item.brandName}</option>): null}
                                        </select> */}
                                    </div>
                                        
                                </div>
                                {/* <div className="col-md-12">
                                    <div className="form-group text-left mt-3">
                                        <label for="wlastName2"> Vehicle Type </label><br />
                                        <select size="md" className="form-control required" value={VehicleType} onChange={(e) => {
                                            console.log("selection", e.target.value)
                                            setVehicleType(e.target.value);}}>
                                            <option value={""}>Select Type</option>
                                            <option value={"4WHEELER"}>Car</option>
                                            <option value={"2WHEELER"}>Bike</option>
                                        </select>
                                    </div>
                                        
                                </div> */}
                                {ShowUpdateBtn ?
                                    <div className="  text-left mt-3">
                                        <button type="button" className="btn border bg-white" onClick={() => setShowVehicle(false)}> Cancel</button>
                                        <button type="button" className="btn btn-info" onClick={() => UpdateVehicle()}> Update</button>
                                    </div>:
                                    <div className="  text-left mt-3">
                                        <button type="button" className="btn border bg-white" onClick={() => setShowVehicle(false)}> Cancel</button>
                                        <button type="button" className="btn btn-info" onClick={() => AddVehicle()}> Submit</button>
                                    </div>
                                }
                            
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <div className="row">
                    <div className="col-12">
                        <div className="mt-3">
                            <div >
                                {/* <h4 className="card-title">Family Details</h4> */}
                                {/* 
                                <h6 className="card-subtitle">Export details to Copy, CSV, Excel, PDF & Print</h6>
                                */}
                                <div className="m-t-20">
                                    <div id="example23_wrapper" className="dataTables_wrapper">
                                        <div className="row">
                                            <div className="col-lg-3 col-xlg-3 col-md-5">
                                                <div className="card">
                                                    <img className="card-img" src={socialbg} alt="Card image" />
                                                    <div className="card-img-overlay card-inverse social-profile">
                                                        <div className="align-self-center pt-4">
                                                            <img src={oldy} className="img-circle" width="100" /> 
                                                            <Link to ="">
                                                            <FontAwesomeIcon icon={faEdit} onClick={() =>
                                                            setShow(true)} class="append_position"/></Link> <br/> <br/>
                                                            <h4 className="card-title">{data[0]?.userName}</h4>
                                                            <h6 className="card-subtitle text-white">
                                                                {data[0]?.Email}
                                                            </h6>
                                                            <h6 className='text-white'>Phone : +91 {data[0]?.MobileNumber}</h6>
                                                            <h6 className='text-white'>Address : {data[0]?.Address1} {data[0]?.Address2}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-9 col-xlg-9 col-md-7">
                                                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) =>
                                                    setKey(k)} className="mb-3">
                                                    <Tab eventKey="Family-Details" title="Family Details">
                                                        <div className="row el-element-overlay">
                                                            {FamilyList ? FamilyList.map((item, index) => <>
                                                                <div className="col-lg-3 col-md-6 mb-3">
                                                                    <div className="card">
                                                                        <div className="el-card-item">
                                                                            <div className="el-card-avatar el-overlay-1">
                                                                                <img src={clientlogo} alt="user" />
                                                                                
                                                                            </div>
                                                                            <div className="el-card-content">
                                                                                <h3 className="box-title">{item.userName}</h3>
                                                                                <small>+91-{item.mobileNumber}</small>
                                                                                <br/> 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> ): null} 
                                                            {/* <div className="col-lg-3 col-md-6">
                                                                <div className="card">
                                                                    <div className="el-card-item">
                                                                        <div className="el-card-avatar el-overlay-1">
                                                                            <img src={clientlogo2} alt="user" />
                                                                            <div className="el-overlay scrl-dwn">
                                                                                <ul className="el-info">
                                                                                    <li>
                                                                                        <a className="btn default btn-outline image-popup-vertical-fit" href="../assets/images/users/1.jpg">
                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="el-card-content">
                                                                            <h3 className="box-title">Ritika Jha</h3>
                                                                            <small>+91-7869841054</small>
                                                                            <br/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-6">
                                                                <div className="card">
                                                                    <div className="el-card-item">
                                                                        <div className="el-card-avatar el-overlay-1">
                                                                            <img src={clientlogo3} alt="user" />
                                                                            <div className="el-overlay scrl-dwn">
                                                                                <ul className="el-info">
                                                                                    <li>
                                                                                        <a className="btn default btn-outline image-popup-vertical-fit" href="../assets/images/users/1.jpg">
                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                        </a>
                                                                                    </li>
                                                                                    
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="el-card-content">
                                                                            <h3 className="box-title">Mohit Sharma</h3>
                                                                            <small>+91-7905405765</small>
                                                                            <br/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            <div className="col-lg-3 col-md-6">
                                                                <div className="card">
                                                                    <div className="el-card-item">
                                                                        <div className="el-card-avatar el-overlay-1">
                                                                            <img src={family} alt="user" />
                                                                            <div className="el-overlay scrl-dwn">
                                                                                <ul className="el-info">
                                                                                    <li>
                                                                                        <a className="btn default btn-outline image-popup-vertical-fit" onClick={() => setShow(true)}>
                                                                                            <FontAwesomeIcon icon={faPlus} />
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="el-card-content">
                                                                            <h3 className="box-title">Add Member</h3>
                                                                            <br/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* 
                                                            <div className="col-lg-3 col-md-6 fmyadd">
                                                                <Link to="#">
                                                                <div className='editfamilymmbr'>
                                                                    <FontAwesomeIcon icon={faPlus} className='plus_marg'/>
                                                                    <h4 className='bold'> Add Member</h4>
                                                                </div>
                                                                </Link>
                                                            </div>
                                                            */}
                                                        </div>
                                                    </Tab>
                                                    <Tab eventKey="Vehicle-Details" title="Vehicle Details">
                                                        <div className="row">
                                                            <div className="row el-element-overlay">
                                                            {VehicleList ? VehicleList.map((item, index) => <>
                                                                <div className="col-lg-3 col-md-6">
                                                                    <div className="card">
                                                                        <div className="el-card-item">
                                                                            <div className="el-card-avatar el-overlay-1">
                                                                                {item?.vehicleType == "4WHEELER" ?
                                                                                    <img src={car} alt="user" />
                                                                                    :
                                                                                    <img src={Bike} alt="user" />
                                                                                }
                                                                                {/* <img src={car} alt="user" />
                                                                                <img src={Bike} alt="user" /> */}
                                                                                <div className="el-overlay scrl-dwn">
                                                                                    <ul className="el-info">
                                                                                        <li>
                                                                                            <a className="btn default btn-outline image-popup-vertical-fit" >
                                                                                                <FontAwesomeIcon icon={faEdit} onClick={() =>
                                                                                                setInputValue(item)}  />
                                                                                            </a>
                                                                                            <a className="btn default btn-outline image-popup-vertical-fit ml-3 fam_del" >
                                                                                                <FontAwesomeIcon icon={faTrash}  onClick={() =>
                                                                                                ShowDeleteModal(item)} />
                                                                                            </a>
                                                                                        </li>
                                                                                        {/* 
                                                                                        <li><a className="btn default btn-outline" href="javascript:void(0);"><i className="sl-icon-link"></i></a></li>
                                                                                        */}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div className="el-card-content">
                                                                                <h3 className="box-title">{item.lpNumber}</h3>
                                                                                <small>
                                                                                {item.make}
                                                                                </small>
                                                                                <br/> 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> ): null}    
                                                                {/* <div className="col-lg-3 col-md-6">
                                                                    <div className="card">
                                                                        <div className="el-card-item">
                                                                            <div className="el-card-avatar el-overlay-1">
                                                                                <img src={Bike} alt="user" />
                                                                                <div className="el-overlay scrl-dwn">
                                                                                    <ul className="el-info">
                                                                                        <li>
                                                                                            <a className="btn default btn-outline image-popup-vertical-fit">
                                                                                                <FontAwesomeIcon icon={faEdit} />
                                                                                            </a>
                                                                                        </li>
                                                                                        
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div className="el-card-content">
                                                                                <h3 className="box-title">UP16SSD6758</h3>
                                                                                <small>
                                                                                Suzuki Gixxer 
                                                                                </small>
                                                                                <br/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-3 col-md-6">
                                                                    <div className="card">
                                                                        <div className="el-card-item">
                                                                            <div className="el-card-avatar el-overlay-1">
                                                                                <img src={cbadd} alt="user" />
                                                                                <div className="el-overlay scrl-dwn">
                                                                                    <ul className="el-info">
                                                                                        <li>
                                                                                            <a className="btn default btn-outline image-popup-vertical-fit" >
                                                                                                <FontAwesomeIcon icon={faPlus} onClick={() =>
                                                                                                openCreateModal()}/>
                                                                                            </a>
                                                                                        </li>
                                                                                        {/* 
                                                                                        <li><a className="btn default btn-outline" href="javascript:void(0);"><i className="sl-icon-link"></i></a></li>
                                                                                        */}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div className="el-card-content">
                                                                                <h3 className="box-title">Add Vehicle</h3>
                                                                                <br/> 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)

}
export default Familydetails