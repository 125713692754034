import React, { useEffect, useRef, useState } from "react";
import "../assets/css/custom.css";
import "../assets/css/client.css";
import "../assets/css/font-awesome.css";
import "../assets/css/sweetalert.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/steps.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faPlus,
    faSort,
    faTrash,
    faPenToSquare,
    faTimes,
    faAdd,
  } from "@fortawesome/free-solid-svg-icons";
import clientlogo from "../assets/img/user1.jpg";
import clientlogo2 from "../assets/img/user2.jpg";
import Nodata from "../assets/img/NoData.png";
import clientDemoImg from "../assets/img/EmptyLogo.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { commonAPI } from "../services/CommonAPIService";
import Menus from "./menus";
// import { Wizard, Steps, Step, WithWizard } from "react-multistep-wizard";
// import SlideButton from "react-slide-button";
import { Stepper, Step, StepLabel } from "@mui/material";
// import  clientimg  from '../assets/img/clientimg.jpg';
// import  clientimg2  from '../assets/img/clientimg-2.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import INDFlag from "../assets/img/INDFlag.jpg";
import filepreview from '../assets/img/filepreview.png';
import { ErrorSource } from "../common/constants";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
 import switchBaseClasses from "@mui/material/internal/switchBaseClasses";
 import { styled } from '@mui/material/styles';
 import FormGroup from '@mui/material/FormGroup';
 import FormControlLabel from '@mui/material/FormControlLabel';
 import noticeuserupload from '../assets/img/noticeuserupload.png';
 import Switch, { SwitchProps } from '@mui/material/Switch';
 import Stack from '@mui/material/Stack';
 import Typography from '@mui/material/Typography';
 import JoditEditor from 'jodit-react';
 import Form from 'react-bootstrap/Form';
 import previewnew from '../assets/img/previewnew.jpg';
 import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Table from 'react-bootstrap/Table'; 
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

function ProjectMachine() {
    const [fullscreen, setFullscreen] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const steps = ["Step 1", "Step 2", "Step 3"];
    const [show, setShow] = useState(false);
    const [showFirstModal,setShowFirstModal] = useState(false)
    const [Loading, setLoading] = useState(false);
    const [NoticeList, setNoticeList] = useState("");
    const [NoticeSubject, setNoticeSubject] = useState("");
    const [NoticeDescription, setNoticeDescription] = useState("");
    const [NoticeEmail, setNoticeEmail] = useState();
    const [NoticeId, setNoticeId] = useState();
    const [Importance, setImportance] = useState();
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const [NoticeType, setNoticeType] = useState("");
    const [NoticeTypeId, setNoticeTypeId] = useState();
    const [NoticeTypeText, setNoticeTypeText] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageShow, setSelectedImageShow] = useState(null);
    const [selectedImageTwo, setSelectedImageTwo] = useState(null);
    const [selectedImageTwoShow, setSelectedImageTwoShow] = useState(null);
    const [firstSectionButton, setFirstSectionButton] = useState(false);
    const [UserData, setUserData] = useState("");
    const [AddNoticeBtn, setAddNoticeBtn] = useState(false);
    const [UpdateNoticeBtn, setUpdateNoticeBtn] = useState(false);


    const openCreateModal = () => {
        setShow(true);
        setAddNoticeBtn(true);
        setUpdateNoticeBtn(false);
        setNoticeSubject("");
        setNoticeDescription("");
        setNoticeEmail("");
        setImportance("");
        setStartDate("");
        setEndDate("");
        setSelectedImage("");
        setSelectedImageTwo("");
      };

      const openCreateModalagain = () => {
        setShowFirstModal(true);
        setAddNoticeBtn(true);
        setUpdateNoticeBtn(false);
        setNoticeSubject("");
        setNoticeDescription("");
        setNoticeEmail("");
        setImportance("");
        setStartDate("");
        setEndDate("");
        setSelectedImage("");
        setSelectedImageTwo("");
      };

      const editor = useRef(null);
      const [content, setContent] = useState('');
      const handleFileInputChangeTwo = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            const encodedString = btoa(reader.result);
            setSelectedImageTwo(reader.result);
            setSelectedImageTwoShow(encodedString);
          };
          reader.readAsDataURL(file);
        }
      };    

 

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const [state, setState] = React.useState({
        gilad: true,
        jason: false,
        antoine: false,
      });
    
      // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      //   setState({
      //     ...state,
      //     [event.target.name]: event.target.checked,
      //   });
      // };
    
      const { DLF, Magnolias, Bestech ,Kraheja } = state;
      const error = [DLF, Magnolias, Bestech ,Kraheja].filter((v) => v).length !== 2;
    
  return (
    <> 

<div className="row mb-1 mt-5" style={{ alignItems:'center' }}>
        <div className="col-md-4 mt-2 pt-2">
          <div className="HeadingTop">
            <h3> Project  </h3>
            <span> List of Project</span>
          </div>
        </div>
        <div className="col-md-3 offset-md-5 text-right">
          <Button  onClick={() => openCreateModalagain()} className="createClr">
                  {" "}    Project <FontAwesomeIcon icon={faPlus} />
          </Button>{" "} 
        </div>
      </div>
      <div className="row mb-3" style={{ alignItems:'center' }}>
             <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="tableFixHead">
                <div className="scrolling_tbl">
              <Table striped responsive="lg">
          <thead>
            <tr>
              
              <th>Name</th>
              <th>Short Name</th>
              <th>AMC</th>
              <th>Warranty</th>
              <th>Single Visit</th>
              <th>AMC Machine Expiry (Current Month)</th>
              <th>2nd Visit (Current Month)</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
          
              <td ><Link to={"/Approval"}> Aditya City Apartments  </Link></td>
              <td > AAKARSHAN </td>
              <td >19</td>
              <td>20</td>
              <td>93</td>
              <td>39</td>
              <td>82</td>
              <td><FontAwesomeIcon className="editNotice" icon={faEdit} /><FontAwesomeIcon className="editNotice" icon={faTrash} />
                    <Link to={"/Management"}></Link>
              </td>      
              {/* <td><FontAwesomeIcon className="editNotice" icon={faAdd} /><FontAwesomeIcon className="editNotice" icon={faTrash} /></td> */}
              
            </tr>
            <tr>
          
            <td ><Link to={"/Approval"}> Aditya City Apartments  </Link></td>
              <td > AAKARSHAN </td>
              <td >19</td>
              <td>20</td>
              <td>93</td>
              <td>39</td>
              <td>82</td>
              <td><FontAwesomeIcon className="editNotice" icon={faEdit} /><FontAwesomeIcon className="editNotice" icon={faTrash} />
                    <Link to={"/Management"}></Link>
              </td>      
            </tr>
            <tr>
          
            <td > <Link to={"/Approval"}> Shipra City Apartments </Link> </td>
              <td > AAKARSHAN </td>
              <td >19</td>
              <td>20</td>
              <td>93</td>
              <td>39</td>
              <td>82</td>
              <td><FontAwesomeIcon className="editNotice" icon={faEdit} /><FontAwesomeIcon className="editNotice" icon={faTrash} />
                    <Link to={"/Management"}></Link>
              </td>      
            </tr>
            <tr>
          
            <td > <Link to={"/Approval"}> Sharma City Apartments</Link>  </td>
              <td > AAKARSHAN </td>
              <td >19</td>
              <td>20</td>
              <td>93</td>
              <td>39</td>
              <td>82</td>
              <td><FontAwesomeIcon className="editNotice" icon={faEdit} /><FontAwesomeIcon className="editNotice" icon={faTrash} />
                    <Link to={"/Management"}></Link>
              </td>      
            </tr>
            <tr>
          
            <td ><Link to={"/Approval"}>  Shreya City Apartments</Link>  </td>
              <td > AAKARSHAN </td>
              <td >19</td>
              <td>20</td>
              <td>93</td>
              <td>39</td>
              <td>82</td>
              <td><FontAwesomeIcon className="editNotice" icon={faEdit} /><FontAwesomeIcon className="editNotice" icon={faTrash} />
                    <Link to={"/Management"}></Link>
              </td>      
          </tr>
          
         
          </tbody>

        </Table>
        
        </div>
        </div>
      
             </div>
        </div>
 

      <Modal size="md" show={showFirstModal} onHide={() =>  setShowFirstModal(false)} className="modal-100w modal-md clientmodal right text-end p-0" aria-labelledby="contained-modal-title-vcenter ">
                        <Modal.Header closeButton className='headerMDL' style={{backgroundColor: "#2e333f"}}>
                            <Modal.Title id="contained-modal-title-vcenter" onClick={openCreateModal}>
                                Add Project
                                
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pt-0">

              <div className="container" style={{height: "77vh"}}>
                       <div className="card-no-border">
                      <div className="row" id="validation">
                    <div className="col-12">
                        <div className="card wizard-content pt-0">
                            <div className="card-body pt-0">
                         
                                <form action="#" className="validation-wizard wizard-circle wizard clearfix" role="application" id="steps-uid-2" novalidate="novalidate">
                                    
                                        <div className="content clearfix">
                                     
                                    
                                    <section id="steps-uid-2-p-0" role="tabpanel" aria-labelledby="steps-uid-2-h-0" className="body current pt-0" aria-hidden="false">
                                        <div className="row text-left">
                                            <div className="col-md-12 mb-2 d-flex">
                                                <div className="form-group">
                                                    <label for="wfirstName2"> Project Name  <span className="danger">*</span> </label>
                                                    <input type="text"   className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> </div>
                                                <div className="form-group">
                                                  <label for="wlastName2"> Project Short Name  <span className="danger">*</span> </label>
                                                  <input type="text"   className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> </div>
                                            </div>
                                            <div className="col-md-12">
                                                
                                                
                                                    
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="wlastName2"> Description  <span className="danger">*</span> </label>
                                                    <input type="text"   className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> </div>
                                                
                                                    
                                            </div>
                                            <div className="col-md-12 d-flex mt-2">
                                                <div className="form-group col-6 p-0">
                                                    <label for="wlastName2"> Client List  <span className="danger">*</span> </label>
                                                    <select name="cars" id="cars" className="form-control required">
                                                      <option value="volvo">Select Project</option>
                                                      <option value="volvo">Client 1</option>
                                                      <option value="saab">Client 2</option>
                                                      <option value="mercedes">Client 3</option>
                                                      <option value="audi">Client 4</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-6 p-0">
                                                  <label for="wlastName2"> GST number  <span className="danger">*</span> </label>
                                                  <input type="text"   className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> </div>
                                                
                                                    
                                            </div>
                                            <div className="col-md-12">
                                                <h4 for="wlastName2" className="mt-3"> Address  </h4>
                                                <div className="form-group">
                                                  <label for="wlastName2"> Address  <span className="danger">*</span> </label>
                                                  <input type="text"   className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> 
                                                </div>
                                                <div className="d-flex">
                                                  <div className="form-group">
                                                    <label for="wlastName2"> State  <span className="danger">*</span> </label>
                                                    <input type="text"   className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> 
                                                  </div>
                                                  <div className="form-group">
                                                    <label for="wlastName2"> Pincode  <span className="danger">*</span> </label>
                                                    <input type="text"   className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> 
                                                  </div>
                                                </div>  
                                                
                                                    
                                            </div>
                                            <div className="col-md-12">
                                              <h4 for="wlastName2" className="mt-3"> Billing Address</h4>
                                                <div className="form-group">
                                                  <label for="wlastName2"> Address  <span className="danger">*</span> </label>
                                                  <input type="text"   className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> 
                                                </div>
                                                <div className="d-flex">
                                                  <div className="form-group">
                                                    <label for="wlastName2"> State  <span className="danger">*</span> </label>
                                                    <input type="text"   className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> 
                                                  </div>
                                                  <div className="form-group">
                                                    <label for="wlastName2"> Pincode  <span className="danger">*</span> </label>
                                                    <input type="text"   className="form-control required" id="wfirstName2" name="firstName" aria-required="true" /> 
                                                  </div>
                                                </div>  
                                                
                                                    
                                            </div>
                                            

                                        </div>  
                                    </section>
 
                                </div>
                                
                                <div className="actions clearfix mt-3">
                                    <ul role="menu" aria-label="Pagination">
                                        
                                        
                                            <div className="  align-self-start">
                                                <button type="button" className="btn border bg-white" onClick={() => setShow(false)}> Cancel</button>
                                                <button type="button" className="btn btn-info"> Add</button>
                                            </div>
                                            
                                    </ul>
                                    </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
              </div>
        </Modal.Body>
      </Modal>
  
</>
  );
}
export default ProjectMachine;
