import React , {useEffect, useRef, useState} from 'react'; 
import "../assets/css/custom.css";
import "../assets/css/client.css";
import "../assets/css/management.css";
import "../assets/css/font-awesome.css";
import "../assets/css/sweetalert.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/steps.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlus,
  faSort,
  faTrash,
  faPenToSquare,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import clientlogo from '../assets/img/user1.jpg'; 
import clientlogo2 from '../assets/img/user2.jpg'; 
import clientlogo3 from '../assets/img/user3.jpg'; 
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import ReactDOM from "react-dom";
// import { Wizard, Steps, Step, WithWizard } from "react-multistep-wizard";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { Stepper, Step, StepLabel } from '@mui/material';
import { Textarea } from '@material-tailwind/react';
import { Circle } from '@mui/icons-material';
import {commonAPI} from "../services/CommonAPIService"
import {ErrorSource} from '../common/constants'
import Menus from "./menus";
import { ToastContainer, toast } from 'react-toastify';
import JoditEditor from 'jodit-react';
import noticeuserupload from '../assets/img/noticeuserupload.png';
import previewnew from '../assets/img/previewnew.jpg';
import stopMark from '../assets/img/stop.png';


  function Noticemanagement() {
    const [fullscreen, setFullscreen] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Step 1', 'Step 2', 'Step 3'];
    const [show, setShow] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [NoticeList, setNoticeList] = useState("");
    const [NoticeSubject, setNoticeSubject] = useState("");
    const [NoticeDescription, setNoticeDescription] = useState("");
    const [NoticeEmail, setNoticeEmail] = useState();
    const [NoticeId, setNoticeId] = useState();
    const [Importance, setImportance] = useState("High");
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const [NoticeType, setNoticeType] = useState("");
    const [NoticeTypeId, setNoticeTypeId] = useState([]);
    const [NoticeTypeText, setNoticeTypeText] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageShow, setSelectedImageShow] = useState(null);
    const [selectedImageTwo,setSelectedImageTwo] = useState(null)
    const [selectedImageTwoShow,setSelectedImageTwoShow] = useState(null)
    const [firstSectionButton,setFirstSectionButton] = useState(false);
    const [UserData, setUserData] = useState("");
    const [AddNoticeBtn, setAddNoticeBtn] = useState(false);
    const [UpdateNoticeBtn, setUpdateNoticeBtn] = useState(false);
    const [UploadBtn, setUploadBtn] = useState(false);
    const [UploadImageBtn, setUploadImageBtn] = useState(true);
    const [NoticeImages, setNoticeImages] = useState("");
    const [NoticeAddId, setNoticeAddId] = useState("");
    const [DeleteShow, setDeleteShow] = useState(false);
    const [DeleteShowImg, setDeleteShowImg] = useState(false);
    const [DeleteItem, setDeleteItem] = useState("");
    const [NoticeShowID, setNoticeShowID] = useState("");
    const [NoticeShow, setNoticeShow] = useState(false);
    const [NoticeUpdateShow, setNoticeUpdateShow] = useState(false);
    const editor = useRef(null);
    const [content, setContent] = useState('');
   console.log("content", content)

    const setInputValue = (item) => {
        setShow(true)
        setAddNoticeBtn(false)
        setUpdateNoticeBtn(true)
        setUploadImageBtn(false)
        console.log("item is", item, convertISSet(item?.effectiveStartDt))
        setNoticeId(item?.noticeId)
        setNoticeShowID(item?.noticeId)
        setNoticeShow(false)
        setNoticeUpdateShow(false)
        setNoticeSubject(item?.noticeSubject)
        setContent(item.noticeDescription)
        setNoticeTypeId([item.noticeTypeId, item.noticeTypeText])
        setImportance(item.importance)
        setStartDate(convertISSet(item?.effectiveStartDt))
        setEndDate(convertISSet(item?.effectiveEndDt))
        setNoticeAddId(item?.noticeId)
        // GetNoticeImages();
        // var decodedString1 = atob(item?.image1);
        // setSelectedImage(decodedString1)
        setSelectedImageShow(item.image1)
        // var decodedString2 = atob(item?.image2);
        // setSelectedImageTwo(decodedString2)
        setSelectedImageTwoShow(item.image2)
    };

    useEffect(() => {
        if(NoticeAddId){
            GetNoticeImages();
        }
    }, [NoticeAddId])
   
    const openCreateModal = () => {
        setShow(true)
        setAddNoticeBtn(true)
        setUpdateNoticeBtn(false)
        setUploadImageBtn(true)
        setNoticeSubject("")
        setNoticeDescription("")
        setNoticeEmail("")
        setNoticeShow(false)
        setNoticeUpdateShow(false)
        // setNoticeTypeId([item.noticeTypeId, item.noticeTypeText])
        setImportance("High")
        setStartDate("")
        setEndDate("")
        setSelectedImage("")
        setSelectedImageTwo("");
        setNoticeImages("")
        setContent("")
    }
    function ImageUpload() {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    
    if (file) {
      const reader = new FileReader();
      var base64result = reader.result.substr(reader.result.indexOf(',') + 1);
      reader.onload = () => {
        setSelectedImage(base64result);
      };
      console.log("base64", base64result)
      reader.readAsDataURL(file);
    }
  };}

    const convertISTTime = (date) => {
        if(date != null || date != "" || date != undefined ){
            const [datePart, TimePart] = date.split(" ");
            const [mm, dd, yyyy ] = datePart.split("/");
            // const [hh, mn, ss] = TimePart.split(':')
            const formatedTime = `${dd}-${mm}-${yyyy}`;
            return formatedTime;
        }else{
            console.warn("input date string may be null, empty string or undefined");
        }
    }

    const convertISSet = (date) => {
        if(date != null || date != "" || date != undefined ){
            let [datePart, TimePart] = date.split(" ");
            let [mm, dd, yyyy ] = datePart.split("/");
            // const [hh, mn, ss] = TimePart.split(':')
            // if(mm<10){mm.padStart(2, "0")};
            // if(dd<10){dd.padStart(2, "0")};
            if(mm<10){
               mm = "0"+mm
            }
            if(dd<10){
               dd = "0"+dd
            }
            const formatedTime = `${yyyy}-${mm}-${dd}`;
            return formatedTime;
        }else{
            console.warn("input date string may be null, empty string or undefined");
        }
    }

    const convertTimePost = (date) => {
        if (date != null || date != "" || date != undefined) {
          const [datePart, TimePart] = date.split(" ");
          const [yyyy, mm, dd] = datePart.split("-");
          // const [hh, mn, ss] = TimePart.split(':')
          const formatedTime = `${mm}/${dd}/${yyyy}`;
          return formatedTime;
        } else {
          console.warn("input date string may be null, empty string or undefined");
        }
    };

//   const endPointVariableKey    = {
//     urlVariable:null,
//     buildingId: 33

//   }
    const GetNoticeList = async () => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        setUserData(myUserData);
        const endPointVariableKey    = {
            urlVariable:null,
            buildingId: myUserData?.buildingId
        }
        const bodydata = { 
            "noticeId": 0,
            "currentStatus": null,
            "validUpto": null
          };
        try {
            setLoading(true);
            let response = await commonAPI("Notice", bodydata, endPointVariableKey)
            if (response?.success === true){
                console.log("NoticeList", response)
                setNoticeList(response?.data);
                setLoading(false);
            }else{
                setLoading(false);
                toast.error("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber, // Need to be change
                "requestJSON": bodydata,
                "uri": "/api/Master/Notice",
                "errorNumber": "NL01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "GetNoticeList",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true && response?.message?.length == 0){
                toast.error("Something went wrong ");
                setLoading(false); 
            }else{
                toast.error("Something went wrong ");
                setLoading(false); 
            }
        }

    }
    
    useEffect(() => {
        if(selectedImageTwo){
            setUploadBtn(true);
        }else{
            setUploadBtn(false);
        }
    }, [selectedImageTwo])

    useEffect(() => {
        GetNoticeList();
        GetNoticeType();
    }, [])

    const GetNoticeType = async () => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        const endPointVariableKey    = {
            urlVariable:null,
            buildingId: myUserData?.buildingId
        }
        try {
            setLoading(true);
            let response = await commonAPI("NoticeType", undefined, endPointVariableKey)
            if (response?.success===true && response?.message?.length == 0){
                console.log("Notice Type", response)
                setNoticeType(response?.data)
                setNoticeTypeId([response?.data[0]?.noticeTypeId, response?.data[0]?.noticeTypeText])
                setLoading(false);
            }else{
                setLoading(false);
                toast.error("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber, // Need to be change
                "requestJSON": null,
                "uri": "/api/Master/NoticeType",
                "errorNumber": "NT01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "GetNoticeType",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true && response?.message?.length == 0){
                toast.error("Something went wrong ");
                setLoading(false); 
            }else{
                toast.error("Something went wrong ");
                setLoading(false); 
            }
        }

    }

    

    const AddNoticeData = async () => {
        const bodydata = { 
            "noticeSubject": NoticeSubject,
            "noticeDescription": content,
            "noticeTypeId": NoticeTypeId[0],
            "noticeTypeText": NoticeTypeId[1],
            "importance": Importance,
            "effectiveStartDt": convertTimePost(StartDate),
            "effectiveEndDt": convertTimePost(EndDate),
            "createdUserId": JSON.stringify(UserData?.userId), // Need to be change
          };
          
        try {
            setLoading(true);
            if(!NoticeTypeId){
                toast.warning("Please select Notice Type");  
                setLoading(false);
            }else if(!Importance){
                toast.warning("Please select Importance");  
                setLoading(false);
            }else if(!StartDate){
                toast.warning("Please select Effective Start Date");  
                setLoading(false);
            }else if(!EndDate){
                toast.warning("Please select Effective End Date");  
                setLoading(false);
            }else if(!NoticeSubject){
                toast.warning("Please enter Notice Subject");  
                setLoading(false);
            }else if(!content){
                toast.warning("Please enter Notice Description");  
                setLoading(false);
            }else{
                const endPointVariableKey    = {
                    urlVariable:null,
                    buildingId: UserData?.buildingId
                
                }
                console.log("body", bodydata);
                let response = await commonAPI("NoticeAdd", bodydata, endPointVariableKey)
                if(response?.success === true && response?.message.length == 0){
                    console.log("NoticeAddApi", response)
                    toast.success("Notice Added");  
                    setLoading(false);
                    setNoticeShowID(response?.data?.noticeId)
                    setUploadImageBtn(false);
                    setNoticeAddId(response?.data?.noticeId)
                    GetNoticeList();
                    setNoticeShow(true);
                }else{
                    setLoading(false);
                    toast.error(response?.message[0]);
                }
            }    
            
        } catch (error) {
            setLoading(false);
            const bodyError = { 
                "userId": UserData.userId,
                "mobileNumber": UserData.mobileNumber, // Need to be change
                "requestJSON": bodydata,
                "uri": "/api/Master/Notice",
                "errorNumber": "AN01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "AddNoticeData",
            }
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true & response?.message.length == 0){
                toast.error("Something went wrong");
                setLoading(false); 
            }else{
                toast.error("Something went wrong");
                setLoading(false); 
            }
        }
        
          
          
    }

    const AddNoticeImage = async () => {
        const bodydata = { 
            "modifiedUserId": JSON.stringify(UserData?.userId),
            "noticeId": NoticeAddId,
            "docImage": selectedImageTwoShow
          };
          
        try {
            setLoading(true);
            const endPointVariableKey    = {
                urlVariable:null,
                buildingId: UserData?.buildingId
            
            }
            if(!selectedImageTwo){
                toast.warning("Please upload the image");
            }else{
                console.log("body", bodydata);
                let response = await commonAPI("NoticeImagesAdd", bodydata, endPointVariableKey)
                if(response?.success === true && response?.message.length == 0){
                    console.log("Notice Image Api", response)
                    toast.success("Image Added");  
                    setLoading(false);
                    setUploadBtn(true);
                    GetNoticeImages();
                    setSelectedImageTwo("");
                }else{
                    setLoading(false);
                    toast.error(response?.message[0]);
                }
            }
        } catch (error) {
            setLoading(false);
            const bodyError = { 
                "userId": UserData.userId,
                "mobileNumber": UserData.mobileNumber, // Need to be change
                "requestJSON": bodydata,
                "uri": "/api/Master/NoticeImages",
                "errorNumber": "NI01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "AddNoticeImage",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true & response?.message.length == 0){
                toast.error("Something went wrong");
                setLoading(false); 
            }else{
                toast.error("Something went wrong");
                setLoading(false); 
            }
        }
        
          
          
    }

    const GetNoticeImages = async () => {
        const endPointVariableKey    = {
            urlVariable: NoticeAddId,
            buildingId: UserData?.buildingId
        }
        try {
            setLoading(true);
            let response = await commonAPI("NoticeImages", undefined, endPointVariableKey)
            if (response?.success===true && response?.message?.length == 0){
                console.log("Notice Get Images", response)
                setNoticeImages(response?.data)
                setLoading(false);
            }else{
                setLoading(false);
                toast.error("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": UserData?.userId,
                "mobileNumber": UserData?.mobileNumber, // Need to be change
                "requestJSON": null,
                "uri": "/api/Master/Notice/Images",
                "errorNumber": "NI01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "GetNoticeImages",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true && response?.message?.length == 0){
                toast.error("Something went wrong ");
                setLoading(false); 
            }else{
                toast.error("Something went wrong ");
                setLoading(false); 
            }
        }

    }

    const ShowDeleteImgModal = (item) => {
        console.log("setDeleteShowImg", item)
        setDeleteShowImg(true);
        setDeleteItem(item)

    }

    const DeleteNoticeImages = async () => {
        const endPointVariableKey    = {
            urlVariable: null,
            buildingId: UserData?.buildingId
        }
        console.log("delete image item", DeleteItem)
        const bodydata = { 
            "modifiedUserId": JSON.stringify(UserData?.userId),
            "noticeDocId": DeleteItem.noticeDocId,
            "noticeId": DeleteItem.noticeId,
            "docImage": DeleteItem.docImage
        };
        try {
            console.log("delete image body", bodydata)
            setLoading(true);
            let response = await commonAPI("NoticeImagesDelete", bodydata, endPointVariableKey)
            if (response?.success===true && response?.message?.length == 0){
                console.log("Notice Get Images", response)
                toast.success("Images Deleted");
                setLoading(false);
                GetNoticeImages();
                setDeleteShowImg(false);
            }else{
                setLoading(false);
                toast.error(response.message[0]);
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = {
                "mobileNumber": UserData?.mobileNumber, // Need to be change
                "requestJSON": null,
                "uri": "/api/Master/Notice/Images",
                "errorNumber": "NI01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "GetNoticeImages",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true && response?.message?.length == 0){
                toast.error("Something went wrong");
                setLoading(false); 
            }else{
                toast.error("Something went wrong");
                setLoading(false); 
            }
        }

    }

    const UpdateNoticeData = async () => {
        const bodydata = { 
            "noticeId" : NoticeId,
            "noticeSubject": NoticeSubject,
            "noticeDescription": content,
            "noticeTypeId": NoticeTypeId[0],
            "noticeTypeText": NoticeTypeId[1],
            "importance": Importance,
            "effectiveStartDt": convertTimePost(StartDate),
            "effectiveEndDt": convertTimePost(EndDate),
            "modifiedUserId": JSON.stringify(UserData?.userId), // Need to be change
          };
        try {
            console.log("body", bodydata);
            setLoading(true);
            if(!NoticeTypeId.length){
                toast.warning("Please select Notice Type");  
                setLoading(false);
            }else if(!Importance){
                toast.warning("Please select Importance");  
                setLoading(false);
            }else if(!StartDate){
                toast.warning("Please select Effective Start Date");  
                setLoading(false);
            }else if(!EndDate){
                toast.warning("Please select Effective End Date");  
                setLoading(false);
            }else if(!NoticeSubject){
                toast.warning("Please enter Notice Subject");  
                setLoading(false);
            }else if(!content){
                toast.warning("Please enter Notice Description");  
                setLoading(false);
            }else{

                const auth = localStorage.getItem("userData");
                const myUserData = JSON.parse(auth);
                // setUserData(myUserData);
                // setLoading(true);
                const endPointVariableKey    = {
                    urlVariable:null,
                    buildingId: myUserData?.buildingId
                
                }
                let response = await commonAPI("NoticeUpdate", bodydata, endPointVariableKey)
                // let response = await fetch(`https://api-test-next.curo.in/api/Notice/Notice`, {
                //     // let response = await fetch(`${apiURL}/DAR/DARReportToHOD`, {  
                //       method: "PUT",
                //       headers: { 'Content-Type': 'application/json', "buildingId": myUserData.buildingId },
                //       body: bodydata
                    
                //   })
                //   const statusCode = response
                //   response = await response.json();
                //   setLoading(true);
                // console.log("statusCode", response)
                if(response.success === true && response.message.length == 0){
                    toast.success("Notice Updated");  
                    // setShow(false)
                    GetNoticeList();
                    setLoading(false);
                    setNoticeUpdateShow(true);
                }else{
                    toast.error(response.message[0]);
                    setLoading(false);
                }
            }    
            
        } catch (error) {
            setLoading(false);
            const bodyError = { 
                "userId": UserData.userId,
                "mobileNumber": UserData.mobileNumber, // Need to be change
                "requestJSON": bodydata,
                "uri": "/api/Master/Notice",
                "errorNumber": "NU01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "UpdateNoticeData",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true && response?.message.length == 0){
                toast.error("Something went wrong");
                setLoading(false); 
            }else{
                toast.error("Something went wrong");
                setLoading(false); 
            }
        }
          
    }

    const ShowDeleteModal = (item) => {
        setDeleteShow(true);
        setNoticeId(item)

    }
    const DeleteNotice = async () => {
        const bodydata = { 
            "noticeId": NoticeId.noticeId,
            "modifiedUserId": JSON.stringify(UserData.userId), // Need to be replaced
          };
        try {
            setLoading(true);
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            // setUserData(myUserData);
            // setLoading(true);
            const endPointVariableKey    = {
                urlVariable:null,
                buildingId: myUserData?.buildingId
            
            }
              console.log("body", bodydata);
              let response = await commonAPI("NoticeDelete", bodydata, endPointVariableKey)
            //   const response = await fetch(`http://api-test.curonext.com/api/Master/Building/`, {
            //     // let response = await fetch(`${apiURL}/DAR/DARReportToHOD`, {  
            //       method: "DELETE",
            //       headers: { 'Content-Type': 'application/json'},
            //       body: bodydata
                  
            //   })
            //   const statusCode = response
          
              console.log("statusCode", response)
              if(response?.success == true & response?.message.length == 0){
                toast.success("Notice Deleted"); 
                setDeleteShow(false)
                GetNoticeList(); 
                setLoading(false);
              }else{
                toast.error(response.message[0]);
                setLoading(false);
              }
        } catch (error) {
            setLoading(false);
            const bodyError = { 
                "userId": UserData.userId,
                "mobileNumber": UserData.mobileNumber, // Need to be change
                "requestJSON": bodydata,
                "uri": "/api/Master/Notice",
                "errorNumber": "ND01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "DeleteNotice",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response.success === true && response.message.length == 0){
                toast.error("Something went wrong");
                setLoading(false); 
            }else{
                toast.error("Something went wrong");
                setLoading(false); 
            }
            
        }
          
    }
  
    const handleNext = () => {
       
        setActiveStep((prevActiveStep) => {
            if (prevActiveStep >= steps.length - 1) { // Assuming 'steps' is your array of step labels
                setFirstSectionButton(false)
                console.log(prevActiveStep)
                return 0; // Reset to first step
            } else {
                // console.log(inpRef.current.value)
                setFirstSectionButton(true)
                console.log(prevActiveStep)
                 return prevActiveStep + 1;
                
            }
        });
    };
  
    const handleBack = () => {
        
        setFirstSectionButton(false)
      setActiveStep((prevActiveStep) => {
        if(prevActiveStep==1){
            setFirstSectionButton(false)
            console.log(prevActiveStep)
            return prevActiveStep - 1
        }
        else{
            setFirstSectionButton(true)
            return prevActiveStep - 1
        }
       
    });
    };

    



    const [file, setFile] = useState();
    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    // console.log("img data",AltSPOCEmail)


    const getStepContent = (stepIndex) => {
        console.log(stepIndex)
        const handleFileInputChange = (event) => {
            const file = event.target.files[0];
            if (file) {
              const reader = new FileReader();
              var base64result = reader.result;
              reader.onload = () => {
                const encodedString = btoa(reader.result);
                console.log(encodedString);
                setSelectedImage(reader.result);
                setSelectedImageShow(encodedString)
                console.log("base64", reader.result)
              };
              
              reader.readAsDataURL(file);
            }
          };
          const handleFileInputChangeTwo = (event) => {
            const file = event.target.files[0];
            if (file) {
              const reader = new FileReader();
              reader.onload = () => {
                const encodedString = btoa(reader.result);
                setSelectedImageTwo(reader.result);
                setSelectedImageTwoShow(encodedString)
              };
              reader.readAsDataURL(file);
            }
          };
      switch (stepIndex) {
        case 0:
            return <div><section id="steps-uid-0-p-0" role="tabpanel" aria-labelledby="steps-uid-0-h-0" className="body current" aria-hidden="false">
            <div className="row mt-5">
            
                <div className="col-md-6">
                
                    <div className="form-group">
                        <label for="wfirstName2">  Notice Subject : <span className="danger">*</span> </label>
                        <input type="text" value={NoticeSubject} onChange={(e) => {setNoticeSubject(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
                         </div>
                        <br/>
                         <div className="form-group">
                      <label for="jobTitle2">Notice Type :</label>

                    <br/>

                    <Form.Select size="md" className="sltareamodal" onChange={(e) => {
                        console.log("selection", e.target.value)
                        setNoticeTypeId(e.target.value);
                    }
                        }>
                        <option value="Select">Select</option>
                        {NoticeType ? NoticeType.map((item, index) =>  <option key={index} value={item.noticeTypeId}>{item.noticeTypeText}</option>): null}
                    </Form.Select>
                    {/* <Form.Select size="md" className="sltareamodal" >
                        <option>General</option>
                        <option>Maintainance</option>
                        <option>Security</option>
                        <option>Renovation</option>
                        <option>Health & Security</option>
                        <option>Alert</option>
                        <option>Warning</option>
                        <option>Information</option>
                        <option>Emergency</option>
                        <option>Meeting</option>
                        <option>Invitation</option>
                    </Form.Select> */}





                      {/* <input type="text" value={AltEmail} onChange={(e) => setAltEmail(e.target.value)} className="form-control required" id="jobTitle2" aria-required="true" /> */}
                  </div>
                  {
                    !firstSectionButton && 
                    <div className='btn_trigger'>
                        <button value="back" className="back_btn btn btn-primary" disabled={activeStep === 0} onClick={handleBack}> Back </button>
                        <button value="next" className="back_btn btn btn-primary" onClick={handleNext}> Next </button>
                    </div>
                }
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label for="wlastName2"> Notice Description : <span className="danger">*</span> </label>
                    <Textarea cols={37} rows={8} value={NoticeDescription} onChange={(e) => {setNoticeDescription(e.target.value);}} className='txtareamodal'> </Textarea>
                        {/* <input type="text" value={ShortName} className="form-control required" onChange={(e) => {setShortName(e.target.value);}} id="wlastName2" name="lastName" aria-required="true" />*/} </div> 
                </div>
                {/* <div className="col-md-4">
                    <div className="form-group">
                        <label for="wlastName2"> Website : <span className="danger">*</span> </label>
                        <input type="text" value={Website} onChange={(e) => setWebsite(e.target.value)} className="form-control required" id="wlastName2" name="lastName" aria-required="true" /> </div>
                </div> */}
            </div>
            {/* <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label for="wemailAddress2"> Phone Number : <span className="danger">*</span> </label>
                        <input type="email" value={PhoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="form-control required" id="wemailAddress2" name="emailAddress" aria-required="true" /> </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label for="wphoneNumber2">Alt Phone Number :</label>
                        <input type="tel" value={AltPhoneNumber} onChange={(e) => setAltPhoneNumber(e.target.value)} className="form-control" id="wphoneNumber2" /> </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label for="wlastName2"> Email : <span className="danger">*</span> </label>
                        <input type="text" value={Email} onChange={(e) => setEmail(e.target.value)} className="form-control required" id="wlastName2" name="lastName" aria-required="true" /> </div>
                </div>
            </div> */}
            
            </section></div>
            setFirstSectionButton(false)
        case 1:
          return <div><section id="steps-uid-0-p-1" role="tabpanel" aria-labelledby="steps-uid-0-h-1" className="body" aria-hidden="true">
          <div className="row mt-5">
 

              <div className="col-md-6">
                      <div className="form-group">
                          <label for="jobTitle2">Image 1:</label>
                          <input type="file" onChange={handleFileInputChange} className="form-control required" id="jobTitle2" aria-required="true" />
                            <div className='img_uploaded'>
                                {selectedImage && <img src={selectedImage} alt="Selected Image" />}
                            </div>
                      </div>
              </div>
                  <div className="col-md-6">
                      <div className="form-group">
                          <label for="webUrl3">Image 2 :</label>
                          <input type="file" onChange={handleFileInputChangeTwo} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
                          <div className='img_uploaded'>
                             {selectedImageTwo && <img src={selectedImageTwo} alt="Selected Image" />}
                        </div>
                          </div>
                      </div>
              {/* <div className="col-md-4">
                  <div className="form-group">
                      <label for="webUrl3">SPOC Mobile Number :</label>
                      <input type="url" value={SpocMobileNumber} onChange={(e) => setSpocMobileNumber(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
              </div> */}
              </div>
              {/* <div className="row">
              <div className="col-md-6">
                  <div className="form-group">
                      <label for="jobTitle2">Image 1:</label>
                      <input type="text" value={SpocEmail} onChange={(e) => setSpocEmail(e.target.value)} className="form-control required" id="jobTitle2" aria-required="true" />
                  </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group">
                      <label for="webUrl3">Image 2 :</label>
                      <input type="url" value={AltSPOCName} onChange={(e) => setAltSPOCName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
              </div>
              <div className="col-md-4">
                  <div className="form-group">
                      <label for="webUrl3">Alt SPOC Mobile Number:</label>
                      <input type="url" value={AltSPOCMobileNumber} onChange={(e) => setAltSPOCMobileNumber(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
              </div>
              </div>  */}
          </section></div>;
        case 2:
          return <div>  
          <section id="steps-uid-0-p-2" role="tabpanel" aria-labelledby="steps-uid-0-h-2" className="body" aria-hidden="true">
              <div className="row mt-5">

              <div className="col-md-6">
                  <div className="form-group">
                      <label for="webUrl3">Notify On Email :</label>
                      <Form.Select size="md" className="sltareamodal" value={NoticeEmail} onChange={(e) => {setNoticeEmail(e.target.value);}}>
                        <option value="Select">Select</option>
                        <option value={"Yes"}> Yes </option>
                        <option value={"No"}> No</option> 
 
                    </Form.Select>
                       </div>
              </div>
 
              <div className="col-md-6">
                        <div className="form-group">
                            <label for="jobTitle2">Importance:</label>

                            <br/>


                        <Form.Select size="md" className="sltareamodal" value={Importance} onChange={(e) => {setImportance(e.target.value);}}>
                            <option value="Select">Select</option>
                            <option value={"High"}> High </option>
                            <option value={"Medium"}> Medium</option>
                            <option value={"Low"}> Low</option>
                    
                        </Form.Select>
                            {/* <input type="text" value={PinCode} onChange={(e) => setPinCode(e.target.value)} className="form-control required" id="jobTitle2" aria-required="true" /> */}
                        </div>
                    </div>
                    </div>
                    <br/>
                    <div className='row'>
                  
                                        <div className="col-md-6">
                        <div className="form-group">
                            <label for="webUrl3">Effective Start Date :</label>
                            <input type="date" value={StartDate} onChange={(e) => setStartDate(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
                    </div>
 
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="webUrl3"> Effective End Date:</label>
                            <input type="date" value={EndDate} onChange={(e) => setEndDate(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
                        </div>

                  {/* <div className="col-md-4">
                      <div className="form-group">
                      <label for="webUrl3">Address Line 2:</label>
                      <input type="url" value={AddressLine2} onChange={(e) => setAddressLine2(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
                      </div> */}
                  </div> 
      
                  {/* <div className="row">
                      <div className="col-md-4">
                          <div className="form-group">
                              <label for="jobTitle2">City:</label>
                              <input type="text" value={City} onChange={(e) => setCity(e.target.value)} className="form-control required" id="jobTitle2" aria-required="true" />
                          </div>
                      </div>
                  <div className="col-md-4">
                      <div className="form-group">
                          <label for="webUrl3">State:</label>
                          <input type="url" value={State} onChange={(e) => setState(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
                      </div>
                  <div className="col-md-4">
                      <div className="form-group">
                          <label for="webUrl3">Country:</label>
                          <input type="url" value={Country} onChange={(e) => setCountry(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
                      </div>
                  </div>  */}
                  
          </section>
          
          </div>;
        default:
          return 'Unknown step';
      }
    };
  
    const handleFileInputChangeTwo = (event) => {
        const file = event.target.files[0];
        if (file) {
        const reader = new FileReader();
        reader.onload = () => {
            // atob(item?.logo)
            const encodedString = btoa(reader.result);
            console.log("btoa", encodedString);
            setSelectedImageTwo(reader.result);
            setSelectedImageTwoShow(encodedString);
        };
        reader.readAsDataURL(file);
        }
    };

     return (
    <>
    {Loading? 
        <div className="loaderDiv">
            <div className="loader"></div>
        </div> 
    :null}
    <ToastContainer />
    {/* <Menus /> */}
    <div className="row mt-5 pt-2" style={{alignItem:'center'}}>
    <div className="col-md-4">
        <div className="HeadingTop">
            <h3> Notice Management </h3>
            <span> List of Curo Solution Notices</span>
            </div>
            </div>
            <div className="col-md-3 offset-md-5 text-right" style={{alignContent:'center'}}>
                  <Button onClick={openCreateModal} className="createClr">
            {" "}
            Create New +
          </Button>{" "}
                          <br/>
          <div className="flex_ptrn">
             <div className="clrptrn">
               <div class="red"> </div><span> High </span>
             </div>
             <div className="clrptrn">
               <div class="orange"> </div><span> Medium  </span>
             </div>
             <div className="clrptrn">
               <div class="green"> </div><span> Low </span>
             </div>            
          </div>
                </div>
</div>
    <section id="client-builder">
        <div className="container-fluid">
            {/* <div className="row page-titles">
                <div className="col-md-12 align-self-end text-right">
                     <button type="button" className="btn btn-info" onClick={() => openCreateModal()}><FontAwesomeIcon icon={faPlus} /> Create New</button>
                </div>
            </div> */}
              

              <Modal scrollable
                fullscreen={fullscreen}
                show={show}
                onHide={() =>
                setShow(false)}
                className="modal-100w clientmodal"
                class="modal-dialog modal-fullscreen"
                >
                <Modal.Header className="headerMDL" closeButton>
                    <Modal.Title onClick={openCreateModal}>Notice Management</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-3'>
                    {!NoticeShow? <></>
                        :<h6 className='text-success'>Notice created successfully. Notice Id: {NoticeShowID}. You can now add images to the notice.</h6>}
                    {!NoticeUpdateShow? <></>
                        :<h6 className='text-success'>Notice Id: {NoticeShowID}</h6>}
                    <div className="row" id="validation">
                        <div className="col-12">
                            <form
                            action="#"
                            className="validation-wizard wizard-circle wizard clearfix"
                            role="application"
                            id="steps-uid-0"
                            novalidate="novalidate"
                            >
                            <div>
                                <div className="row">
                                    <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                                        <div className="row">
                                        <div class="col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                                            <div className="form-group">
                                                <label for="wfirstName2">
                                                {" "}
                                                Notice Type {" "}
                                                <span className="danger">*</span>{" "}
                                                </label>
                                                <select size="md" className="sltareamodal col-12 ml-auto" value={NoticeTypeId} onChange={(e) => {console.log(e.target.value.split(",")) 
                                                    setNoticeTypeId(e.target.value.split(","))}}>
                                                    {/* <option value="0">Select</option> */}
                                                    {NoticeType ? NoticeType?.map((item, index) => <option key={index} value={[item?.noticeTypeId, item?.noticeTypeText]}>{item?.noticeTypeText}</option>):null}
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                                            <div className="form-group">
                                                <label for="wfirstName2">
                                                {" "}
                                                Importance {" "}
                                                <span className="danger">*</span>{" "}
                                                </label>
                                                <select size="md" className="sltareamodal col-12 ml-auto" value={Importance} onChange={(e) => setImportance(e.target.value)}>
                                                    <option value="High">High</option>
                                                    <option value="Medium">Medium</option>
                                                    <option value="Low">Low</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                                            <div className="form-group">
                                                <label for="wfirstName2">
                                                {" "}
                                                Effective Start Date {" "}
                                                <span className="danger">*</span>{" "}
                                                </label>
                                                <input type="date" className="form-control required" value={StartDate} onChange={(e) => {setStartDate(e.target.value);}}/>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                                            <div className="form-group">
                                                <label for="wfirstName2">
                                                {" "}
                                                Effective End Date {" "}
                                                <span className="danger">*</span>{" "}
                                                </label>
                                                <input type="date" className="form-control required" value={EndDate} onChange={(e) => {setEndDate(e.target.value);}}/>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                            <div className="form-group">
                                                <label for="wfirstName2">
                                                {" "}
                                                Notice Subject {" "}
                                                <span className="danger">*</span>{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control required"
                                                    id="wfirstName2"
                                                    name="firstName"
                                                    aria-required="true"
                                                    value={NoticeSubject} onChange={(e) => {setNoticeSubject(e.target.value);}}/>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                            <div className="form-group">
                                                <label for="wfirstName2">
                                                {" "}
                                                Notice Description {" "}
                                                <span className="danger">*</span>{" "}
                                                </label>
                                                <JoditEditor ref={editor} value={content} tabIndex={1} onBlur={newContent =>
                                                setContent(newContent)}/>
                                            </div>
                                        </div>
                                        {!UpdateNoticeBtn ?
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <button type="button" className="btn border bg-white"> Cancel</button>
                                                <button type="button" className="btn btn-info createClr" onClick={AddNoticeData}> Submit</button>
                                            </div>
                                        :<div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <button type="button" className="btn border bg-white"> Cancel</button>
                                            <button type="button" className="btn btn-info createClr" onClick={UpdateNoticeData}> Update</button>
                                        </div>}    
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12 col-12 border_areaFullwdth">
                                        <div className="form-group">
                                        {!selectedImageTwo ? <> <label for="file-upload" disabled={UploadImageBtn} className="custom-file-upload" style={{border: 0}}>
                                                <img for="file-upload" src={noticeuserupload} className="img img-responsive"/>
                                            </label>
                                            <input id="file-upload" disabled={UploadImageBtn} type="file" onChange={(e)=>handleFileInputChangeTwo(e)} /></>
                                            :<div  style={{border: 0}}>
                                                {selectedImageTwo && <img src={selectedImageTwo} className="img img-responsive" style={{width: "90%"}} />}
                                            </div>
                                        }
                                            {/* <label for="file-upload" className="custom-file-upload" style={{border: 0}}>
                                                <img for="file-upload" src={noticeuserupload} className="img img-responsive"/>
                                        
                                            </label>
                                            <input id="file-upload" type="file" onChange={(e)=>handleFileInputChangeTwo(e)} /> */}
                                        {/* <h6 onChange={(e)=>handleFileInputChangeTwo(e)}> Browse file</h6> */}
                                        {!UploadBtn ?
                                            <div className='row absolute_change'>
                                                <div className='col-6 '>
                                                    <button type="button" className="btn border bg-white form-control" disabled onClick={()=> setSelectedImageTwo("")}> Cancel</button>
                                                </div>
                                                <div className='col-6'>
                                                    <Button onChange={AddNoticeImage} disabled className="bg-secondary form-control border-secondary" id="webUrl3" name="webUrl3" aria-required="true" >
                                                        {" "}
                                                        Upload
                                                    </Button>{" "} 
                                                </div>        
                                            </div>    
                                        :<div className='row absolute_change'>
                                            <div className='col-6 '>
                                                <button type="button" className="btn border bg-white form-control" onClick={()=> setSelectedImageTwo("")}> Cancel</button>
                                            </div>
                                            <div className='col-6'>
                                                <Button onClick={AddNoticeImage}  className="form-control border-secondary" >
                                                    {" "}
                                                    Upload
                                                </Button>{" "} 
                                            </div>        
                                        </div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-12 col-12 pr-0">
                                        <div className="uploadedfilesection">
                                        <span> Uploaded Images Preview</span>
                                        <ul>
                                        {NoticeImages ? NoticeImages?.map((item, index) =>
                                            <li key={index}>
                                                <div className='RelativeDel'>
                                                    <FontAwesomeIcon icon={faTimes} onClick={() => ShowDeleteImgModal(item)} className="deleteupload" />
                                                </div>
                                                <img src={atob(item?.docImage)} className="img img-responsive" />
                                                
                                            </li>
                                        ):null}    
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>




  
          <div className="row">
                    <div className="col-12">
                        {/* <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Notice Managment</h4>

                                <div className="table-responsive m-t-20">
                                    <div id="example23_wrapper" className="dataTables_wrapper">
                                        <table id="example23" className="display nowrap table table-hover table-striped table-bordered dataTable" cellSpacing="0"  role="grid" aria-describedby="example23_info" style={{width: '100%'}}>
                                            <thead>
                                                <tr role="row"> 

                                                    <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Office: activate to sort column ascending" style={{width: '200px'}}>Notice Type <FontAwesomeIcon icon={faSort} className='sortclr' /></th>

                                                    <th className="sorting_asc" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Position: activate to sort column descending" style={{width: '210px'}} aria-sort="ascending">Notice Subject <FontAwesomeIcon icon={faSort} className='sortclr' /></th>

                                                    <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{width: '180px'}}> Notice Description <FontAwesomeIcon icon={faSort} className='sortclr' /></th>

                                                    <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Start date: activate to sort column ascending" style={{width: '180px'}}>Importance <FontAwesomeIcon icon={faSort} className='sortclr' /></th>

                                                    <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{width: '180px'}}> Notify On Email <FontAwesomeIcon icon={faSort} className='sortclr' /></th>

                                                </tr>
                                            </thead>
                                        
                                        <tbody>

                                        {NoticeList ? NoticeList.map((item, index) =>  
                                            <tr role="row" key={index} className="odd">
                                                <td>{item.noticeTypeText}</td>
                                                <td><h5 style={{width: 150, overflow: "hidden", textOverflow: "ellipsis", marginTop: 3}}>{item.noticeSubject}</h5></td>
                                                <td><h5 style={{width: 350, overflow: "hidden", textOverflow: "ellipsis", marginTop: 3}} colSpan={2}>{item?.noticeDescription}</h5></td>
                                                <td>{item.importance}</td>
                                                <td>{item.notifyOnEmail == true ? "Yes" : "No"}</td>
                                                <td onClick={() => setInputValue(item)}><a ><FontAwesomeIcon icon={faEdit} className='editclr'/> </a></td>
                                                <td onClick={() => DeleteNotice(item)}><a ><FontAwesomeIcon icon={faTrash} className='editclr' style={{color: "red"}}/> </a></td>
                                            </tr>
                                        ): null}  
                                            
                                             </tbody>
                                    </table>
                                    
                                </div>
                                
                            </div>
                     </div>
                     
                </div>  */}
                <div className='row'>
                    {NoticeList ? NoticeList.map((item, index) =>  
                    <div key={index} className="OneTile">
                        <div className="ex_feild">
                            {item?.importance.toUpperCase() == "MEDIUM" ?
                                <span className="imp_med"> </span>
                            :null}

                            {item?.importance.toUpperCase() == "HIGH" ?
                                <span className="imp_high"> </span>
                            :null}

                            {item?.importance.toUpperCase() == "LOW" ?
                                <span className="imp_Low"> </span>
                            :null}
                        </div>  
                        <div className="NoticeFeild">
                            <h1> {item.noticeSubject}</h1>
                            <h2> {item.noticeTypeText} </h2>

                            <div className="onboardFlex">
                                {/* <h4> Current Status </h4> */}
                                <span> {item.currentStatus} </span>
                            </div> <br/>
                            <div className="onboardFlex">
                                <h4> Expiring On </h4>
                                <span> {convertISTTime(item.effectiveEndDt)} </span>
                            <div className="onboardFont" style={{marginLeft: '35px'}}>
                                <FontAwesomeIcon
                                className="editNotice"
                                icon={faPenToSquare}
                                onClick={() => setInputValue(item)}
                                />
                                <FontAwesomeIcon className="deleteNotice"  onClick={()=> ShowDeleteModal(item)} icon={faTrash} />
                            </div>
                            </div>
                        </div>
                    </div>
                    ): null}  
                </div>
            </div>
        </div>
        <Modal centered show={DeleteShowImg} onHide={() =>
            setDeleteShowImg(false)} className="modal-100w" aria-labelledby="contained-modal-title-vcenter ">
            <Modal.Header className="headerMDL" closeButton>
                <Modal.Title id="contained-modal-title-vcenter" centered onClick={openCreateModal}>
                    <h4 className="mb-0"> Delete Image </h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row" id="validation">
                    <div className="col-12">
                        <div className="FnFItem">
                        <div className="FnFimg">
                            <img src={stopMark} className='img img-fluid img-responsive'  alt="placeholder" />
                        </div>
                        <div className="FnFtxt">
                            <h3> Are your Sure You want to Delete? </h3>
                        </div>
                        </div>
                        <div className='confirmation_btn'>
                        <div className='both_ConfrmBTN' onClick={DeleteNoticeImages}>
                            <Button variant="danger">Delete</Button>{' '}
                        </div>
                        <div className='both_ConfrmBTN' onClick={() => setDeleteShowImg(false)} >
                            <Button variant="light"> Cancel</Button>{' '}
                        </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        <Modal centered show={DeleteShow} onHide={() =>
            setDeleteShow(false)} dialogclassName="modal-100w" aria-labelledby="contained-modal-title-vcenter ">
            <Modal.Header className="headerMDL" closeButton>
                <Modal.Title id="contained-modal-title-vcenter" centered onClick={openCreateModal}>
                    <h4 className="mb-0"> Delete Notice </h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row" id="validation">
                    <div className="col-12">
                        <div className="FnFItem">
                        <div className="FnFimg">
                            <img src={stopMark} className='img img-fluid img-responsive'  alt="placeholder" />
                        </div>
                        <div className="FnFtxt">
                            <h3> Are your Sure You want to Delete? </h3>
                        </div>
                        </div>
                        <div className='confirmation_btn'>
                        <div className='both_ConfrmBTN' onClick={DeleteNotice}>
                            <Button variant="danger">Delete</Button>{' '}
                        </div>
                        <div className='both_ConfrmBTN' onClick={() => setDeleteShow(false)} >
                            <Button variant="light"> Cancel</Button>{' '}
                        </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
</div>
    </section> 
        </>)

} 
  export default Noticemanagement 