import React , {useEffect, useRef, useState} from 'react'; 
import '../assets/css/custom.css'; 
import '../assets/css/client.css'; 
import '../assets/css/font-awesome.css';
import '../assets/css/sweetalert.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/steps.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faEdit, faPlus, faSort } from '@fortawesome/free-solid-svg-icons';
import clientlogo from '../assets/img/user1.jpg'; 
import clientlogo2 from '../assets/img/user2.jpg'; 
import clientlogo3 from '../assets/img/user3.jpg'; 
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import ReactDOM from "react-dom";
// import { Wizard, Steps, Step, WithWizard } from "react-multistep-wizard";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { Stepper, Step, StepLabel } from '@mui/material';
import { Textarea } from '@material-tailwind/react';
import { Circle } from '@mui/icons-material';
import {commonAPI} from "../services/CommonAPIService"
import {ErrorSource} from '../common/constants'
import Menus from "./menus";
import Multiselect from 'multiselect-react-dropdown';


  function UserApproval() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Step 1', 'Step 2', 'Step 3'];
    const [Show, setShow] = useState(false);
    const [ApproveShow, setApproveShow] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [NoticeList, setNoticeList] = useState("");
    const [TowerList, setTowerList] = useState("");
    const [TowerListGet, setTowerListGet] = useState("");
    const [FlatList, setFlatList] = useState("");
    const [FlatListGet, setFlatListGet] = useState("");
    const [FamilyList, setFamilyList] = useState("");
    const [NoticeSubject, setNoticeSubject] = useState("");
    const [NoticeDescription, setNoticeDescription] = useState("");
    const [NoticeEmail, setNoticeEmail] = useState();
    const [Importance, setImportance] = useState();
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const [NoticeType, setNoticeType] = useState("");
    const [NoticeTypeId, setNoticeTypeId] = useState();
    const [NoticeStatus, setNoticeStatus] = useState();
    const [NoticeTypeText, setNoticeTypeText] = useState("");
    const [NoticeId,setNoticeId] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageTwo,setSelectedImageTwo] = useState(null)
    const [firstSectionButton,setFirstSectionButton] = useState(false);
    const [TowerFlatArry,setTowerFlatArry] = useState([]);
    const [CurrentStatusSelected,setCurrentStatusSelected] = useState("PENDING");
    const [UserData, setUserData] = useState("");
    const [TowerSelected, setTowerSelected] = useState([]);
    const [FlatSelected, setFlatSelected] = useState([]);
    const [MappingId, setMappingId] = useState();
    console.log("TowerSelectedget", TowerSelected)
    
    useEffect(() => {
        
    }, [TowerSelected])

    const BroadCastMeseg = (item) => {
        setShow(true)
        console.log("item", item)
        setNoticeSubject(item.noticeSubject)
        setNoticeDescription(item.noticeDescription)
        setNoticeTypeId(item.noticeTypeId)
        setNoticeTypeText(item.noticeTypeText)
        setNoticeStatus(item.currentStatus)
        setNoticeEmail(item.notifyOnEmail == true ? "Yes" : "No")
        setImportance(item.importance)
        setStartDate(item.effectiveStartDt)
        setEndDate(item.effectiveEndDt)
        setSelectedImage()
        setSelectedImageTwo();
    };
   
    const openCreateModal = () => {
        setShow(true)
        setNoticeSubject("")
        setNoticeDescription("")
        setNoticeEmail("")
        setImportance("")
        setStartDate("")
        setEndDate("")
        setSelectedImage("")
        setSelectedImageTwo("");
    }

    function ImageUpload() {
        const [selectedImage, setSelectedImage] = useState(null);

        const handleFileInputChange = (event) => {
            const file = event.target.files[0];
            if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
            }
        };
    }

    const convertISTTime = (date) => {
        if(date != null || date != "" || date != undefined ){
            const [datePart, TimePart] = date.split(" ");
            const [yyyy, mm, dd ] = datePart.split("-");
            // const [hh, mn, ss] = TimePart.split(':')
            const formatedTime = `${mm}/${dd}/${yyyy}`;
            return formatedTime;
        }else{
            console.warn("input date string may be null, empty string or undefined");
        }
    }

    // const endPointVariableKey = {
    //     urlVariable:CurrentStatusSelected,
    //     buildingId: 97
    // }
    
    const GetNoticeList = async () => {
        try {
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            setUserData(myUserData);
            const endPointVariableKey = {
                urlVariable:CurrentStatusSelected,
                buildingId: myUserData?.buildingId
            }
            console.log("myuserData", myUserData)
            let response = await commonAPI("CuroNextUserMappingStatus", undefined, endPointVariableKey)
            console.log("CuroNextUserMappingStatus", response?.data)
            if (response?.success === true){
                console.log("NoticeList", response)
                setNoticeList(response?.data);
            }else{
                alert("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = JSON.stringify({ 
                "userId": UserData?.userId,
                "mobileNumber": UserData?.mobileNumber, // Need to be change
                "requestJSON": "",
                "uri": "/api/Master/Notice",
                "errorNumber": "NL01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "GetNoticeList",
            });
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.status === 200 & response?.message?.length == 0){
                alert("Something went wrong");
                setLoading(false); 
            }else{
                alert("Something went wrong");
                setLoading(false); 
            }
        }

    }

    const GetNoticeType = async () => {

        try {
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            setUserData(myUserData);
            const endPointVariableKey = {
                urlVariable:CurrentStatusSelected,
                buildingId: myUserData?.buildingId
            }
            let response = await commonAPI("NoticeType", undefined, endPointVariableKey)
            // console.log("Notice List", response.data)
            if (response?.success===true){
                // console.log("NoticeType", response)
                setNoticeType(response?.data)
            }else{
                alert("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            alert("Something went wrong");
        }

    }



    useEffect(() => {
        GetNoticeList();
    }, [CurrentStatusSelected])

    


    const ApproveModal = (item) => {
        setApproveShow(true);
        console.log("itemApproveModal", item)
        setNoticeSubject(item?.flatName)
        setNoticeDescription(item?.mobileNumber)
        setNoticeTypeText(item?.userName)
        setImportance(item?.emailId)
        setNoticeStatus(item?.roleName)
        setStartDate(item.effectiveStartDt)
        setEndDate(item.effectiveEndDt)
        setNoticeId(item.userId)
        setMappingId(item.userMappingId)
    }

    const ApproveAPi = async(e) => {
        setApproveShow(true);
        console.log("e value", e)
        const bodydata = { 
            "userId": NoticeId,
            "CurrentStatus": e,
            "ApproverUserName": UserData?.userName,
            "modifiedUserId": UserData?.userName,
            "userMappingId": MappingId 
    //         "userId": 9,
    // "CurrentStatus": "APPROVED",
    // "ApproverUserName": "Satyendra Kumar",
    // "modifiedUserId": "Satyendra Kumar",
    // "userMappingId": "21"
            // "modifiedUserId": "RadiusAdmin", // Need to be replaced
          };
        try {
            setLoading(true);
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            setUserData(myUserData);
            const endPointVariableKey = {
                urlVariable:CurrentStatusSelected,
                buildingId: myUserData?.buildingId
            }
              console.log("body", bodydata);
              let response = await commonAPI("CuroNextUserApproveDeny", bodydata, endPointVariableKey)
            //   const response = await fetch(`http://api-test.curonext.com/api/Master/Building/`, {
            //     // let response = await fetch(`${apiURL}/DAR/DARReportToHOD`, {  
            //       method: "DELETE",
            //       headers: { 'Content-Type': 'application/json'},
            //       body: bodydata
                  
            //   })
            //   const statusCode = response
              console.log("statusCode", response)
              if(response?.success === true & response?.message.length == 0){
                alert(`User ${e}`);  
                setApproveShow(false);
                setLoading(false);
                GetNoticeList();
              }else{
                alert(response.message[0]);
                setLoading(false);
              }
        } catch (error) {
            setLoading(false);
            const bodyError = JSON.stringify({ 
                "userId": UserData.userId,
                "mobileNumber": UserData.mobileNumber, // Need to be change
                "requestJSON": bodydata,
                "uri": "/api/Master/Notice",
                "errorNumber": "DN01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "DeleteNotice",
            });
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response.status === 200 & response.message.length == 0){
                alert("Something went wrong");
                setLoading(false); 
            }else{
                alert("Something went wrong");
                setLoading(false); 
            }
            
        }
        
    }
  
    const handleNext = () => {
       
        setActiveStep((prevActiveStep) => {
            if (prevActiveStep >= steps.length - 1) { // Assuming 'steps' is your array of step labels
                setFirstSectionButton(false)
                console.log(prevActiveStep)
                return 0; // Reset to first step
            } else {
                // console.log(inpRef.current.value)
                setFirstSectionButton(true)
                console.log(prevActiveStep)
                 return prevActiveStep + 1;
                
            }
        });
    };
  
    const handleBack = () => {
        
        setFirstSectionButton(false)
      setActiveStep((prevActiveStep) => {
        if(prevActiveStep==1){
            setFirstSectionButton(false)
            console.log(prevActiveStep)
            return prevActiveStep - 1
        }
        else{
            setFirstSectionButton(true)
            return prevActiveStep - 1
        }
       
    });
    };

    



    const [file, setFile] = useState();
    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    // console.log("img data",AltSPOCEmail)


    // const getStepContent = (stepIndex) => {
    //     console.log(stepIndex)
    //     const handleFileInputChange = (event) => {
    //         const file = event.target.files[0];
    //         if (file) {
    //           const reader = new FileReader();
    //           reader.onload = () => {
    //             setSelectedImage(reader.result);
    //           };
    //           reader.readAsDataURL(file);
    //         }
    //       };
    //       const handleFileInputChangeTwo = (event) => {
    //         const file = event.target.files[0];
    //         if (file) {
    //           const reader = new FileReader();
    //           reader.onload = () => {
    //             setSelectedImageTwo(reader.result);
    //           };
    //           reader.readAsDataURL(file);
    //         }
    //       };
    //   switch (stepIndex) {
    //     case 0:
    //         return <div><section id="steps-uid-0-p-0" role="tabpanel" aria-labelledby="steps-uid-0-h-0" className="body current" aria-hidden="false">
    //         <div className="row mt-5">
            
    //             <div className="col-md-6">
                
    //                 <div className="form-group">
    //                     <label for="wfirstName2">  Notice Subject : <span className="danger">*</span> </label>
    //                     <input type="text" value={NoticeSubject} onChange={(e) => {setNoticeSubject(e.target.value);}} className="form-control required"  id="wfirstName2" name="firstName" aria-required="true" />
    //                      </div>
    //                     <br/>
    //                      <div className="form-group">
    //                   <label for="jobTitle2">Notice Type :</label>

    //                 <br/>

    //                 <Form.Select size="md" className="sltareamodal" onChange={(e) => {
    //                     console.log("selection", e.target.value)
    //                     setNoticeTypeId(e.target.value);
    //                 }
    //                     }>
    //                     <option value="Select">Select</option>
    //                     {NoticeType ? NoticeType.map((item, index) =>  <option key={index} value={item.noticeTypeId}>{item.noticeTypeText}</option>): null}
    //                 </Form.Select>
    //                 {/* <Form.Select size="md" className="sltareamodal" >
    //                     <option>General</option>
    //                     <option>Maintainance</option>
    //                     <option>Security</option>
    //                     <option>Renovation</option>
    //                     <option>Health & Security</option>
    //                     <option>Alert</option>
    //                     <option>Warning</option>
    //                     <option>Information</option>
    //                     <option>Emergency</option>
    //                     <option>Meeting</option>
    //                     <option>Invitation</option>
    //                 </Form.Select> */}





    //                   {/* <input type="text" value={AltEmail} onChange={(e) => setAltEmail(e.target.value)} className="form-control required" id="jobTitle2" aria-required="true" /> */}
    //               </div>
    //               {
    //                 !firstSectionButton && 
    //                 <div className='btn_trigger'>
    //                     <button value="back" className="back_btn btn btn-primary" disabled={activeStep === 0} onClick={handleBack}> Back </button>
    //                     <button value="next" className="back_btn btn btn-primary" onClick={handleNext}> Next </button>
    //                 </div>
    //             }
    //             </div>
    //             <div className="col-md-6">
    //                 <div className="form-group">
    //                     <label for="wlastName2"> Notice Description : <span className="danger">*</span> </label>
    //                 <Textarea cols={37} rows={8} value={NoticeDescription} onChange={(e) => {setNoticeDescription(e.target.value);}} className='txtareamodal'> </Textarea>
    //                     {/* <input type="text" value={ShortName} className="form-control required" onChange={(e) => {setShortName(e.target.value);}} id="wlastName2" name="lastName" aria-required="true" />*/} </div> 
    //             </div>
    //             {/* <div className="col-md-4">
    //                 <div className="form-group">
    //                     <label for="wlastName2"> Website : <span className="danger">*</span> </label>
    //                     <input type="text" value={Website} onChange={(e) => setWebsite(e.target.value)} className="form-control required" id="wlastName2" name="lastName" aria-required="true" /> </div>
    //             </div> */}
    //         </div>
    //         {/* <div className="row">
    //             <div className="col-md-4">
    //                 <div className="form-group">
    //                     <label for="wemailAddress2"> Phone Number : <span className="danger">*</span> </label>
    //                     <input type="email" value={PhoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="form-control required" id="wemailAddress2" name="emailAddress" aria-required="true" /> </div>
    //             </div>
    //             <div className="col-md-4">
    //                 <div className="form-group">
    //                     <label for="wphoneNumber2">Alt Phone Number :</label>
    //                     <input type="tel" value={AltPhoneNumber} onChange={(e) => setAltPhoneNumber(e.target.value)} className="form-control" id="wphoneNumber2" /> </div>
    //             </div>
    //             <div className="col-md-4">
    //                 <div className="form-group">
    //                     <label for="wlastName2"> Email : <span className="danger">*</span> </label>
    //                     <input type="text" value={Email} onChange={(e) => setEmail(e.target.value)} className="form-control required" id="wlastName2" name="lastName" aria-required="true" /> </div>
    //             </div>
    //         </div> */}
            
    //         </section></div>
    //         setFirstSectionButton(false)
    //     case 1:
    //       return <div><section id="steps-uid-0-p-1" role="tabpanel" aria-labelledby="steps-uid-0-h-1" className="body" aria-hidden="true">
    //       <div className="row mt-5">
 

    //           <div className="col-md-6">
    //                   <div className="form-group">
    //                       <label for="jobTitle2">Image 1:</label>
    //                       <input type="file" onChange={handleFileInputChange} className="form-control required" id="jobTitle2" aria-required="true" />
    //                         <div className='img_uploaded'>
    //                             {selectedImage && <img src={selectedImage} alt="Selected Image" />}
    //                         </div>
    //                   </div>
    //           </div>
    //               <div className="col-md-6">
    //                   <div className="form-group">
    //                       <label for="webUrl3">Image 2 :</label>
    //                       <input type="file" value={selectedImageTwo} onChange={handleFileInputChangeTwo} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> 
    //                       <div className='img_uploaded'>
    //                          {selectedImageTwo && <img src={selectedImageTwo} alt="Selected Image" />}
    //                     </div>
    //                       </div>
    //                   </div>
    //           {/* <div className="col-md-4">
    //               <div className="form-group">
    //                   <label for="webUrl3">SPOC Mobile Number :</label>
    //                   <input type="url" value={SpocMobileNumber} onChange={(e) => setSpocMobileNumber(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //           </div> */}
    //           </div>
    //           {/* <div className="row">
    //           <div className="col-md-6">
    //               <div className="form-group">
    //                   <label for="jobTitle2">Image 1:</label>
    //                   <input type="text" value={SpocEmail} onChange={(e) => setSpocEmail(e.target.value)} className="form-control required" id="jobTitle2" aria-required="true" />
    //               </div>
    //           </div>
    //           <div className="col-md-6">
    //               <div className="form-group">
    //                   <label for="webUrl3">Image 2 :</label>
    //                   <input type="url" value={AltSPOCName} onChange={(e) => setAltSPOCName(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //           </div>
    //           <div className="col-md-4">
    //               <div className="form-group">
    //                   <label for="webUrl3">Alt SPOC Mobile Number:</label>
    //                   <input type="url" value={AltSPOCMobileNumber} onChange={(e) => setAltSPOCMobileNumber(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //           </div>
    //           </div>  */}
    //       </section></div>;
    //     case 2:
    //       return <div>  
    //       <section id="steps-uid-0-p-2" role="tabpanel" aria-labelledby="steps-uid-0-h-2" className="body" aria-hidden="true">
    //           <div className="row mt-5">

    //           <div className="col-md-6">
    //               <div className="form-group">
    //                   <label for="webUrl3">Notify On Email :</label>
    //                   <Form.Select size="md" className="sltareamodal" value={NoticeEmail} onChange={(e) => {setNoticeEmail(e.target.value);}}>
    //                     <option value="Select">Select</option>
    //                     <option value={"Yes"}> Yes </option>
    //                     <option value={"No"}> No</option> 
 
    //                 </Form.Select>
    //                    </div>
    //           </div>
 
    //           <div className="col-md-6">
    //                     <div className="form-group">
    //                         <label for="jobTitle2">Importance:</label>

    //                         <br/>


    //                     <Form.Select size="md" className="sltareamodal" value={Importance} onChange={(e) => {setImportance(e.target.value);}}>
    //                         <option value="Select">Select</option>
    //                         <option value={"High"}> High </option>
    //                         <option value={"Medium"}> Medium</option>
    //                         <option value={"Low"}> Low</option>
                    
    //                     </Form.Select>
    //                         {/* <input type="text" value={PinCode} onChange={(e) => setPinCode(e.target.value)} className="form-control required" id="jobTitle2" aria-required="true" /> */}
    //                     </div>
    //                 </div>
    //                 </div>
    //                 <br/>
    //                 <div className='row'>
                  
    //                                     <div className="col-md-6">
    //                     <div className="form-group">
    //                         <label for="webUrl3">Effective Start Date :</label>
    //                         <input type="date" value={StartDate} onChange={(e) => setStartDate(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //                 </div>
 
    //                 <div className="col-md-6">
    //                     <div className="form-group">
    //                         <label for="webUrl3"> Effective End Date:</label>
    //                         <input type="date" value={EndDate} onChange={(e) => setEndDate(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //                     </div>

    //               {/* <div className="col-md-4">
    //                   <div className="form-group">
    //                   <label for="webUrl3">Address Line 2:</label>
    //                   <input type="url" value={AddressLine2} onChange={(e) => setAddressLine2(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //                   </div> */}
    //               </div> 
      
    //               {/* <div className="row">
    //                   <div className="col-md-4">
    //                       <div className="form-group">
    //                           <label for="jobTitle2">City:</label>
    //                           <input type="text" value={City} onChange={(e) => setCity(e.target.value)} className="form-control required" id="jobTitle2" aria-required="true" />
    //                       </div>
    //                   </div>
    //               <div className="col-md-4">
    //                   <div className="form-group">
    //                       <label for="webUrl3">State:</label>
    //                       <input type="url" value={State} onChange={(e) => setState(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //                   </div>
    //               <div className="col-md-4">
    //                   <div className="form-group">
    //                       <label for="webUrl3">Country:</label>
    //                       <input type="url" value={Country} onChange={(e) => setCountry(e.target.value)} className="form-control required" id="webUrl3" name="webUrl3" aria-required="true" /> </div>
    //                   </div>
    //               </div>  */}
                  
    //       </section>
          
    //       </div>;
    //     default:
    //       return 'Unknown step';
    //   }
    // };  
  
    

     return (
    <>
    {Loading? 
        <div className="loaderDiv">
            <div className="loader"></div>
        </div> 
    :null}
    <Menus />
    <section id="client-builder">
   <div className="container-fluid pt-4">
      <div className="row page-titles">
        
         <div className="col-md-12 align-self-end text-right">
            <Form.Select size="md" className="sltareamodal col-2 ml-auto" onChange={(e) => {
                            console.log("selection", e.target.value)
                            setCurrentStatusSelected(e.target.value);
                        }
                            }>
            {/* <select className="col-md-12 align-self-end text-right" name="cars" id="cars"> */}
                <option value="PENDING">Pending</option>
                <option value="APPROVED">Approved</option>
                <option value="REJECTED">Rejected</option>
            </Form.Select>
            {/* <button type="button" className="btn btn-info" onClick={() =>
               openCreateModal()}>
               <FontAwesomeIcon icon={faPlus} />
               Create New
            </button> */}
         </div>
      </div>
      
    

      {/*  Approve/Reject Modal */}
      <Modal show={ApproveShow} onHide={() =>
         setApproveShow(false)} dialogclassName="modal-100w modal-lg clientmodal" aria-labelledby="example-custom-modal-styling-title">
         <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
               Approve User
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className="row" id="validation">
               <div className="col-12">
                  <div className="card wizard-content">
                     <div className="card-body row">
                        <div className="col-md-4 mt-3">
                            <label for="wfirstName2">  Flat Name : <span className="danger">*</span> </label>
                            <h4>  {NoticeSubject} </h4>
                        </div>    
                        <div className="col-md-4 mt-3">
                            <label for="wfirstName2">  Name : <span className="danger">*</span> </label>
                            <h4>  {NoticeTypeText}  </h4>
                        </div>    
                        <div className="col-md-4 mt-3">
                            <label for="wfirstName2">  Mobile : <span className="danger">*</span> </label>
                            <h4>  {NoticeDescription}  </h4>
                        </div>    
                        <div className="col-md-4 mt-3">
                            <label for="wfirstName2">  Email : <span className="danger">*</span> </label>
                            <h4>  {Importance} </h4>
                        </div>    
                        <div className="col-md-4 mt-3">
                            <label for="wfirstName2">  Role Name : <span className="danger">*</span> </label>
                            <h4>  {NoticeStatus} </h4>
                        </div> 
                        {/* <div className="col-md-4 mt-3">
                            <label for="wfirstName2">  Valid Upto : <span className="danger">*</span> </label>
                            <h4>  {EndDate} <span className="danger">*</span> </h4>
                        </div>     */}

                     </div>
                     <div className='row mb-5 mt-3'>
                        <div className='col-md-6 text-center'>
                            <button value={"APPROVED"} onClick={(e) => (ApproveAPi(e.target.value))} className='col-5 btn btn-info'>Approve</button>
                        </div>    
                        <div className='col-md-6 text-center'>
                            <button value={"REJECTED"} onClick={(e) => (ApproveAPi(e.target.value))} className='col-5 btn btn-danger'>Reject</button>
                        </div>    
                     </div>
                  </div>
               </div>
            </div>
         </Modal.Body>
      </Modal>
      <div className="row">
         <div className="col-12">
            <div className="card">
               <div className="card-body">
                  <h4 className="card-title">User Managment</h4>
                  <div className="table-responsive m-t-20">
                     <div id="example23_wrapper" className="dataTables_wrapper">
                        <table id="example23" className="display nowrap table table-hover table-striped table-bordered dataTable" cellSpacing="0"  role="grid" aria-describedby="example23_info" style={{width: '100%'}}>
                        <thead>
                           <tr role="row">
                              {/* <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Name: activate to sort column ascending" style={{width: '100px'}}>Client Logo</th> */}
                              <th className="sorting_asc" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Position: activate to sort column descending" style={{width: '210px'}} aria-sort="ascending"> Flat Name 
                              <FontAwesomeIcon icon={faSort} className='sortclr' />
                              </th>
                              <th className="sorting_asc" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Position: activate to sort column descending" style={{width: '210px'}} aria-sort="ascending"> Name 
                              <FontAwesomeIcon icon={faSort} className='sortclr' />
                              </th>
                              <th className="sorting_asc" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Position: activate to sort column descending" style={{width: '210px'}} aria-sort="ascending">Building Name 
                              <FontAwesomeIcon icon={faSort} className='sortclr' />
                              </th>
                              <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Office: activate to sort column ascending" style={{width: '200px'}}>Email 
                              <FontAwesomeIcon icon={faSort} className='sortclr' />
                              </th>
                              <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{width: '180px'}}>Address Line 
                              <FontAwesomeIcon icon={faSort} className='sortclr' />
                              </th>
                              <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Start date: activate to sort column ascending" style={{width: '180px'}}>Mobile Number 
                              <FontAwesomeIcon icon={faSort} className='sortclr' />
                              </th>
                              <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{width: '180px'}}> Status 
                              <FontAwesomeIcon icon={faSort} className='sortclr' />
                              </th>
                              {CurrentStatusSelected?.toUpperCase() == "REJECTED" || CurrentStatusSelected?.toUpperCase() == "APPROVED" ? null:
                                <th className="sorting" tabIndex="0" aria-controls="example23" rowSpan="1" colSpan="1" aria-label="Salary: activate to sort column ascending" style={{width: '129px'}}>Take Action</th>
                              }
                           </tr>
                        </thead>
                        <tbody>
                           {NoticeList ? NoticeList.map((item, index) =>  
                           <tr role="row" key={index} className="odd">
                                {/* <td className=""> <img src={clientlogo} className="logoadjust" /></td> */}
                                <td>{item?.flatName}</td>
                                <td>{item?.userName}</td>
                                <td>{item?.buildingName}</td>
                                <td>{item?.emailId}</td>
                                <td><h5 style={{width: 150, overflow: "hidden", textOverflow: "ellipsis", marginTop: 3}}>{item?.addressLine1}  {item?.addressLine2}</h5></td>
                                <td>{item?.mobileNumber}</td>
                                {item?.currentStatus?.toUpperCase() == "PENDING" ? 
                                <td>Pending</td>
                                :null
                                }
                                {item?.currentStatus?.toUpperCase() == "APPROVED" ? 
                                <td>Approved</td>
                                :null
                                }
                                {item?.currentStatus?.toUpperCase() == "REJECTED" ? 
                                <td>Rejected</td>
                                :null
                                }
                                
                                {CurrentStatusSelected?.toUpperCase() == "REJECTED" || CurrentStatusSelected?.toUpperCase() == "APPROVED"  ? null:
                                    <td >
                                        {item?.currentStatus?.toUpperCase() == "PENDING" ? 
                                            <button onClick={() => ApproveModal(item)} className='btn btn-info'>Approve</button>
                                            :
                                            null
                                        }
                                    </td>
                                }
                                
                              
                           </tr>
                           ): null}  
                        </tbody>
                        </table>
                        {/* <div className="dataTables_info" id="example23_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                        <div className="dataTables_paginate paging_simple_numbers" id="example23_paginate">
                           <a className="paginate_button previous disabled" aria-controls="example23" data-dt-idx="0" tabIndex="0" id="example23_previous">Previous</a>
                           <span>
                           <a className="paginate_button current" aria-controls="example23" data-dt-idx="1" tabIndex="0">1</a>
                           <a className="paginate_button " aria-controls="example23" data-dt-idx="2" tabIndex="0">2</a>
                           <a className="paginate_button " aria-controls="example23" data-dt-idx="3" tabIndex="0">3</a>
                           <a className="paginate_button " aria-controls="example23" data-dt-idx="4" tabIndex="0">4</a>
                           <a className="paginate_button " aria-controls="example23" data-dt-idx="5" tabIndex="0">5</a>
                           <a className="paginate_button " aria-controls="example23" data-dt-idx="6" tabIndex="0">6</a>
                           </span>
                           <a className="paginate_button next" aria-controls="example23" data-dt-idx="7" tabIndex="0" id="example23_next">Next</a>
                        </div> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section> 
        </>)

} 
  export default UserApproval 