import React, { useEffect, useRef, useState } from "react";
import { FaUsers, FaProjectDiagram, FaTools, FaShieldAlt } from 'react-icons/fa';
import { Card, ListGroup } from 'react-bootstrap';
import "../assets/css/animate.css";
import "../assets/css/font-awesome.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/login.css";
import "../assets/css/register.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link } from "react-router-dom";
import vehicleUp from "../assets/img/vehicleUp.png";
import motorcycle from "../assets/img/motorcycle.png";
import greatcar from "../assets/img/greatcar.png";
import greatbike from "../assets/img/greatbike.png";
import carimg from "../assets/img/vehicle-car.png";
import bikeimg from "../assets/img/vehicle-bike.png";
import visit from "../assets/img/visitor.png";
import walkin from "../assets/img/walkin.png";
import visitorIn from "../assets/img/visitorIn.png";
import { Pie, Bar, Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import Menus from "./menus";
import {commonAPI} from "../services/CommonAPIService"
import {ErrorSource} from '../common/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
Chart.register(...registerables);

function Dashboard() {
  const history = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [DashBoardAPIData, setDashBoardAPIData] = useState("");
  const [DashBoardAPIDataLast, setDashBoardAPIDataLast] = useState("");
  const [MoreThanHour, setMoreThanHour] = useState("");
  const [SmsConsumedCount, setSmsConsumedCount] = useState([]);
  const [SmsConsumedCountDate, setSmsConsumedCountDate] = useState([]);
  const [EmailConsumedCountDate, setEmailConsumedCountDate] = useState([]);
  const [NotificationConsumedCountDate, setNotificationConsumedCountDate] = useState([]);
  

  useEffect(() => {
    const storedUsername = localStorage.getItem('userData');
    console.log("useEffect Local storage name", storedUsername)
    if (storedUsername) {
      // If the username is present, redirect to the dashboard
      history('/Dashboard');
      console.log("useEffect Local storage Yes")    
    }
    else if(storedUsername===null){
        history('/');
        console.log("useEffect Local storage No")    
    }
}, [history]);

function formatDateString(dateString) {
  console.log("date string", dateString)
  
  // Split the date string to extract date parts
  let [datePart, rest] = dateString.split('T');
  let [year, month, day] = datePart.split('-');
  
  // Create a date object
  let date = new Date(`${year}-${month}-${day}`);

  // Define an array of month names
  const monthNames = ["January", "February", "March", "April", "May", "June", 
                      "July", "August", "September", "October", "November", "December"];

  // Get the day of the month
  let dayOfMonth = date.getDate();
  
  // Get the month name
  let monthName = monthNames[date.getMonth()];

  // Add appropriate ordinal suffix to the day
  let ordinal;
  if (dayOfMonth > 3 && dayOfMonth < 21) ordinal = 'th';
  else {
      switch (dayOfMonth % 10) {
          case 1: ordinal = 'st'; break;
          case 2: ordinal = 'nd'; break;
          case 3: ordinal = 'rd'; break;
          default: ordinal = 'th'; break;
      }
  }

  // Format the date string
  return `${dayOfMonth}${ordinal} ${monthName}`;
}

  // const convertISTTime = (date) => {
  //   if(date != null || date != "" || date != undefined ){
  //       const [datePart, TimePart] = date.split(" ");
  //       const [mm, dd, yyyy ] = datePart.split("-");
  //       // const [hh, mn, ss] = TimePart.split('T')
  //       const formatedTime = `${dd}-${mm}-${yyyy}`;
  //       return formatedTime;
  //   }else{
  //       console.warn("input date string may be null, empty string or undefined");
  //   }
  // }
  
  const GetAppSetting = async () => {
    const auth = localStorage.getItem("userData");
    const myUserData = JSON.parse(auth);
    try {
        const endPointVariableKey    = {
            // urlVariable: `?StartDate=${Todaydata}&EndDate=${Todaydata}`,
            // urlVariable: null,
            buildingId: myUserData?.buildingId
        
        }
        let response = await commonAPI("AppSettings", undefined, endPointVariableKey)
        setLoading(true);
        if (response?.success== true){
            console.log("AppSettings", response);
            const MoreThenHour = response.data.filter((item, index) => {
              return item.key.toUpperCase() === "DASHBOARD_VISITOR_IN_MORE_THAN_X_HOURS"
            })
            // console.log("MoreThenHour", MoreThenHour[0].value);
            setMoreThanHour(MoreThenHour[0].value);
            setLoading(false);
        }else{
            setLoading(false);
            toast.error("Something went wrong11");
        }

    } catch (error) {
        console.log(error);
        setLoading(false);
        const bodyError = { 
            "userId": myUserData?.userId,
            "mobileNumber": myUserData?.mobileNumber,
            "requestJSON": null,
            "uri": "/api/Dashboard/DashboardData",
            "errorNumber": "AS01",
            "errorSource": ErrorSource,
            "errorSummary": error.message,
            "errorDescription": error.message,
            "fileClassName": "Dashboard.js",
            "functionName": "GetDashboardData",
        };
        let response = await commonAPI("ErrorLogMaster", bodyError);
        console.log("statusCode", response);
        if (response.success == true && response.message.length == 0) {
            toast.error("Something went wrong");
            setLoading(false);
        } else {
            setLoading(false);
            toast.error("Something went wrong");
        }
    }

  }

  const GetDashboardData = async () => {
    const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
    try {
        
        const date = new Date();
        const Todaydata = String(date.getMonth()+1).padStart(2, "0")+ "/" +String(date.getDate()).padStart(2, "0") +"/"+ date.getFullYear() 
        console.log("today dateT", Todaydata)
        const endPointVariableKey    = {
            // urlVariable: `?StartDate=${Todaydata}&EndDate=${Todaydata}`,
            urlVariable: `?StartDate=05/23/2024&EndDate=05/23/2024`,
            buildingId: myUserData?.buildingId
        
        }
        let response = await commonAPI("DashboardData", undefined, endPointVariableKey)
        setLoading(true);
        if (response?.success== true){
            console.log("DashboardData", response)
            setDashBoardAPIData(response.data[0]);
            setLoading(false);
        }else{
            setLoading(false);
            toast.error("Something went wrong");
        }

    } catch (error) {
        console.log(error);
        setLoading(false);
        const bodyError = { 
            "userId": myUserData?.userId,
            "mobileNumber": myUserData?.mobileNumber,
            "requestJSON": null,
            "uri": "/api/Dashboard/DashboardData",
            "errorNumber": "RU01",
            "errorSource": ErrorSource,
            "errorSummary": error.message,
            "errorDescription": error.message,
            "fileClassName": "Dashboard.js",
            "functionName": "GetDashboardData",
        };
        let response = await commonAPI("ErrorLogMaster", bodyError);
        console.log("statusCode", response);
        if (response.success == true && response.message.length == 0) {
            toast.error("Something went wrong");
            setLoading(false);
        } else {
            setLoading(false);
            toast.error("Something went wrong");
        }
    }

  }

  const GetDashboardData7Day = async () => {
    const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
    try {
        const date = new Date();
        const last = new Date(date.getTime() - (6 * 24 * 60 * 60 * 1000));
        const day =last.getDate();
        const month=last.getMonth()+1;
        const year=last.getFullYear();
        const Last7Date = String(last.getMonth()+1).padStart(2, "0")+ "/" +String(last.getDate()).padStart(2, "0") +"/"+ last.getFullYear() 
        console.log("last 7 day date",day + "" + month + "" + year)

        
        
        const Todaydata = String(date.getMonth()+1).padStart(2, "0")+ "/" +String(date.getDate()).padStart(2, "0") +"/"+ date.getFullYear() 
        console.log("today dateT", Todaydata)
        const endPointVariableKey    = {
            urlVariable: `?StartDate=${Last7Date}&EndDate=${Todaydata}`,
            // urlVariable: `?StartDate=05/23/2024&EndDate=05/23/2024`,
            buildingId: myUserData?.buildingId
        
        }
        let response = await commonAPI("DashboardData", undefined, endPointVariableKey)
        setLoading(true);
        if (response?.success== true){
            console.log("DashboardData last 7day", response)
            setDashBoardAPIDataLast(response.data);
            setLoading(false);
            const consumedCount = response.data.map((item) => {
              return item.notificationConsumedCount
            })
            const consumedCountDate = response.data.map((item) => {
              return formatDateString(item?.recordDate)
            })
            const EmailConsumedCount = response.data.map((item) => {
              return item?.emailConsumedCount
            })
            const NotificationConsumedCount = response.data.map((item) => {
              return item?.notificationConsumedCount
            })
            console.log("consumedCountDate", consumedCountDate)
            setSmsConsumedCount(consumedCount);
            setSmsConsumedCountDate(consumedCountDate);
            setEmailConsumedCountDate(EmailConsumedCount);
            setNotificationConsumedCountDate(NotificationConsumedCount);
        }else{
            setLoading(false);
            toast.error("Something went wrong");
        }

    } catch (error) {
        console.log(error);
        setLoading(false);
        const bodyError = { 
            "userId": myUserData?.userId,
            "mobileNumber": myUserData?.mobileNumber,
            "requestJSON": null,
            "uri": "/api/Dashboard/DashboardData",
            "errorNumber": "RU01",
            "errorSource": ErrorSource,
            "errorSummary": error.message,
            "errorDescription": error.message,
            "fileClassName": "Dashboard.js",
            "functionName": "GetDashboardData7Day",
        };
        let response = await commonAPI("ErrorLogMaster", bodyError);
        console.log("statusCode", response);
        if (response.success == true && response.message.length == 0) {
            toast.error("Something went wrong");
            setLoading(false);
        } else {
            setLoading(false);
            toast.error("Something went wrong");
        }
    }

  }

  const billingData = [
    {
        projectName: 'New Town Heights 86',
        status: 'TRH-150D',
        nextBilling: '2024-07-01',
        billingAmount: '1,75,790.00',
    },
    {
        projectName: 'Bestech Business Tower (Mohali) - Phase-1',
        status: 'TRH-150D',
        nextBilling: '2022-01-01',
        billingAmount: '6,666.67',
    },
    {
      projectName: 'Bestech Business Tower (Mohali) - Phase-1',
      status: 'TRH-150D',
      nextBilling: '2022-01-01',
      billingAmount: '6,666.67',
  },
    // Add more billing data as necessary
];

const projectSuspensionData = [
    {
        projectName: 'New Town Heights 86',
        status: 'TRH-150D',
        expiryDate: '2024-09-30',
        engineer: 'AMC',
    },
    {
        projectName: 'Dlf Golf',
        status: 'TRH-150D',
        expiryDate: '2021-03-31',
        engineer: 'Single Visit',
    },
    {
      projectName: 'Dlf Golf',
      status: 'TRH-150D',
      expiryDate: '2021-03-31',
      engineer: '2nd Visit',
  },
    // Add more project suspension data as necessary
];

  

  const state = {
    labels: ["Visitors", "Residents"],

    datasets: [
      {
        label: "Vehicle In",
        backgroundColor: ["#fbbc05", "#4285f4"],
        data: [DashBoardAPIData?.visitorCarIn + DashBoardAPIData?.visitorBikeIn, DashBoardAPIData?.residentCarIn + DashBoardAPIData?.residentBikeIn],
      },
    ],
  };

  const state1 = {
    labels: ["Cars", "Bikes"],
    datasets: [
      {
        label: "Vehicle In",
        backgroundColor: ["#34A853", "#ea4335"],
        data: [DashBoardAPIData?.residentCarIn + DashBoardAPIData?.visitorCarIn, DashBoardAPIData?.residentBikeIn + DashBoardAPIData?.visitorBikeIn],
      },
    ],
  };

  const state10 = {
    labels: [ "Visitors", "Residents"],
    datasets: [
      {
        label: "Vehicle In",
        backgroundColor: ["#fbbc05", "#4285f4"],
        data: [DashBoardAPIData?.visitorCarOut + DashBoardAPIData?.visitorBikeOut, DashBoardAPIData?.residentCarOut + DashBoardAPIData?.residentBikeOut],
      },
    ],
  };

  const state11 = {
    labels: ["Cars", "Bikes"],
    datasets: [
      {
        label: "Vehicle In",
        backgroundColor: ["#34A853", "#ea4335"],
        data: [DashBoardAPIData?.residentCarOut + DashBoardAPIData?.visitorCarOut, DashBoardAPIData?.residentBikeOut + DashBoardAPIData?.visitorBikeOut],
      },
    ],
  };

  const state4 = {
    labels: SmsConsumedCountDate,
    datasets: [
      {
        label: "SMS QUOTA",
        backgroundColor: [
          "#bd8d00",
          "Purple",
          "Yellow",
          "Teal",
          "Red",
          "Navy",
          "Brown",
        ],
        fill: false,
        lineTension: 0.5,
        borderColor: "#bd8d00",
        borderWidth: 1,

        data: SmsConsumedCount,
      },
    ],
  };

  const state5 = {
    labels: SmsConsumedCountDate,
    datasets: [
      {
        label: "EMAIL QUOTA",
        backgroundColor: [
          "#bd8d00",
          "Purple",
          "Yellow",
          "Teal",
          "Red",
          "Navy",
          "Brown",
        ],
        fill: false,
        lineTension: 0.5,
        borderColor: "#bd8d00",
        borderWidth: 1,
        data: EmailConsumedCountDate,
      },
    ],
  };

  const state6 = {
    labels: SmsConsumedCountDate,
    datasets: [
      {
        label: "NOTIFICATION QUOTA",
        backgroundColor: [
          "#bd8d00",
          "Purple",
          "Yellow",
          "Teal",
          "Red",
          "Navy",
          "Brown",
        ],
        fill: false,
        lineTension: 0.5,
        borderColor: "#bd8d00",
        borderWidth: 1,
        data: NotificationConsumedCountDate,
      },
    ],
  };

  const [clientCount, setClientCount] = useState(120);
  const [projectCount, setProjectCount] = useState(45);
  const [amcCount, setAmcCount] = useState(78);
  const [warrantyCount, setWarrantyCount] = useState(32);
  return (
    <>
      {Loading? 
          <div className="loaderDiv">
              <div className="loader"></div>
          </div> 
      :null}
      <Menus />
      <ToastContainer />
      <div className="dashalign">
        <div className="container-fluid mt-5 pt-4">
        <div style={styles.dashboardContainer}>
          {/* Client Count */}
          <div style={{...styles.card, backgroundColor: "orange", color: "#fff", display: "flex" , flexDirection: "row", justifyContent: "space-around"}}>
            <FaUsers style={styles.icon} />
            <div style={styles.textContainer}>
              <p style={{fontSize: 50, marginBottom: 0}}>{clientCount}</p>
              <p>Clients</p>
            </div>
          </div>

          {/* Project Count */}
          <div style={{...styles.card, backgroundColor: "#00cd66", color: "#fff", display: "flex" , flexDirection: "row", justifyContent: "space-around"}}>
            <FaProjectDiagram style={styles.icon} />
            <div style={styles.textContainer}>
              <p style={{fontSize: 50, marginBottom: 0}}>{projectCount}</p>
              <p>Projects</p>
            </div>
          </div>

          {/* Machine AMC Count */}
          <div style={{...styles.card, backgroundColor: "#0fddaf", color: "#fff", display: "flex" , flexDirection: "row", justifyContent: "space-around"}}>
            <FaTools style={styles.icon} />
            <div style={styles.textContainer}>
              <p style={{fontSize: 50, marginBottom: 0}}>{amcCount}</p>
              <p>Machine AMC</p>
            </div>
          </div>

          {/* Machine Warranty Count */}
          <div style={{...styles.card, backgroundColor: "#ee3a8c", color: "#fff", display: "flex" , flexDirection: "row", justifyContent: "space-around"}}>
            <FaShieldAlt style={styles.icon} />
            <div style={styles.textContainer}>
              <p style={{fontSize: 50, marginBottom: 0}}>{warrantyCount}</p>
              <p>Machine Warranty</p>
            </div>
          </div>
        </div>

         <div className="row">
            {/* Upcoming Billing Section */}
            <div className="col-md-6">
                <Card className="mb-4">
                    <Card.Header as="h5">Expired Machine</Card.Header>
                    <ListGroup variant="flush">
                        {billingData.map((billing, index) => (
                            <ListGroup.Item key={index} className="d-flex align-items-center">
                                <FaTools style={{...styles.icon, color: "#000", fontSize: 25}} /> {/* Add proper path for the icon */}
                                <div className="ms-3">
                                    <div>
                                        <strong>{billing.projectName}</strong> - <span className="text-danger">{billing.status}</span>
                                    </div>
                                    <div>
                                        <span className="text-muted">Expired Date | {billing.nextBilling}</span> 
                                    </div>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card>
            </div>

            {/* Upcoming Project Suspension Section */}
            <div className="col-md-6">
                <Card className="mb-4">
                    <Card.Header as="h5">UpComing Visits</Card.Header>
                    <ListGroup variant="flush">
                        {projectSuspensionData.map((project, index) => (
                            <ListGroup.Item key={index} className="d-flex align-items-center">
                                <FaTools style={{...styles.icon, color: "#000", fontSize: 25}} /> {/* Add proper path for the icon */}
                                <div className="ms-3">
                                    <div>
                                        <strong>{project.projectName}</strong> - <span className="text-danger">{project.status}</span>
                                    </div>
                                    <div>
                                        <span className="text-muted">Visit Date | {project.expiryDate}</span> | <span>{project.engineer}</span>
                                    </div>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card>
            </div>

         </div>
        </div>
      </div>
    </>
  );
}
const styles = {
  dashboardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px',
    flexWrap: 'wrap', // Allows wrapping for smaller screens
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '280px',
    height: '150px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '10px',
    padding: '20px',
  },
  icon: {
    fontSize: '70px',
    color: '#fff', // Set your preferred icon color
  },
  textContainer: {
    textAlign: 'center',
    fontSize: "20px"
  },
};
export default Dashboard;
