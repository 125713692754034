
export async function commonAPI(
  endPointKey,
  body = null,
  endPointVariableKey = null,
) {
  // api-test-next.curo.in
const MasterApi = "https://api-test-next.curo.in";

const BaseURL = process.env.REACT_APP_API_KEY_DEV_MODE
console.log("BASE URL", endPointKey, `${endPointVariableKey}`);

  const APIObj = {
    Country: {
      endPoint: `${BaseURL}/api/Common/Country`,
      method: 'GET',
    },
    State: {
      endPoint: `${BaseURL}/api/Common/State/${endPointVariableKey}`,
      method: 'GET',
    },
    City: {
      endPoint: `${BaseURL}/api/Common/City/${endPointVariableKey}`,
      method: 'GET',
    },
    Client: {
      endPoint: `${BaseURL}/api/Common/Client`,
      method: 'GET',
    },
    ClientAdd: {
        endPoint: `${BaseURL}/api/Common/Client`,
        method: 'POST',
    },
    ClientUpdate: {
        endPoint: `${BaseURL}/api/Common/Client`,
        method: 'PUT',
    },
    CuroProducts: {
        endPoint: `${BaseURL}/api/Common/CuroProducts`,
        method: 'GET',
    },
    Building: {
        endPoint: `${BaseURL}/api/Common/Building`,
        method: 'GET',
    },
    BuildingAdd: {
        endPoint: `${BaseURL}/api/Common/Building`,
        method: 'POST',
    },
    BuildingUpdate: {
        endPoint: `${BaseURL}/api/Common/Building`,
        method: 'PUT',
    },
    BuildingDelete: {
      endPoint: `${BaseURL}/api/Common/Building`,
      method: 'DELETE',
    },
    Gate: {
      endPoint: `${MasterApi}/api/Master/Gate`,
      method: 'GET',
    },
    GateAdd: {
      endPoint: `${MasterApi}/api/Master/Gate`,
      method: 'POST',
    },
    GateUpdate: {
      endPoint: `${MasterApi}/api/Master/Gate`,
      method: 'PUT',
    },
    GateDelete: {
      endPoint: `${MasterApi}/api/Master/Gate`,
      method: 'DELETE',
    },
    Flat: {
      endPoint: `${MasterApi}/api/Master/Flat`,
      method: 'GET',
    },
    FlatAdd: {
      endPoint: `${MasterApi}/api/Master/Flat`,
      method: 'POST',
    },
    FlatDelete: {
      endPoint: `${MasterApi}/api/Master/Flat`,
      method: 'DELETE',
    },
    FlatUpdate: {
      endPoint: `${MasterApi}/api/Master/Flat`,
      method: 'PUT',
    },
    NoticeRecipient: {
      endPoint: `${MasterApi}/api/Notice/NoticeRecipient/${endPointVariableKey?.urlVariable}`,
      // endPoint: `${MasterApi}/api/Notice/NoticeRecipient/10002`,
      method: 'GET',
    },
    Tower: {
      endPoint: `${MasterApi}/api/Master/Tower`,
      method: 'GET',
    },
    TowerDelete: {
      endPoint: `${MasterApi}/api/Master/Tower`,
      method: 'DELETE',
    },
    TowerUpdate: {
      endPoint: `${MasterApi}/api/Master/Tower`,
      method: 'PUT',
    },
    TowerAdd: {
      endPoint: `${MasterApi}/api/Master/Tower`,
      method: 'POST',
    },
    Notice: {
      endPoint: `${MasterApi}/api/Notice/NoticeList`,
      method: 'POST',
    },
    NoticeType: {
      endPoint: `${MasterApi}/api/Notice/NoticeType`,
      method: 'GET',
    },
    NoticeAdd: {
      endPoint: `${MasterApi}/api/Notice/Notice`,
      method: 'POST',
    },
    NoticeImagesAdd: {
      endPoint: `${MasterApi}/api/Notice/NoticeImages`,
      method: 'POST',
    },
    NoticeImages: {
      endPoint: `${MasterApi}/api/Notice/NoticeImages/${endPointVariableKey?.urlVariable}`,
      method: 'GET',
    },
    NoticeImagesDelete: {
      endPoint: `${MasterApi}/api/Notice/NoticeImages`,
      method: 'DELETE',
    },
    NoticeUpdate: {
      endPoint: `${MasterApi}/api/Notice/Notice`,
      method: 'PUT',
    },
    NoticeDelete: {
      endPoint: `${MasterApi}/api/Notice/Notice`,
      method: 'DELETE',
    },
    ApproveNotice: {
      endPoint: `${MasterApi}/api/Notice/ApproveNotice`,
      method: 'PUT',
    },
    NoticeRecipientAdd: {
      endPoint: `${MasterApi}/api/Notice/NoticeRecipient`,
      method: 'POST',
    },
    CuroNextUserMappingStatus: {
      endPoint: `${BaseURL}/api/User/CuroNextUserMappingStatus/${endPointVariableKey?.buildingId}/${endPointVariableKey?.urlVariable}`,
      method: 'GET',
    },
    CuroNextUserApproveDeny: {
      endPoint: `${BaseURL}/api/User/CuroNextUserApproveDeny`,
      method: 'POST',
    },
    PendingResVehicle: {
      endPoint: `${MasterApi}/api/Vehicle/PendingResVehicle/${endPointVariableKey?.urlVariable}`,
      method: 'GET',
    },
    VehicleImages: {
      endPoint: `${MasterApi}/api/Vehicle/VehicleImages/${endPointVariableKey?.urlVariable}`,
      method: 'GET',
    },
    ApproveResidentVehicle: {
      endPoint: `${MasterApi}/api/Vehicle/ApproveResidentVehicle/${endPointVariableKey}`,
      method: 'PUT',
    },
    CuroNextUserFamily: {
      endPoint: `${BaseURL}/api/User/CuroNextUserFamily/0/true`,
      method: 'GET',
    },
    CuroNextUserFamilyList: {
      endPoint: `${BaseURL}/api/User/CuroNextUserFamily/${endPointVariableKey?.urlVariable}`,
      method: 'GET',
    },
    ResidentVehicle: {
      endPoint: `${MasterApi}/api/Vehicle/ResidentVehicle/${endPointVariableKey?.urlVariable}`,
      method: 'GET',
    },
    GetResidentVehicle: {
      endPoint: `${MasterApi}/api/Vehicle/GetResidentVehicle`,
      method: 'POST',
    },
    PendingResVehicle: {
      endPoint: `${MasterApi}/api/Vehicle/PendingResVehicle/${endPointVariableKey?.urlVariable}`,
      method: 'GET',
    },
    ResidentVehicleImage: {
      endPoint: `${MasterApi}/api/Vehicle/ResidentVehicleImages/${endPointVariableKey?.urlVariable}`,
      method: 'GET',
    },
    ApproveResidentVehicle: {
      endPoint: `${MasterApi}/api/Vehicle/ApproveResidentVehicle`,
      method: 'PUT',
    },
    CuroNextUserMappingStatus: {
      endPoint: `${BaseURL}/api/User/CuroNextUserMappingStatus/${endPointVariableKey}`,
      method: 'GET',
    },
    CuroNextUserMappingAdd: {
      endPoint: `${BaseURL}/api/User/CuroNextUserMapping`,
      method: 'POST',
    },
    User: {
      endPoint: `${BaseURL}/api/User/User`,
      method: 'POST',
    },
    MobileOTP: {
      endPoint: `${BaseURL}/api/Notification/MobileOTP`,
      method: 'POST',
    },
    EmailOTP: {
      endPoint: `${BaseURL}/api/Notification/EmailOTP`,
      method: 'POST',
    },
    Login: {
      endPoint: `${BaseURL}/api/User/Login/${endPointVariableKey}`,
      method: 'GET',
    },
    PortalLogin: {
      endPoint: `${BaseURL}/api/User/PortalLogin`,
      method: 'POST',
    },
    CuroNextUserMapping: {
      endPoint: `${BaseURL}/api/User/CuroNextUserMapping/${endPointVariableKey}`,
      method: 'GET',
    },
    CuroNextUserApproveDeny: {
      endPoint: `${BaseURL}/api/User/CuroNextUserApproveDeny`,
      method: 'POST',
    },
    CuroNextUserMappingComment: {
      endPoint: `${BaseURL}/api/User/CuroNextUserMapping/${endPointVariableKey}`,
      method: 'GET',
    },
    ResidentVehicleList: {
      endPoint: `${MasterApi}/api/Vehicle/ResidentVehicle`,
      method: 'GET',
    },
    ResidentVehicleAdd: {
      endPoint: `${MasterApi}/api/Vehicle/ResidentVehicle`,
      method: 'POST',
    },
    ResidentVehicleUpdate: {
      endPoint: `${MasterApi}/api/Vehicle/ResidentVehicle`,
      method: 'PUT',
    },
    ResidentVehicleDelete: {
      endPoint: `${MasterApi}/api/Vehicle/ResidentVehicle`,
      method: 'DELETE',
    },
    DashboardData: {
      endPoint: `${MasterApi}/api/Dashboard/DashboardData${endPointVariableKey?.urlVariable}`,
      method: 'GET',
    },
    AppSettings: {
      endPoint: `${MasterApi}/api/Master/AppSettings`,
      method: 'GET',
    },
    VehicleFuelType: {
      endPoint: `${BaseURL}/api/Common/VehicleFuelType`,
      method: 'GET',
    },
    VehicleBrand: {
      endPoint: `${BaseURL}/api/Common/VehicleBrand`,
      method: 'GET',
    },
    ErrorLogMaster: {
      endPoint: `${BaseURL}/api/Common/ErrorLog`,
      method: 'POST',
    },
}
  try {
    console.log("API ENDPOINT: ", endPointVariableKey?.buildingId) 
    
    let response = await fetch(APIObj[endPointKey].endPoint, {
      method: APIObj[endPointKey].method,
      headers: {
        'Content-Type': 'application/json',
        "BuildingId": endPointVariableKey?.buildingId? endPointVariableKey?.buildingId: null
      },
      body: body ? JSON.stringify(body) : null,
    });

    if (response.status === 200 || response.status === 400) {
        return response.json();
    } else {
        throw new Error(`Unexpected status code: ${response.status}`);
    }

    response = await response.json();
    console.log(`API Response from ${endPointKey}`, response);
    return response;

  } catch (error) {
    console.error('API Request Error:', error);
  }
}
