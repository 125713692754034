import React, { useEffect, useRef, useState } from "react";
import "../assets/css/custom.css";
import "../assets/css/client.css";
import "../assets/css/font-awesome.css";
import "../assets/css/sweetalert.css";
// import { Users, Star } from "react-feather";
import "../assets/css/bootstrap.min.css";
import "../assets/css/steps.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faDoorOpen,
  faEdit,
  faPlus,
  faSort,
  faTowerObservation,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import clientlogo from "../assets/img/user1.jpg";
import clientlogo2 from "../assets/img/user2.jpg";
import clientlogo3 from "../assets/img/user3.jpg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import client from "../assets/img/client.png";
import building from "../assets/img/building.png";
import office from "../assets/img/office.png";
import apartment from "../assets/img/apartment.png";
import gate from "../assets/img/gate.png";
import Menus from "./menus";
import Noticemanagement from "./Noticemanagement";
// import NoticeApproval from "./NoticeApproval";
import Building from "./building";
// import Tower from "./tower";
import Flat from "./flat";
import Gate from "./Gate";
import Resident from "./Resident";
import ProjectMachine from "./Projectmachine";
import ClientMachine from "./clientMachine";



function Management() {
  const [activeTab, setActiveTab] = useState("tab1");
  

  const handleTabChange = (tab) => {
    console.log("Initial Tab", tab)
    localStorage.setItem("lastTabClient", tab)
    setActiveTab(tab);
  };

  useEffect(() => {
    // localStorage.setItem("lastTabClient", "tab1")
    const lastTab = localStorage.getItem("lastTabClient");
    if (!lastTab) {
      setActiveTab("tab1")
    }
    else {
      console.log(lastTab, "lastTTT")
      setActiveTab(lastTab)
    }

  }, [])


  return (
    <>
    <Menus />
      <section className="tabular_intergration">
        <div className="tab-container">
          <div className="UnderBox-Shadow">
          <div className="container-fluid py-0 px-0">
            <div className="row">
              <div className="col-lg-12 col-dm-12 col-sm-12 col-12">   
              <div className="tabs">
            <button
              className={activeTab === "tab1" ? "active-tab" : ""}
              onClick={() => handleTabChange("tab1")}
            >
              <img src={client} className="img" style={{ width: "21px" }} />{" "}
              Client
            </button> 
            <button
              className={activeTab === "tab2" ? "active-tab" : ""}
              onClick={() => handleTabChange("tab2")}
            >
              <img src={client} className="img" style={{ width: "21px" }} />{" "}
              Project
            </button> 
               </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="OneClr_apply">
<div className="container-fluid">
  <div className='row'>
       <div className="col-12 ">

 
              {activeTab === "tab1" && (
                <div>
                  <ClientMachine />
                  
                  {/* <button
                    onClick={() => {
                      setReset((counter) => counter + 1);
                    }}
                  >
                    Reset
                  </button> */}
                </div>
              )}
                            {activeTab === "tab2" && (
                <div>
 
                  <ProjectMachine />

                  {/* <button
                    onClick={() => {
                      setReset((counter) => counter + 1);
                    }}
                  >
                    Reset
                  </button> */}
                </div>
              )}
              {/* {activeTab === "tab2" && (
                <div>
                  <NoticeApproval />
                </div>
              )}
   */}
     
          </div>
  </div>
</div>
</div>
    </>
  );
}
export default Management;
