// import React from 'react';
import React , {useEffect, useRef, useState} from 'react';
import '../assets/css/animate.css';
import '../assets/css/font-awesome.css'; 
import '../assets/css/bootstrap.min.css';   
import '../assets/css/login.css';
import '../assets/css/register.css';
import {commonAPI} from "../services/CommonAPIService"
import {ErrorSource} from '../common/constants'
import { event } from 'jquery';
  

  function Registration() {
    const [Loading, setLoading] = useState(false);
    const [ShowHide, setShowHide] = useState(false);
    const [MobileNumber, setMobileNumber] = useState("");
    const [EmailId, setEmailId] = useState("");
    const [UserName, setUserName] = useState("");
    const [ProductFactor, setProductFactor] = useState("");
    const [OTP, setOTP] = useState("");
    const [OTPMatch, setOTPMatch] = useState("");

    const ProductApi = async () => {
        try {
            setLoading(true);
            let response = await commonAPI("CuroProducts")
            // console.log("commonapi",commonAPI)
            // let response = await fetch(`http://api-test-common.curonext.com/api/Common/Client`, {
            //     // let response = await fetch(`${apiURL}/DAR/GetDARData/${myUserData.userId}`, {
            //     method: "GET",
            //     headers: { 'Content-Type': 'application/json'},
            // })
            // response = await response.json();
            console.log("Table", response)
            if (response.success===true){
                console.log("Table", response)
                const productName = response.data.filter((products, index) => {
                    return products.productName.toUpperCase() === "CURONEXT"
                  })
                  console.log(productName)
                  setProductFactor(productName[0].primeProductCode)
                  console.log(productName[0].primeProductCode)
                setLoading(false);
            }else{
                alert("Something went wrong");
                setLoading(false);
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = JSON.stringify({ 
                "errorLogId": 0, // Need to be change
                "userId": 2, // Need to be change
                "mobileNumber": "9087654321", // Need to be change
                "requestJSON": "",
                "uri": "/api/Master/Notice",
                "errorNumber": "UN01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Registration.js",
                "functionName": "ProductApi",
            });
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response.status === 200 & response.message.length == null){
                alert("Something went wrong catch");
                setLoading(false); 
            }else{
                alert("Something went wrong catch");
                setLoading(false); 
            }
            
        }

    }

    useEffect(() => {
        ProductApi();
    }, [])

    const OtpApi = async () => {
        const bodydata = { 
            "templateKey": "OTP",
            "smsTo": MobileNumber,
            "appSource": "CuroNext",
            "placeHolderList": {
                "{#appname#}": "CuroNext",
                "{#otp#}": ""
            }
        };
        try {
            if(!MobileNumber){
                alert("Please enter mobile number");
            }else if(!UserName){
                alert("Please enter name");
            }else{
                console.log("body", bodydata);
                let response = await commonAPI("MobileOTP", bodydata)
                //   const response = await fetch(`http://api-test.curonext.com/api/Master/Client`, {
                //     // let response = await fetch(`${apiURL}/DAR/DARReportToHOD`, {  
                //       method: "POST",
                //       headers: { 'Content-Type': 'application/json'},
                //       body: bodydata
                    
                //   })
                //   const statusCode = response
                console.log("otp response", response.data);
                if(response.success == true & response.message.length == 0){
                    alert("OTP Sent to your mobile number");  
                    setOTPMatch(response.data)
                    setShowHide(true)
                }else{
                    alert(response.message[0]);
                    setShowHide(false)
                }
            }
            
        } catch (error) {
            setLoading(false);
            const bodyError = { 
                "errorLogId": 0, // Need to be change
                "userId": 2, // Need to be change
                "mobileNumber": MobileNumber, 
                "requestJSON": bodydata,
                "uri": "/api/Notification/MobileOTP",
                "errorNumber": "OTPR01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Registration.js",
                "functionName": "OtpApi",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response.status === 200 & response.message.length == null){
                alert("Something went wrong catch");
                setLoading(false); 
            }else{
                alert("Something went wrong catch");
                setLoading(false); 
            }
        }
        
          
          
    }

    const matchOTP = async () => {
        console.log("OTP", OTPMatch, OTP)
        if (OTPMatch == OTP){
            RegisterApi()
        }else{
            alert("Please enter vaild otp");
        }
    }

    const RegisterApi = async () => {
        const bodydata = { 
            "mobileNumber": MobileNumber,
            "emailId": EmailId,
            "userName": UserName,
            "productAccessFactor":  ProductFactor
          };
        try {
            console.log("body", bodydata);
            let response = await commonAPI("User", bodydata)
            //   const response = await fetch(`http://api-test.curonext.com/api/Master/Client`, {
            //     // let response = await fetch(`${apiURL}/DAR/DARReportToHOD`, {  
            //       method: "POST",
            //       headers: { 'Content-Type': 'application/json'},
            //       body: bodydata
                
            //   })
            //   const statusCode = response
            console.log("statusCode", response)
            if(response.success == true & response.message.length == 0){
                alert("User Added");  
                setShowHide(false)
            }else{
                alert(response.message[0]);
            }
            
        } catch (error) {
            setLoading(false);
            const bodyError = JSON.stringify({ 
                "errorLogId": 0, // Need to be change
                "userId": 2, // Need to be change
                "mobileNumber": "9087654321", // Need to be change
                "requestJSON": bodydata,
                "uri": "/api/Master/Notice",
                "errorNumber": "UN01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "UpdateNoticeData",
            });
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response.status === 200 & response.message.length == null){
                alert("Something went wrong catch");
                setLoading(false); 
            }else{
                alert("Something went wrong catch");
                setLoading(false); 
            }
        }
        
          
          
    }

     
    return ( 
            <>
               {Loading? 
                    <div className="loaderDiv">
                        <div className="loader"></div>
                    </div> 
                :null}

               <div className="register">
               {ShowHide ? 
                    <div className="login-box card">
                        <div className="card-body">
                            <div className="form-horizontal form-material">
                            <h3 className="signin_login">OTP</h3>
                                <div className="form-group login-btm">
                                    <div className="col-xs-12">
                                        <div className="label-float">
                                            <input type="text" value={OTP} onChange={(e) => {setOTP(e.target.value);}} placeholder=" "/>
                                            <label>Enter OTP</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="text-center">
                                    <div className="col-xs-12 p-b-20">
                                        <button className="btn btn-block btn-lg btn-info btn-rounded text-white" onClick={matchOTP} >Sumbit OTP</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div> 
                    :
                    
                    <div className="login-box card">
                        <div className="card-body">
                            <div className="form-horizontal form-material">
                                <h3 className="signin_login">Sign Up</h3>
                                <div className="form-group login-btm">
                                    <div className="col-xs-12">
                                        <div className="label-float">
                                            <input type="text" value={UserName} onChange={(e) => {setUserName(e.target.value);}} placeholder=" "/>
                                            <label>Enter Name</label>
                                        </div>
                                        <div className="label-float">
                                            <input type="text" value={MobileNumber} onChange={(e) => {setMobileNumber(e.target.value);}} placeholder=" "/>
                                            <label>Phone number</label>
                                        </div>
                                        <div className="label-float">
                                            <input type="text" value={EmailId} onChange={(e) => {setEmailId(e.target.value);}} placeholder=" "/>
                                            <label>Enter Email  </label>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="form-group login-btm">
                                    <div className="col-xs-12">

                                    <input type={password}onChange={onPasswordChange}value={passwordInput}          placeholder="Enter password" name="password" classNameName="form-control"
                />
                <Button onClick={toggle} classNameName='eyeopner'>
                {password === "password" ? (
                <FontAwesomeIcon icon={faEye} classNameName='eyeclr' />
                ) : (
                <FontAwesomeIcon icon={faEyeSlash} classNameName='eyeclr' />
                )}
                </Button>


                                        </div>
                                </div>
                                <div className="form-group row  login-btm">
                                    <div className="col-md-12">
                                        <div className="checkbox checkbox-info pull-left p-t-0">
                                            <input id="checkbox-signup" type="checkbox" className="filled-in chk-col-light-blue" />
                                            <label for="checkbox-signup"> Remember me </label>
                                        </div> <a href="javascript:void(0)" id="to-recover" className="text-dark pull-right">   <FontAwesomeIcon icon={faLock} size="xs" />&nbsp; Forgot password? </a> </div>
                                </div> */}
                                <div className="text-center">
                                    <div className="col-xs-12 p-b-20">
                                        <button className="btn btn-block btn-lg btn-info btn-rounded text-white" onClick={OtpApi} >Register</button>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 m-t-10 text-center">
                                        <div className="social social_dev">
                                            <a href="javascript:void(0)" className="btn  btn-facebook divsocial1" data-toggle="tooltip" title="" data-original-title="Login with Facebook"> <FontAwesomeIcon icon={faFacebookF} /> </a>
                                            <a href="javascript:void(0)" className="btn btn-googleplus divsocial2" data-toggle="tooltip" title="" data-original-title="Login with Google">  <FontAwesomeIcon icon={faGooglePlusG} /> </a>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="form-group m-b-0">
                                    <div className="col-sm-12 text-center">
                                        Already have a account? <a href="login" className="text-info m-l-5"><b>Sign In</b></a>
                                    </div>
                                </div>
                            </div>
                            <form className="form-horizontal" id="recoverform" action="index.html">
                                <div className="form-group ">
                                    <div className="col-xs-12">
                                        <h3>Recover Password</h3>
                                        <p className="text-muted">Enter your Email and instructions will be sent to you! </p>
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <div className="col-xs-12">
                                        <input className="form-control" type="text" required="" placeholder="Email" /> </div>
                                </div>
                                <div className="form-group text-center m-t-20">
                                    <div className="col-xs-12">
                                        <button className="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> 
                }
                </div>

                <p style={{ textAlign: 'center', marginTop: '-26px', marginBottom: '0px', fontWeight: '500'}}>  CuroNext © All Rights Reserved. 2024 </p>
            </>

    )
  }
  export default Registration