import React , {useEffect, useState} from 'react';
import { useNavigate, Link } from "react-router-dom";
import '../assets/css/menu.css';
import '../assets/css/custom.css';
import '../assets/css/animate.css';
import '../assets/css/font-awesome.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/themify-icons.css';
import '../assets/css/iconmind.css';
import headlogo1 from '../assets/img/logo-light-icon.png';
import headlogo2 from '../assets/img/logo-light-text.png';
import familyAvatar4 from '../assets/img/family-avatar-4.png';
import Button from 'react-bootstrap/Button';
import stopMark from '../assets/img/stop.png';
import Modal from 'react-bootstrap/Modal';
import bike from '../assets/img/bike.png';
import FlatIcon from '../assets/img/config-flat.png';
import userimg from '../assets/img/user.jpg';
import userimg1 from '../assets/img/user1.jpg';
import userimg2 from '../assets/img/user2.jpg';
import userimg3 from '../assets/img/user3.jpg';
import carousel1 from '../assets/img/img1.jpg';
import carousel2 from '../assets/img/img2.jpg';
import carousel3 from '../assets/img/img3.jpg';
import flag1 from '../assets/img/f1.jpg';
import flag2 from '../assets/img/f2.jpg';
import flag3 from '../assets/img/f3.jpg';
import flag4 from '../assets/img/f4.jpg';
import { PiUsersThree } from "react-icons/pi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch, faBell, faEnvelope, faTh, faFlag, faTachometer, faRocket, faFileAlt, faTable, faCertificate, faClose, faBars, faChevronRight, faLink, faCogs, faUser, faCheck, faHouseUser, faGears, faBuildingCircleCheck ,faFlagCheckered , faPersonCircleCheck, faArrowRightFromBracket, faUsers, faPowerOff, faBurger, faDashboard   } from '@fortawesome/free-solid-svg-icons';
import { faPage4, faUikit, faWpforms } from '@fortawesome/free-brands-svg-icons';
import { faCalendar } from '@fortawesome/fontawesome-free-solid';
// import { Link } from 'react-router-dom';
import curonext from '../assets/img/curonext.png';
import machineLogo from '../assets/img/machineLogo.png';
import { BsBuildings } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";
 
 
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
 
 
 
function Menus()
 
 
{
    const history = useNavigate();
    const [classname, setClassName] = useState(false)
    const [classname1, setClassName1] = useState(false)
    const [classname2, setClassName2] = useState(false)
    const [classname3, setClassName3] = useState(false)
    const [showSearch,setShowSearch] = useState(false)
    const [LogoutShow, setLogoutShow] = useState(false)
 
    const addAnimation = (linkPosition)=>{
     
        if(linkPosition==1){
            setClassName(!classname)
            console.log(linkPosition)
        }
        if(linkPosition==2){
            setClassName1(!classname1)
            console.log(linkPosition)
        }
        if(linkPosition==3){
            setClassName2(!classname2)
            console.log(linkPosition)
        }
        if(linkPosition==4){
            setClassName3(!classname3)
            console.log(linkPosition)
        }
       
    }
 
    const logout = () => {
        localStorage.clear();
        // navigate('/')
        history('/');
    }
 
    // function logout() {
    //     localStorage.clear();
    //     window.location.href = '/';
    // }
 
 
    function resetNotificationPopup(){
        if(classname1){
            setClassName1(false)
        }
        if(classname){
            setClassName(false)
        }
        if(classname2){
            setClassName2(false)
        }
                if(classname3){
            setClassName3(false)
        }
       
       
    }
 
    // function logout() {
    //     localStorage.clear();
    //  }
 
    function showSearchBar(){
        setShowSearch(true)
    }
    function hideSearchBar(){
        setShowSearch(false)
    }
    // const [classname2, setClassName2] = useState("")
    // const addAnimation2 = ()=>{
    //     setClassName2("show")
    // }
    return (
         
<>
<Navbar collapseOnSelect expand="lg" fixed='top' className="bg-body-tertiary fx_head">
      <Container fluid>
        <Navbar.Brand href="/"><img src={machineLogo} alt="homepage" className="dark-logo" style={{width: "40px",padding: '0px 0px 0px', borderRadius: 24}} /> </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Link to={"/"} className='text-white px-3'><FontAwesomeIcon icon={faDashboard} /><span className="hide-menu pl-1">Dashboard</span></Link>
            <Link to={"/Management"} className='text-white px-3'><PiUsersThree size={"20px"}/><span className="hide-menu pl-1">Client</span></Link>
            {/* <Link to="/Management" className={'text-black  nav-link d-flex align-items-center' + (pathname == '/Management' ? ' active-tab' : '')}><PiUsersThree size={"20px"}/><span className="hide-menu pl-1"> Management</span></Link> */}
            <Link to={"/Configuration"} className='text-white px-3'><BsBuildings size={"20px"}/>Company</Link> 
            {/* <Nav.Link href="Utilities" className='text-white px-3'></Nav.Link> */}
            <Link to={"/Reports"} className='text-white px-3'><AiOutlineFileText size={"20px"}/>Report </Link>
            {/* <Nav.Link href="Reports" className='text-white px-3'></Nav.Link> */}
          </Nav>
          <Nav>
           <NavDropdown title={<FontAwesomeIcon icon={faBars} />}  className='dropName navbar-light' id="nav-dropdown">
              <NavDropdown.Item eventKey="4.1"><FontAwesomeIcon icon={faUser} /> Profile </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.2"><FontAwesomeIcon icon={faArrowRightFromBracket} /> Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
{/* <div className='card-no-border mini-sidebar upper_fixed'
 onClick={()=>resetNotificationPopup()}
 >
<header className="topbar">
            <nav className="navbar top-navbar navbar-expand-md navbar-light">
 
               
                <div className="navbar-collapse" onClick={()=>resetNotificationPopup()}>
                     
                    <ul className="navbar-nav mr-auto">
                       
                        <li className="nav-item"> <a className="nav-link nav-toggler hidden-md-up waves-effect waves-dark" href="#"><FontAwesomeIcon className="menu_bars" icon={faBars} /></a> </li>
                        <li className="nav-item hidden-sm-down"><span></span></li>
                    </ul>
                   
                    <ul className="navbar-nav my-lg-0">
 
                         
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ><img src={familyAvatar4} alt="user" className="profile-pic" /></a>
                            <div className="dropdown-menu dropdown-menu-right animated flipInY">
                                <ul className="dropdown-user">
                                    <li>
                                        <div className="dw-user-box">
                                            <div className="u-img"><img src="../assets/images/users/1.jpg" alt="user" /></div>
                                            <div className="u-text">
                                                <h4>Steave Jobs</h4>
                                                <p className="text-muted">varun@gmail.com</p><a href="pages-profile.html" className="btn btn-rounded btn-danger btn-sm">View Profile</a></div>
                                        </div>
                                    </li>
                                    <li role="separator" className="divider"></li>
                                    <li><a href="#"><i className="ti-user"></i> My Profile</a></li>
                                    <li><a href="#"><i className="ti-wallet"></i> My Balance</a></li>
                                    <li><a href="#"><i className="ti-email"></i> Inbox</a></li>
                                    <li role="separator" className="divider"></li>
                                    <li><a href="#"><i className="ti-settings"></i> Account Setting</a></li>
                                    <li role="separator" className="divider"></li>
                                    <li><a href="#" onClick={logout}><i className="fa fa-power-off"></i> Logout</a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
 </header>
 
 
<aside className="left-sidebar">
    <div className="scroll-sidebar">
        <nav className="sidebar-nav">
 
                 <div className="navbar-header">
                    <a className="navbar-brand" href="#" style={{marginBottom:0,}}>
 
                       
                       <span>
                            <img src={curonext} alt="homepage" className="dark-logo" style={{width: "80%",padding: '10px 0px 5px'}} />
                       </span> </a>
                </div>
               
 
            <ul id="sidebarnav">
                <li className="nav-devider"></li>
       
                <li>
                    <Link className="has-arrow waves-effect waves-dark" to={"/Configuration"} aria-expanded="false"><FontAwesomeIcon icon={faGears} /><span className="hide-menu"> Configration</span></Link>
                   
                </li>
 
                <li>
                    <Link className="has-arrow waves-effect waves-dark" to={"/Management"} aria-expanded="false"><FontAwesomeIcon icon={faUsers} /><span className="hide-menu"> Management</span></Link>
                     
                </li>
 
                <li>
                    <a className="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><FontAwesomeIcon icon={faBuildingCircleCheck} /><span className="hide-menu"> Utility</span></a>
                 
                </li>
               
                <li>
                    <Link className="has-arrow waves-effect waves-dark" to={"/Approval"} aria-expanded="false"><FontAwesomeIcon icon={faPersonCircleCheck} /><span className="hide-menu"> Approval Central </span></Link>
                   
                </li>
                <li>
                    <a className="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><FontAwesomeIcon icon={faFlagCheckered} /><span className="hide-menu"> Reports</span></a>
                       
                </li>
            </ul>
        </nav>
 
 
 
        <div className="dropdown">
        <a className="dropdown-toggle float-right" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ><img src={familyAvatar4} alt="user" style={{ width: '50px',    padding: '7px 5px',}}className="profile-pic" /></a>
        <ul className="dropdown-content">
    <li><a href="OEEReport.aspx" className="color"><FontAwesomeIcon icon={faUser} /> Profile</a></li>
    <li><button  onClick={() => setLogoutShow(true)} className="color" ><FontAwesomeIcon icon={faArrowRightFromBracket} />Logout</button></li>
  </ul>
  </div>
    </div>
</aside>
 
<Modal centered show={LogoutShow} onHide={() => setLogoutShow(false)} dialogclassName="modal-100w" aria-labelledby="contained-modal-title-vcenter ">
    <Modal.Header className="headerMDL" closeButton>
        <Modal.Title id="contained-modal-title-vcenter" centered >
            <h4 className="mb-0"> Logout confirmation </h4>
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="row" id="validation">
            <div className="col-12">
                <div className="FnFItem">
                 
                <div className="FnFtxt">
                    <h3> Are your Sure You want to logout? </h3>
                </div>
                </div>
                <div className='confirmation_btn'>
                <div className='both_ConfrmBTN' onClick={() => logout()}>
                    <Button variant="danger">Ok</Button>{' '}
                </div>
                <div className='both_ConfrmBTN' onClick={() => setLogoutShow(false)} >
                    <Button variant="light"> Cancel</Button>{' '}
                </div>
                </div>
            </div>
        </div>
    </Modal.Body>
</Modal>  
</div> */}
</>
)
} export default Menus;