import React, { useEffect, useRef, useState } from "react";
import "../assets/css/custom.css";
import "../assets/css/client.css";
import "../assets/css/font-awesome.css";
import "../assets/css/sweetalert.css";
// import { Users, Star } from "react-feather";
import "../assets/css/bootstrap.min.css";
import "../assets/css/steps.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faDoorOpen,
  faEdit,
  faPlus,
  faSort,
  faTowerObservation,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import clientlogo from "../assets/img/user1.jpg";
import clientlogo2 from "../assets/img/user2.jpg";
import clientlogo3 from "../assets/img/user3.jpg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import client from "../assets/img/client.png";
import building from "../assets/img/building.png";
import office from "../assets/img/office.png";
import apartment from "../assets/img/apartment.png";
import gate from "../assets/img/gate.png";
import machine from "../assets/img/engineering.png"
import Client from "./client";
import Menus from "./menus";
import Building from "./building";
import Tower from "./ClientCompany";
import Flat from "./flat";
import Gate from "./Gate";
import ClientMachine from "./clientMachine";
import ClientCompany from "./ClientCompany";
import ClientMachinePage from "./building";
import ClientSpare from "./client";
import ClientDepartment from "./flat";
import ClientEmploy from "./Gate";

function Configuration() {
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const [reset, setReset] = useState(0);

  return (
    <>
      <Menus />
      <section className="tabular_intergration">
        <div className="tab-container">
            <div className="UnderBox-Shadow">
              <div className="container-fluid py-0 px-0">
                  <div className="row">
                    <div className="col-lg-12 col-dm-12 col-sm-12 col-12">
                        <div className="tabs">
                          <button
                          className={activeTab === "tab1" ? "active-tab" : ""}
                          onClick={() => handleTabChange("tab1")}
                          >
                            <img src={client} className="img" style={{ width: "21px" }} />{" "}
                          
                            Company
                          </button>
                          
                          <button
                          className={activeTab === "tab2" ? "active-tab" : ""}
                          onClick={() => handleTabChange("tab2")}
                          >
                          <img src={machine} className="img" style={{ width: "24px" }} />{" "}
                          Machine
                          </button>
                          <button
                          className={activeTab === "tab3" ? "active-tab" : ""}
                          onClick={() => handleTabChange("tab3")}
                          >
                          <img src={gate} className="img" style={{ width: "20px" }} />{" "}
                          Inventory
                          </button>
                          <button
                          className={activeTab === "tab4" ? "active-tab" : ""}
                          onClick={() => handleTabChange("tab4")}
                          >
                          <img src={apartment} className="img" style={{ width: "19px" }} />{" "}
                          Department
                          </button>
                          <button
                          className={activeTab === "tab5" ? "active-tab" : ""}
                          onClick={() => handleTabChange("tab5")}
                          >
                          <img src={gate} className="img" style={{ width: "20px" }} /> Employee
                          </button>
                          {/* <button
                          className={activeTab === "tab6" ? "active-tab" : ""}
                          onClick={() => handleTabChange("tab6")}
                          >
                            <img src={office} className="img" style={{ width: "24px" }} />{" "}
                           Company
                          </button> */}
                          {/* <button
                          className={activeTab === "tab6" ? "active-tab" : ""}
                          onClick={() => handleTabChange("tab6")}
                          >
                          <img src={gate} className="img" style={{ width: "20px" }} /> Report
                          </button> */}
                          
                        </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          </section>
       
       <section className="whole_bColor">
            <div className="container-fluid">
              <div className='row'>
                  <div className="col-12 ">
             
                        <div>
                          {activeTab === "tab1" && (
                          <div>
                            <ClientCompany />
                          </div>
                          )}
                          {activeTab === "tab2" && (
                          <div>
                              <ClientMachinePage />
                          </div>
                          )}
                          {activeTab === "tab3" && (
                          <div>
                              <ClientSpare />
                          </div>
                          )}
                          {activeTab === "tab4" && (
                          <div>
                              <ClientDepartment />
                          </div>
                          )}
                          {activeTab === "tab5" && (
                          <div>
                              <ClientEmploy />
                          </div>
                          )}
                          {/* {activeTab === "tab6" && (
                          <div>
                              <Tower />
                          </div>
                          )} */}
                          {/* {activeTab === "tab6" && (
                          <div>
                              <Gate />
                          </div>
                          )} */}
                        </div>
                     
                  </div>
              </div>
            </div>
            </section>            
    </>
  );
}
export default Configuration;
