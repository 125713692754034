import React, { useEffect, useRef, useState } from "react";
import "../assets/css/custom.css";
import "../assets/css/client.css";
import "../assets/css/font-awesome.css";
import "../assets/css/sweetalert.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/steps.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faPlus,
    faSort,
    faTrash,
    faPenToSquare,
    faTimes,
    faAdd,
    faLocation,
    faLocationPin,
  } from "@fortawesome/free-solid-svg-icons";
import clientlogo from "../assets/img/user1.jpg";
import clientlogo2 from "../assets/img/user2.jpg";
import Nodata from "../assets/img/NoData.png";
import clientDemoImg from "../assets/img/EmptyLogo.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { commonAPI } from "../services/CommonAPIService";
import Menus from "./menus";
// import { Wizard, Steps, Step, WithWizard } from "react-multistep-wizard";
// import SlideButton from "react-slide-button";
import { Stepper, Step, StepLabel } from "@mui/material";
// import  clientimg  from '../assets/img/clientimg.jpg';
// import  clientimg2  from '../assets/img/clientimg-2.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import INDFlag from "../assets/img/INDFlag.jpg";
import filepreview from '../assets/img/filepreview.png';
import { ErrorSource } from "../common/constants";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
 import switchBaseClasses from "@mui/material/internal/switchBaseClasses";
 import { styled } from '@mui/material/styles';
 import FormGroup from '@mui/material/FormGroup';
 import FormControlLabel from '@mui/material/FormControlLabel';
 import noticeuserupload from '../assets/img/noticeuserupload.png';
 import Switch, { SwitchProps } from '@mui/material/Switch';
 import Stack from '@mui/material/Stack';
 import Typography from '@mui/material/Typography';
 import JoditEditor from 'jodit-react';
 import Form from 'react-bootstrap/Form';
 import previewnew from '../assets/img/previewnew.jpg';
 import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Table from 'react-bootstrap/Table'; 
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

function ReportMachine() {
    const [fullscreen, setFullscreen] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const steps = ["Step 1", "Step 2", "Step 3"];
    const [show, setShow] = useState(false);
    const [showFirstModal,setShowFirstModal] = useState(false)
    const [Loading, setLoading] = useState(false);
    const [NoticeList, setNoticeList] = useState("");
    const [NoticeSubject, setNoticeSubject] = useState("");
    const [NoticeDescription, setNoticeDescription] = useState("");
    const [NoticeEmail, setNoticeEmail] = useState();
    const [NoticeId, setNoticeId] = useState();
    const [Importance, setImportance] = useState();
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const [NoticeType, setNoticeType] = useState("");
    const [NoticeTypeId, setNoticeTypeId] = useState();
    const [NoticeTypeText, setNoticeTypeText] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageShow, setSelectedImageShow] = useState(null);
    const [selectedImageTwo, setSelectedImageTwo] = useState(null);
    const [selectedImageTwoShow, setSelectedImageTwoShow] = useState(null);
    const [firstSectionButton, setFirstSectionButton] = useState(false);
    const [UserData, setUserData] = useState("");
    const [AddNoticeBtn, setAddNoticeBtn] = useState(false);
    const [UpdateNoticeBtn, setUpdateNoticeBtn] = useState(false);


    const openCreateModal = () => {
        setShow(true);
        setAddNoticeBtn(true);
        setUpdateNoticeBtn(false);
        setNoticeSubject("");
        setNoticeDescription("");
        setNoticeEmail("");
        setImportance("");
        setStartDate("");
        setEndDate("");
        setSelectedImage("");
        setSelectedImageTwo("");
      };

      const openCreateModalagain = () => {
        setShowFirstModal(true);
        setAddNoticeBtn(true);
        setUpdateNoticeBtn(false);
        setNoticeSubject("");
        setNoticeDescription("");
        setNoticeEmail("");
        setImportance("");
        setStartDate("");
        setEndDate("");
        setSelectedImage("");
        setSelectedImageTwo("");
      };

      const editor = useRef(null);
      const [content, setContent] = useState('');
      const handleFileInputChangeTwo = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            const encodedString = btoa(reader.result);
            setSelectedImageTwo(reader.result);
            setSelectedImageTwoShow(encodedString);
          };
          reader.readAsDataURL(file);
        }
      };    

 

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const [state, setState] = React.useState({
        gilad: true,
        jason: false,
        antoine: false,
      });
    
      // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      //   setState({
      //     ...state,
      //     [event.target.name]: event.target.checked,
      //   });
      // };
    
      const { DLF, Magnolias, Bestech ,Kraheja } = state;
      const error = [DLF, Magnolias, Bestech ,Kraheja].filter((v) => v).length !== 2;
    
  return (
    <> 
        <Menus/>
        <div className="container-fluid">
            <div className="row mb-1" style={{ alignItems:'center' }}>
                <div className="col-md-4 mt-5 pt-2">
                  <div className="HeadingTop">
                      <h3> Reports  </h3>
                      <span> List of Reports</span>
                  </div>
                </div>
             </div>  
             <div className="row mb-0" style={{ alignItems:'center' }}>
                <div className="col-md-2 mt-0 pt-2">
                  <div className="HeadingTop mt-0">
                      <span> Start Date</span>
                      <input type="date" id="birthday" name="birthday" className="form-control required"/>
                  </div>
                </div>
                <div className="col-md-2 mt-0 pt-2">
                  <div className="HeadingTop mt-0">
                      <span> End Date</span>
                      <input type="date" id="birthday" name="birthday" className="form-control required"/>
                  </div>
                </div>
                <div className="col-md-2 mt-0 pt-2">
                  <div className="HeadingTop mt-0">
                      <span> Status</span>
                      <select name="cars" id="cars" className="form-control required">
                      <option value="volvo">All</option>
                        <option value="volvo">AMC</option>
                        <option value="saab">2nd Visit</option>
                        <option value="mercedes">Warranty</option>
                        <option value="audi">Single Visit</option>
                      </select>
                  </div>
                </div>
                <div className="col-md-2 mt-0 pt-2">
                  <div className="HeadingTop mt-0">
                      <span> Clients</span>
                      <select name="cars" id="cars" className="form-control required">
                      <option value="volvo">All</option>
                        <option value="volvo">Client 1</option>
                        <option value="saab">Client 2</option>
                        <option value="mercedes">Client 3</option>
                        <option value="audi">Client 4</option>
                      </select>
                  </div>
                </div>
                <div className="col-md-2 mt-0 pt-2">
                  <div className="HeadingTop mt-0">
                      <span> Projects</span>
                      <select name="cars" id="cars" className="form-control required">
                      <option value="volvo">All</option>
                        <option value="volvo">Project 1</option>
                        <option value="saab">Project 2</option>
                        <option value="mercedes">Project 3</option>
                        <option value="audi">Project 4</option>
                      </select>
                  </div>
                </div>
             </div>  
             <div className="row">
             <Table striped responsive="lg">
                <thead>
                  <tr>
                    
                    <th>Client Name</th>
                    <th>Project Name</th>
                    <th>Machine Name</th>
                    <th>Serial No</th>
                    <th>Status</th>
                    <th>Due Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Sharma Textile</td>
                    <td >Test Block (60.7,33.6 HRC)</td>
                    <td >Test Block (60.7,33.6 HRC)</td>
                    <td>1</td>
                    <td>AMC</td>
                    <td>30-09-2024</td>
                    
                  </tr>
                  <tr>
                
                  <td>Singh Transport</td>
                    <td >Test Block (60.7,33.6 HRC)</td>
                    <td >Test Block (60.7,33.6 HRC)</td>
                    <td>2</td>
                    <td>Warranty</td>
                    <td>29-09-2024</td>
                  </tr>
                  <tr>
                
                  <td>Tyagi Textile</td>
                    <td >Test Block (60.7,33.6 HRC)</td>
                    <td >Test Block (60.7,33.6 HRC)</td>
                    <td>3</td>
                    <td>Single Visit</td>
                    <td>30-09-2024</td>
                  </tr>
                  <tr>
                
                  <td>Pandit WorkShop</td>
                    <td >Test Block (60.7,33.6 HRC)</td>
                    <td >Test Block (60.7,33.6 HRC)</td>
                    <td>4</td>
                    <td>2ND Visit</td>
                    <td>30-09-2024</td>
                  </tr>
                  <tr>
                
                    <td>Sharma Textile</td>
                    <td >Test Block (60.7,33.6 HRC)</td>
                    <td >Test Block (60.7,33.6 HRC)</td>
                    <td>5</td>
                    <td>AMC</td>
                    <td>30-09-2024</td>
                </tr>
                
              
                </tbody>

              </Table>
             </div>
        </div>    
  
</>
  );
}
export default ReportMachine;
