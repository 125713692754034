import React , {useEffect, useRef, useState} from 'react'; 
import '../assets/css/custom.css'; 
import '../assets/css/client.css'; 
import '../assets/css/font-awesome.css';
import '../assets/css/sweetalert.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/steps.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import {
    faEdit,
    faPlus,
    faSort,
    faTrash,
    faPenToSquare,
    faTimes,
    faTowerBroadcast,
    faScrewdriver,
    faScrewdriverWrench,
    faMessage,
  } from "@fortawesome/free-solid-svg-icons";
// import { faEdit, faPlus, faSort } from '@fortawesome/free-solid-svg-icons';
import clientlogo from '../assets/img/user1.jpg'; 
import clientlogo2 from '../assets/img/user2.jpg'; 
import clientlogo3 from '../assets/img/user3.jpg'; 
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import ReactDOM from "react-dom";
// import { Wizard, Steps, Step, WithWizard } from "react-multistep-wizard";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { Stepper, Step, StepLabel } from '@mui/material';
import { Textarea } from '@material-tailwind/react';
import { Circle } from '@mui/icons-material';
import {commonAPI} from "../services/CommonAPIService"
import {ErrorSource} from '../common/constants'
import Menus from "./menus";
import Multiselect from 'multiselect-react-dropdown';
import Action from '../assets/img/Action.png';
import BroadCastImg from '../assets/img/megaphones.png';
import chat from '../assets/img/bubble-chat.png';
import JoditEditor from 'jodit-react';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';


  function UserApproval() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Step 1', 'Step 2', 'Step 3'];
    const [Show, setShow] = useState(false);
    const [ApproveShow, setApproveShow] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [VehiclePendingList, setVehiclePendingList] = useState("");
    const [TowerListGet, setTowerListGet] = useState("");
    const [FlatListGet, setFlatListGet] = useState("");
    const [NoticeSubject, setNoticeSubject] = useState("");
    const [NoticeDescription, setNoticeDescription] = useState("");
    const [Importance, setImportance] = useState();
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const [NoticeStatus, setNoticeStatus] = useState();
    const [NoticeTypeText, setNoticeTypeText] = useState("");
    const [NoticeId,setNoticeId] = useState("");
    const [firstSectionButton,setFirstSectionButton] = useState(false);
    const [CurrentStatusSelected,setCurrentStatusSelected] = useState("PENDING");
    const [UserData, setUserData] = useState("");
    const [TowerSelected, setTowerSelected] = useState([]);
    const [FlatSelected, setFlatSelected] = useState([]);
    const [VehicleImages, setVehicleImages] = useState("");
    const [UserName, setUserName] = useState("");
    const [EmailId, setEmailId] = useState("");
    const [BuildingName, setBuildingName] = useState("");
    const [Address, setAddress] = useState("");
    const [City, setCity] = useState("");
    const [State, setState] = useState("");
    const [Pincode, setPincode] = useState("");
    const [UserMappingId, setUserMappingId] = useState("");
    const [UserId, setUserId] = useState("");
    const [ApproverComment, setApproverComment] = useState([]);
    const [ApproverCommentInput, setApproverCommentInput] = useState("");

    const [fullscreen, setFullscreen] = useState(true);
    const editor = useRef(null);
    const [content, setContent] = useState('');

    const convertISTTime = (date) => {
        if(date != null || date != "" || date != undefined ){
            const [datePart, TimePart] = date.split(" ");
            const [mm, dd, yyyy ] = datePart.split("/");
            // const [hh, mn, ss] = TimePart.split(':')
            const formatedTime = `${dd}-${mm}-${yyyy}`;
            return formatedTime;
        }else{
            console.warn("input date string may be null, empty string or undefined");
        }
    }

    const convertISSet = (date) => {
        if(date != null || date != "" || date != undefined ){
            let [datePart, TimePart] = date.split(" ");
            let [mm, dd, yyyy ] = datePart.split("/");
            // const [hh, mn, ss] = TimePart.split(':')
            // if(mm<10){mm.padStart(2, "0")};
            // if(dd<10){dd.padStart(2, "0")};
            if(mm<10){
               mm = "0"+mm
            }
            if(dd<10){
               dd = "0"+dd
            }
            const formatedTime = `${yyyy}-${mm}-${dd}`;
            return formatedTime;
        }else{
            console.warn("input date string may be null, empty string or undefined");
        }
    }

    const endPointVariableKey = {
        urlVariable:null,
        buildingId: 33
    }
    
    const GetPendingVehicleList = async () => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        setUserData(myUserData);
        console.log("myuserData", myUserData)
        try {
            
            const endPointVariableKey = `${myUserData.buildingId}/${CurrentStatusSelected}/`
            let response = await commonAPI("CuroNextUserMappingStatus", undefined, endPointVariableKey)
            // console.log("Notice List", response?.data)
            if (response?.success === true){
                console.log("UserList====================", response)
                setVehiclePendingList(response?.data);
            }else{
                toast.error("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": myUserData?.userId,
                "mobileNumber": myUserData?.mobileNumber, // Need to be change
                "requestJSON": "",
                "uri": "/api/Master/Notice",
                "errorNumber": "PV01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "VehicleApproval.js",
                "functionName": "GetPendingVehicleList",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true && response?.message?.length == null){
                toast.error("Something went wrong");
                setLoading(false); 
            }else{
                toast.error("Something went wrong");
                setLoading(false); 
            }
        }

    }

    useEffect(() => {
        GetPendingVehicleList();
    }, [CurrentStatusSelected])

    const GetVehicleImages = async () => {
        const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
        const endPointVariableKey    = {
            // urlVariable: VehicleId,
            buildingId: myUserData?.buildingId
        }
        try {
            setLoading(true);
            let response = await commonAPI("ResidentVehicleImage", undefined, endPointVariableKey)
            if (response?.success===true && response?.message?.length == 0){
                console.log("Vehicle Get Images", response)
                setVehicleImages(response?.data)
                setLoading(false);
            }else{
                setLoading(false);
                toast.error("Something went wrong1111");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": UserData?.userId,
                "mobileNumber": UserData?.mobileNumber, // Need to be change
                "requestJSON": null,
                "uri": "/api/Master/Notice/Images",
                "errorNumber": "NI01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "GetNoticeImages",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true && response?.message?.length == 0){
                toast.error("Something went wrong ");
                setLoading(false); 
            }else{
                toast.error("Something went wrong ");
                setLoading(false); 
            }
        }

    }

    // useEffect(() => {
    //     if(VehicleId){
    //         GetVehicleImages();
    //     }    
    // }, [VehicleId])    

    const GetPublishList = async () => {
        try {
            const auth = localStorage.getItem("userData");
            const myUserData = JSON.parse(auth);
            setUserData(myUserData);
            console.log("myuserData", myUserData)
            const endPointVariableKey = {
                urlVariable:NoticeId,
                buildingId: myUserData.buildingId
            }
            let response = await commonAPI("NoticeRecipient", undefined, endPointVariableKey)
            console.log("Notice Recipient List", response?.data)
            if (response?.success === true){
                // console.log("Notice Recipient", response);
                const selectedTowers = [];
                const noticeRecipientTransitData = response?.data?.noticeRecipientTransitData;
                // console.log("selected=================", TowerListGet);
                
                for(var k=0;k<noticeRecipientTransitData.length;k++){
                    for(var l=0;l<TowerListGet.length;l++){
                        if(noticeRecipientTransitData[k].towerId ==  TowerListGet[l].towerId){
                            var exists=false;
                            for(var m=0;m<selectedTowers.length;m++){
                                if(selectedTowers[m].towerName==TowerListGet[l].towerName){
                                    exists=true;
                                    break;
                                }
                            }
                            if(exists==false){
                                const selectedTowersObj={};
                                selectedTowersObj.towerId = noticeRecipientTransitData[k].towerId ;
                                selectedTowersObj.towerName = TowerListGet[l].towerName ;
                                selectedTowers.push(selectedTowersObj);
                            }
                            break;
                        }
                    }
                }
                // console.log("selected=================", noticeRecipientTransitData,  selectedTowers, TowerListGet);
                const tempData = selectedTowers?.map((item) => {return {value: item?.towerId, label: item?.towerName}});

                const selectedFlat = [];
                setTowerSelected(tempData);
                console.log("selected=================", tempData);
                
                for(var k=0;k<noticeRecipientTransitData.length;k++){
                    for(var l=0;l<FlatListGet.length;l++){
                        if(noticeRecipientTransitData[k].flatId ==  FlatListGet[l].flatId){
                            var exists=false;
                            for(var m=0;m<selectedFlat.length;m++){
                                if(selectedFlat[m].flatName==FlatListGet[l].flatName){
                                    exists=true;
                                    break;
                                }
                            }
                            if(exists==false){
                                const selectedFlatObj={};
                                selectedFlatObj.flatId = noticeRecipientTransitData[k].flatId ;
                                selectedFlatObj.flatName = FlatListGet[l].flatName ;
                                selectedFlat.push(selectedFlatObj);
                            }
                            break;
                        }
                    }
                }
                console.log("selected=================", noticeRecipientTransitData,  selectedFlat, selectedFlat);
                const tempDataFlat = selectedFlat?.map((item) => {return {value: item.flatId, label: item.flatName, towerId: item?.towerId}});
                setFlatSelected(tempDataFlat);
                
                
                
                
                

                
            }else{
                toast.error("Something went wrong");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            const bodyError = { 
                "userId": UserData?.userId,
                "mobileNumber": UserData?.mobileNumber, // Need to be change
                "requestJSON": "",
                "uri": "/api/Master/Notice",
                "errorNumber": "NL01",
                "errorSource": ErrorSource,
                "errorSummary": error?.message,
                "errorDescription": error?.message,
                "fileClassName": "Noticemanagement.js",
                "functionName": "GetNoticeList",
            };
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response?.success === true & response?.message?.length == null){
                toast.error("Something went wrong");
                setLoading(false); 
            }else{
                toast.error("Something went wrong");
                setLoading(false); 
            }
        }

    }

    // useEffect(() => {
    //     if (VehicleId){
    //         GetPublishList();
    //         // toast.error("publice Fuc Call");
    //     }
    // }, [VehicleId])
 

    const ApproveModal = async(item) => {
        setApproveShow(true);
        
        console.log("itemApproveModal", item)
        setUserName(item?.userName)
        setEmailId(item?.emailId)
        setBuildingName(item?.buildingName)
        setAddress(item?.addressLine1 + item?.addressLine2)
        setCity(item?.city)
        setState(item?.state)
        setPincode(item?.pinCode)
        setUserMappingId(item?.userMappingId)
        setUserId(item?.userId)
        const endPointVariableKey = `${item?.userId}/${item?.userMappingId}`
        let responseMapping = await commonAPI("CuroNextUserMappingComment", undefined, endPointVariableKey)
        const tempData = responseMapping?.data
        console.log("tempData", tempData)
        const approverComment = tempData[0]?.approverComment?.split("\r\n")
        console.log("approverComment", approverComment)
        setApproverComment(approverComment)
        setApproverCommentInput("")
    }

    const ApproveAPi = async(e) => {
        const auth = localStorage.getItem("userData");
        const myUserData = JSON.parse(auth);
        const endPointVariableKey = {
            urlVariable:null,
            buildingId: myUserData.buildingId
        }
        setApproveShow(true);
        console.log("e value", e)
        const bodydata = { 
            // "vehicleId": VehicleId,
            "userMappingId": UserMappingId,
            "userId": UserId,
            "CurrentStatus": e,
            "ApproverComment": ApproverCommentInput,
            "ApproverUserName": myUserData.userName,
            "modifiedUserId": myUserData.userName
          };
        try {
            setLoading(true);
            if(!ApproverCommentInput){
                toast.warning("Please enter Comment"); 
            }else{
                console.log("body", bodydata);
                let response = await commonAPI("CuroNextUserApproveDeny", bodydata, endPointVariableKey)
                //   const response = await fetch(`http://api-test.curonext.com/api/Master/Building/`, {
                //     // let response = await fetch(`${apiURL}/DAR/DARReportToHOD`, {  
                //       method: "DELETE",
                //       headers: { 'Content-Type': 'application/json'},
                //       body: bodydata
                    
                //   })
                //   const statusCode = response
                console.log("VehicleNotice", response)
                if(response?.success === true && response?.message.length == 0){
                    
                    // setApproveShow(false);
                    if(e == "PENDING"){
                        setLoading(false);
                        const endPointVariableKey = `${UserId}/${UserMappingId}`
                        let responseMapping = await commonAPI("CuroNextUserMappingComment", undefined, endPointVariableKey)
                        const tempData = responseMapping?.data
                        console.log("tempData", tempData)
                        const approverComment = tempData[0]?.approverComment?.split("\r\n")
                        console.log("approverComment", approverComment)
                        setApproverComment(approverComment)
                        setApproverCommentInput("")
                        toast.success(`Comment Added`);
                        // toast.success(`Vehicle ${e}`);  
                    }else if(e == "APPROVE") {
                        setLoading(false);
                        const endPointVariableKey = `${UserId}/${UserMappingId}/`
                        let responseMapping = await commonAPI("CuroNextUserMappingComment", undefined, endPointVariableKey)
                        const tempData = responseMapping?.data
                        console.log("tempData", tempData)
                        const approverComment = tempData[0]?.approverComment?.split("\r\n")
                        console.log("approverComment", approverComment)
                        setApproverComment(approverComment)
                        setApproverCommentInput("")
                        toast.success(`Vehicle ${e}`); 
                        setApproveShow(false);
                    }else{
                        setLoading(false);
                        const endPointVariableKey = `${UserId}/${UserMappingId}/`
                        let responseMapping = await commonAPI("CuroNextUserMappingComment", undefined, endPointVariableKey)
                        const tempData = responseMapping?.data
                        console.log("tempData", tempData)
                        const approverComment = tempData[0]?.approverComment?.split("\r\n")
                        console.log("approverComment", approverComment)
                        setApproverComment(approverComment)
                        setApproverCommentInput("")
                        toast.success(`Vehicle ${e}`);  
                        setApproveShow(false);
                    }
                    

                }else{
                    toast.error(response.message[0]);
                    setLoading(false);
                }
            }    
        } catch (error) {
            setLoading(false);
            const bodyError = JSON.stringify({ 
                "userId": UserData.userId,
                "mobileNumber": UserData.mobileNumber, // Need to be change
                "requestJSON": bodydata,
                "uri": "/api/Vehicle/ApproveResidentVehicle",
                "errorNumber": "AV01",
                "errorSource": ErrorSource,
                "errorSummary": error.message,
                "errorDescription": error.message,
                "fileClassName": "VehicleApproval.js",
                "functionName": "ApproveAPi",
            });
            let response = await commonAPI("ErrorLogMaster", bodyError)
           
            if(response.success === true && response.message.length == 0){
                toast.error("Something went wrong");
                setLoading(false); 
            }else{
                toast.error("Something went wrong");
                setLoading(false); 
            }
            
        }
        
    }
  
    const handleNext = () => {
       
        setActiveStep((prevActiveStep) => {
            if (prevActiveStep >= steps.length - 1) { // Assuming 'steps' is your array of step labels
                setFirstSectionButton(false)
                console.log(prevActiveStep)
                return 0; // Reset to first step
            } else {
                // console.log(inpRef.current.value)
                setFirstSectionButton(true)
                console.log(prevActiveStep)
                 return prevActiveStep + 1;
                
            }
        });
    };
  
    const handleBack = () => {
        
        setFirstSectionButton(false)
      setActiveStep((prevActiveStep) => {
        if(prevActiveStep==1){
            setFirstSectionButton(false)
            console.log(prevActiveStep)
            return prevActiveStep - 1
        }
        else{
            setFirstSectionButton(true)
            return prevActiveStep - 1
        }
       
    });
    };

    



    const [file, setFile] = useState();
    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }


     return (
    <>
    {Loading? 
        <div className="loaderDiv">
            <div className="loader"></div>
        </div> 
    :null}
    <ToastContainer />
    {/* <Menus /> */}
    <section id="client-builder">
   <div className="container-fluid pt-2">
   <div className="row mb-3 mt-5 pt-2" style={{alignItem:'center'}}>
            <div className="col-lg-6 col-md-6 px-0">
                <div className="HeadingTop">
                    <h3> User Approval </h3>
                    <span> List of Curo Solution User Approval</span>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 px-0 text-right" style={{alignContent:'center'}}>
              <select size="md" className="sltareamodal col-4 ml-auto bg-white"  onChange={(e) => {
                            console.log("selection", e.target.value)
                            setCurrentStatusSelected(e.target.value);
                        }
                            }>
                <option value="PENDING">Pending</option>
                <option value="APPROVED">Approved</option>
                <option value="REJECTED">Rejected</option>
              </select>
            </div>
        </div>
    

      {/*  Approve/Reject Modal */}
      <Modal fullscreen={fullscreen}  show={ApproveShow} onHide={() =>
         setApproveShow(false)} className="modal-100w  modal-fullscreen" aria-labelledby="example-custom-modal-styling-title">
         <Modal.Header className="headerMDL" closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
               Approve User
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className="row" id="validation">
                <div className="col-12">
                    <form
                    action="#"
                    className="validation-wizard wizard-circle wizard clearfix"
                    role="application"
                    id="steps-uid-0"
                    novalidate="novalidate">
                        <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                    <div className="form-group">
                                        <label for="wfirstName2">
                                        {" "}
                                        Name {" "}
                                        {/* <span className="danger">*</span>{" "} */}
                                        </label>
                                        <input type="text" className="form-control required" disabled value={UserName} />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                    <div className="form-group">
                                        <label for="wfirstName2">
                                        {" "}
                                        EmailId {" "}
                                        {/* <span className="danger">*</span>{" "} */}
                                        </label>
                                        <input type="text" className="form-control required" disabled value={EmailId} />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                    <div className="form-group">
                                        <label for="wfirstName2">
                                        {" "}
                                        Building Name {" "}
                                        {/* <span className="danger">*</span>{" "} */}
                                        </label>
                                        <input type="text" className="form-control required" disabled value={BuildingName} />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                    <div className="form-group">
                                        <label for="wfirstName2">
                                        {" "}
                                        Address {" "}
                                        {/* <span className="danger">*</span>{" "} */}
                                        </label>
                                        <input type="text" className="form-control required" disabled value={Address}/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="form-group">
                                        <label for="wfirstName2">
                                        {" "}
                                        City {" "}
                                        {/* <span className="danger">*</span>{" "} */}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control required"
                                            id="wfirstName2"
                                            name="firstName"
                                            aria-required="true"
                                            value={City} disabled/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="form-group">
                                        <label for="wfirstName2">
                                        {" "}
                                        State {" "}
                                        {/* <span className="danger">*</span>{" "} */}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control required"
                                            id="wfirstName2"
                                            name="firstName"
                                            aria-required="true"
                                            value={State} disabled/>
                                    </div>
                                </div>  

                                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                                    <div className="form-group">
                                        <label for="wfirstName2">
                                        {" "}
                                        Pincode {" "}
                                        {/* <span className="danger">*</span>{" "} */}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control required"
                                            id="wfirstName2"
                                            name="firstName"
                                            aria-required="true"
                                            value={Pincode} disabled/>
                                    </div>
                                </div>  
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12 col-12 px-0">
                                <div className="uploadedfilesection" style={{border: "1px solid #dadada"}}>
                                    <span className='modalImageheader'>Images</span>
                                <ul style={{marginTop: "60px"}}>
                                {VehicleImages ? VehicleImages?.map((item, index) =>
                                    <li key={index} >
                                        {/* <div className='RelativeDel'>
                                            <FontAwesomeIcon icon={faTimes} onClick={() => ShowDeleteImgModal(item)} className="deleteupload" />
                                        </div> */}
                                        <img src={atob(item?.docImage)} className="img img-responsive" />
                                        
                                    </li>
                                ):null}    
                                </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 px-0">
                                <div className="form-group bg-white" >
                                    <div className='' style={{border: "1px solid #dadada", padding: "10px"}}>
                                        <span style={{fontSize: "12px"}}>Comments</span>
                                    </div>

                                        <div className="uploadedfilesection" style={{height: "400px"}}>
                                        {ApproverComment?.map((item,index) =>
                                        <h6 className='mt-3' style={{wordWrap: "break-word"}}>{item}</h6>
                                        )}
                                        </div>
                                    

                                        <div className='Notice_modal_comment'>
                                            <div className="comment_Sec">
                                                <input
                                                    type="text"
                                                    className="form-control required border-0"
                                                    id="wfirstName2"
                                                    name="firstName"
                                                    aria-required="true"
                                                    placeholder='Add comment here'
                                                    value={ApproverCommentInput}
                                                    onChange={(e) => setApproverCommentInput(e.target.value)}
                                                    />
                                            </div>
                                            <div className='comment_btns'  >
                                            {/* <Button className='btn btn-default p-0'> */}
                                                    {/* <FontAwesomeIcon className="editNotice" icon={faMessage} style={{fontSize:"20px"}}/> */}
                                                {/* </Button> */}
                                                {/* <img src={chat} alt="homepage" className="dark-logo" value={"PENDING"} onClick={(e) => (ApproveAPi(e.target.value))} style={{width: "19%", paddingRight: 20}}/>  */}
                                                <img src={chat} alt="homepage" className="dark-logo" style={{width: "19%", paddingRight: 20}}/>
                                                <Button value={"PENDING"} onClick={(e) => (ApproveAPi(e.target.value))}  className=" border-0 m-0" id="webUrl3" name="webUrl3" aria-required="true" style={{backgroundColor: "transparent", position: "absolute", padding: "20px", boxShadow: "none", left: "265px"}}>
                                                        {" "}
                                                        
                                                </Button>
                                            
                                            <div className="cmt-btn">
                                                
                                                <Button value={"APPROVED"} onClick={(e) => (ApproveAPi(e.target.value))}  className="bg-success form-control notice-comment m-0" id="webUrl3" name="webUrl3" aria-required="true" >
                                                    {" "}
                                                    Approve
                                                </Button>
                                                <Button value={"REJECTED"} onClick={(e) => (ApproveAPi(e.target.value))}  className="bg-danger form-control notice-comment m-0" id="webUrl3" name="webUrl3" aria-required="true" >
                                                    {" "}
                                                    Reject
                                                </Button>
                                            </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
         </Modal.Body>
      </Modal>
      <div className='row'>
        {VehiclePendingList ? VehiclePendingList.map((item, index) =>  
        <div key={index} className="OneTile">
            <div className="ex_feild">
                {item?.currentStatus.toUpperCase() == "PENDING" ?
                    <span className="imp_med"> </span>
                :null}

                {item?.currentStatus.toUpperCase() == "REJECT" ?
                    <span className="imp_high"> </span>
                :null}

                {item?.currentStatus.toUpperCase() == "APPROVED" ?
                    <span className="imp_Low"> </span>
                :null}
            </div>
            <div className="NoticeFeild">
                <h1> {item?.userName}</h1>
                <h2> {item?.buildingName} </h2>

                <div className="onboardFlex">
                    {/* <h4> Current Status </h4> */}
                    <span> {item.currentStatus} </span>
                </div><br/>
                <div className="onboardFlex">
                    <h4> Mobile Number </h4>
                    <span> {item?.mobileNumber} </span>

  
                {/* {item?.currentStatus?.toUpperCase() == "APPROVED" ? 
                    <div className="onboardFont" style={{marginLeft: "20px"}}>
                        <img src={BroadCastImg} onClick={() => BroadCastModal(item)} alt="homepage" className="dark-logo" style={{width: "45%", marginTop: "-10px"}}/> 
                    </div>
                    :null
                } */}
                </div> 
                {item?.currentStatus?.toUpperCase() == "PENDING" ?     
                    <div className="onboardFont" >    
                        <img src={Action} onClick={() => ApproveModal(item)} alt="homepage" className="dark-logo"  /> 
                    </div>
                    :null
                }  
            </div>
        </div>
        ): null}  
    </div>
   </div>
</section> 
        </>)

} 
  export default UserApproval 