import React, { useEffect, useRef, useState } from "react";
import "../assets/css/custom.css";
import "../assets/css/client.css";
import "../assets/css/font-awesome.css";
import "../assets/css/sweetalert.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/steps.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faPlus,
    faSort,
    faTrash,
    faPenToSquare,
    faTimes,
    faAdd,
  } from "@fortawesome/free-solid-svg-icons";
import clientlogo from "../assets/img/user1.jpg";
import clientlogo2 from "../assets/img/user2.jpg";
import Nodata from "../assets/img/NoData.png";
import clientDemoImg from "../assets/img/EmptyLogo.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { commonAPI } from "../services/CommonAPIService";
import Menus from "./menus";
// import { Wizard, Steps, Step, WithWizard } from "react-multistep-wizard";
// import SlideButton from "react-slide-button";
import { Stepper, Step, StepLabel } from "@mui/material";
// import  clientimg  from '../assets/img/clientimg.jpg';
// import  clientimg2  from '../assets/img/clientimg-2.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import INDFlag from "../assets/img/INDFlag.jpg";
import filepreview from '../assets/img/filepreview.png';
import { ErrorSource } from "../common/constants";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
 import switchBaseClasses from "@mui/material/internal/switchBaseClasses";
 import { styled } from '@mui/material/styles';
 import FormGroup from '@mui/material/FormGroup';
 import FormControlLabel from '@mui/material/FormControlLabel';
 import noticeuserupload from '../assets/img/noticeuserupload.png';
 import Switch, { SwitchProps } from '@mui/material/Switch';
 import Stack from '@mui/material/Stack';
 import Typography from '@mui/material/Typography';
 import JoditEditor from 'jodit-react';
 import Form from 'react-bootstrap/Form';
 import previewnew from '../assets/img/previewnew.jpg';
 import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Table from 'react-bootstrap/Table'; 
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

function ClientSpare() {
    const [fullscreen, setFullscreen] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const steps = ["Step 1", "Step 2", "Step 3"];
    const [show, setShow] = useState(false);
    const [showFirstModal,setShowFirstModal] = useState(false)
    const [Loading, setLoading] = useState(false);
    const [NoticeList, setNoticeList] = useState("");
    const [NoticeSubject, setNoticeSubject] = useState("");
    const [NoticeDescription, setNoticeDescription] = useState("");
    const [NoticeEmail, setNoticeEmail] = useState();
    const [NoticeId, setNoticeId] = useState();
    const [Importance, setImportance] = useState();
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const [NoticeType, setNoticeType] = useState("");
    const [NoticeTypeId, setNoticeTypeId] = useState();
    const [NoticeTypeText, setNoticeTypeText] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageShow, setSelectedImageShow] = useState(null);
    const [selectedImageTwo, setSelectedImageTwo] = useState(null);
    const [selectedImageTwoShow, setSelectedImageTwoShow] = useState(null);
    const [firstSectionButton, setFirstSectionButton] = useState(false);
    const [UserData, setUserData] = useState("");
    const [AddNoticeBtn, setAddNoticeBtn] = useState(false);
    const [UpdateNoticeBtn, setUpdateNoticeBtn] = useState(false);


    const openCreateModal = () => {
        setShow(true);
        setAddNoticeBtn(true);
        setUpdateNoticeBtn(false);
        setNoticeSubject("");
        setNoticeDescription("");
        setNoticeEmail("");
        setImportance("");
        setStartDate("");
        setEndDate("");
        setSelectedImage("");
        setSelectedImageTwo("");
      };

      const openCreateModalagain = () => {
        setShowFirstModal(true);
        setAddNoticeBtn(true);
        setUpdateNoticeBtn(false);
        setNoticeSubject("");
        setNoticeDescription("");
        setNoticeEmail("");
        setImportance("");
        setStartDate("");
        setEndDate("");
        setSelectedImage("");
        setSelectedImageTwo("");
      };

      const editor = useRef(null);
      const [content, setContent] = useState('');
      const handleFileInputChangeTwo = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            const encodedString = btoa(reader.result);
            setSelectedImageTwo(reader.result);
            setSelectedImageTwoShow(encodedString);
          };
          reader.readAsDataURL(file);
        }
      };    

 

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const [state, setState] = React.useState({
        gilad: true,
        jason: false,
        antoine: false,
      });
    
      // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      //   setState({
      //     ...state,
      //     [event.target.name]: event.target.checked,
      //   });
      // };
    
      const { DLF, Magnolias, Bestech ,Kraheja } = state;
      const error = [DLF, Magnolias, Bestech ,Kraheja].filter((v) => v).length !== 2;
    
  return (
    <> 

<div className="row mb-1 mt-5" style={{ alignItems:'center' }}>
        <div className="col-md-4 mt-5 pt-2">
          <div className="HeadingTop">
            <h3> Inventory  </h3>
            <span> List of Inventory</span>
          </div>
        </div>
        <div className="col-md-3 offset-md-5 text-right">
          {/* <Button  onClick={() => openCreateModalagain()} className="createClr">
                  {" "}    Copy Settings <FontAwesomeIcon icon={faCopy} />
          </Button>{" "}  */}
        </div>
      </div>
      <div className="row mb-3" style={{ alignItems:'center' }}>
             <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="tableFixHead">
                <div className="scrolling_tbl">
              <Table striped responsive="lg">
          <thead>
            <tr>
              
              <th>Name</th>
              <th>Description</th>
              <th>Total Quantity</th>
              <th>Available Quantity</th>
              <th>Action</th>
              {/* <th>Edit</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
          
              <td >Test Block (60.7,33.6 HRC)</td>
              <td >Test Block (60.7,33.6 HRC)</td>
              <td>20</td>
              <td>10</td>
              <td><FontAwesomeIcon className="editNotice" icon={faAdd} />Add</td>
              
            </tr>
            <tr>
          
              <td >Bulb HPO-250</td>
              <td >Bulb HPO-250</td>
              <td>20</td>
              <td>10</td>
              <td><FontAwesomeIcon className="editNotice" icon={faAdd} />Add</td>
            </tr>
            <tr>
          
              <td >Diamond Indentor RTA-675</td>
              <td >Diamond Indentor RTA-675</td>
              <td>20</td>
              <td>10</td>
              <td><FontAwesomeIcon className="editNotice" icon={faAdd} />Add</td>
            </tr>
            <tr>
          
              <td >DT T/B - 34.2 HRC,80.1 HRA</td>
              <td >Diamond Indenter 1084,1110,</td>
              <td>20</td>
              <td>10</td>
              <td><FontAwesomeIcon className="editNotice" icon={faAdd} />Add</td>
            </tr>
            <tr>
          
              <td >DT T/B - 60.8 HRC, 33.9 HRC</td>
              <td >DT T/B - 60.8 HRC, 33.9 HRC</td>
              <td>20</td>
              <td>10</td>
              <td><FontAwesomeIcon className="editNotice" icon={faAdd} />Add</td>
          </tr>
          
         
          </tbody>

        </Table>
        
        </div>
        </div>
      
             </div>
        </div>
 

      <Modal  size="sm" show={showFirstModal}
        onHide={() => setShowFirstModal(false)}>
        <Modal.Header className="headerMDL" closeButton>
          <Modal.Title onClick={openCreateModalagain}> Email Config</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
            <span>Building </span> 
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 col-12">
            <div className="subPopup">

<select size="md" className="sltareamodal col-12 ml-auto"  >
        <option value="Geeral">Building 1</option>
        <option value="Alert"> Building 2</option>
        <option value="Warning">Building 3</option>
         
    </select>
</div>
</div>
          </div>
 

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Copy Email Config
          </Button>
        </Modal.Footer>
      </Modal>
  
</>
  );
}
export default ClientSpare;
